import * as React from 'react';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import CubeOutlineIcon from "mdi-react/CubeOutlineIcon";
import ReportsExcel from "./components/ReportsExcel";
import DownIcon from "mdi-react/CalendarExportIcon";
import { alerta, alertaRemove } from "shared/Alert";
import MesIcon from "mdi-react/CalendarMonthIcon";
import CubeSendIcon from "mdi-react/CubeSendIcon";
import DatePicker from "react-datepicker";
import * as Rdom from "react-router-dom";
import * as Iconos from "../../images";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import { Input } from "shared/Input";
import useAuth from "hooks/useAuth";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const NuevoMetodoPago = () => {
  const { auth } = useAuth();
  const { setTitulo } = useTitle();
  const navigate = Rdom.useNavigate();
  const location = Rdom.useLocation();
  const form_ref = React.useRef(null);
  const [tipo, setTipo] = React.useState(1);
  const [rango, setRango] = React.useState(0);
  const [agente, setAgente] = React.useState({});
  const [fechaDesde, setFechaDesde] = React.useState(new Date());
  const [fechaHasta, setFechaHasta] = React.useState(new Date());
  const [showSelectOpt, setShowSelectOpt] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [tipoFecha, setTipoFecha] = React.useState("");


  const validaCampos = (_length) => {
    alertaRemove(form_ref.current.agente);
    alertaRemove(form_ref.current.rango);

    if (agente.length == 0 || agente.id == null || agente.id < 0) {
      alerta(form_ref.current.agente, "¡Este campo es requerido!");
      form_ref.current.agente.focus();
      return false;
    }

    if (rango < 1) {
      alerta(form_ref.current.rango, "¡Este campo es requerido!");
      form_ref.current.rango.focus();
      return false;
    }

    if (_length < 1) {
      Alert("¡No hay datos para generar el documento!", "warning");
    }

    return true;
  }

  const handleChange = (e, opt) => {
    e.preventDefault();

    setTipo(opt);
    setShowSelectOpt(false);

    switch (parseInt(opt)) {
      case 1:
        setTitle("Reporte de cotizaciones");
        setTipoFecha("de creación");
        break;
      case 2:
        setTitle("Reporte de cotizaciones");
        setTipoFecha("de ejecución");
        break;
      case 3:
        setTitle("Reporte de ventas");
        setTipoFecha("de creación");
        break;
      case 4:
        setTitle("Reporte de ventas");
        setTipoFecha("de ejecución");
        break;
      case 5:
        setTitle("Ventas de productos");
        setTipoFecha("de creación");
        break;
      case 6:
        setTitle("Ventas de productos");
        setTipoFecha("de ejecución");
        break;
      case 7:
        setTitle("Ventas de productos individuales");
        setTipoFecha("de ejecución");
        break;
      case 8:
        setTitle("Ordenes ejecutadas");
        setTipoFecha("de ejecución");
        break;
      default:
        setTitle("Reportes");
        setTipoFecha("");
        break;
    }
  }

  const SelectOpt = () => {

    return (
      <div className="select_opt">
        <h2 className="title_close">Descarga de reportes <CloseIcon className="close" onClick={() => navigate(-1)} /></h2>

        <div className="cont_select_opt">

          <div className="select_opt_item" onClick={e => handleChange(e, 1)}>
            <Iconos.ChartDownload />

            <h3>Cotizaciones</h3>
            <p>Descarga solo las cotizaciones de los agentes por fecha de creación.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 2)}>
            <Iconos.ChartDownload />

            <h3>Cotizaciones</h3>
            <p>Descarga solo las cotizaciones de los agentes por fecha de ejecución.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 3)}>
            <Iconos.ChartVentas />

            <h3>Ventas</h3>
            <p>Descarga solo las ventas de los agentes cuando el documento pasa a ors con abono o sin abono con fecha de creación.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 4)}>
            <Iconos.ChartVentas />

            <h3>Ventas</h3>
            <p>Descarga solo las ventas de los agentes cuando el documento pasa a ors con abono o sin abono con fecha de ejecución.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 5)}>
            <CubeOutlineIcon />

            <h3>Productos</h3>
            <p>Descarga el resumen de los productos  principales vendidos con fecha de creación.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 6)}>
            <CubeOutlineIcon />

            <h3>Productos</h3>
            <p>Descarga el resumen de los productos  principales vendidos con fecha de ejecución.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 7)}>
            <CubeSendIcon />

            <h3>Productos Detallado</h3>
            <p>Descarga los productos que conforman un combo o son principales con fecha de ejecución.</p>
          </div>

          <div className="select_opt_item" onClick={e => handleChange(e, 8)}>
            <Iconos.FileChartCheckOutline />

            <h3>Ordenes ejecutadas</h3>
            <p>Descarga el resumen de las ordenes ya ejecutadas.</p>
          </div>
        </div>

      </div>
    );
  }

  React.useEffect(() => {
    setTitulo("Descarga de reportes");
    if (auth && auth.nivel >= 2) {
      navigate("/", { replace: true, state: { from: location } });
    }
  }, []);

  return (
    <>
      {showSelectOpt ?
        <div className="cont_principal deg_top cont_select">
          <SelectOpt />
        </div>
        :
        <div className="cont_principal deg_top cont_forms" style={{ overflow: "visible" }}>
          <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
            <h2 className="title_close">{title} <CloseIcon className="close" onClick={() => setShowSelectOpt(true)} /> </h2>

            <div className="fila">

              <div className="columna">
                {(tipo === 1 || tipo === 2 || tipo === 7 || tipo === 8) ?
                  <>
                    <label>Agente</label>
                    <Input type="text" name="agente" find="agente" placeholder='Nombre del agente'
                      onMatch={(item) => setAgente(item)}>
                      <AccountOutlineIcon />
                    </Input>
                  </>
                  :
                  <>
                    <label>Producto</label>
                    <Input type="text" name="agente" find="producto" placeholder='Nombre del producto'
                      onMatch={(item) => setAgente(item)}>
                      <AccountOutlineIcon />
                    </Input>
                  </>
                }
              </div>

              <div className="columna">
                <label>Fecha {tipoFecha}</label>
                <div className="input_icon">
                  <DownIcon />
                  <select name="rango" value={rango} onChange={(e) => { alertaRemove(form_ref.current.rango); setRango(parseInt(e.target.value)); }}>
                    <option value="0">Selecciona...</option>
                    <option value="8">Hoy</option>
                    <option value="1">Desde ayer</option>
                    <option value="2">Hace una semana</option>
                    <option value="3">Hace un mes</option>
                    <option value="4">Ultimo trimestre</option>
                    <option value="5">Ultimo semestre</option>
                    <option value="6">Ultimo año</option>
                    <option value="7">Rango</option>
                  </select>
                </div>
              </div>

            </div>

            {rango === 7 && <div className="fila">

              <div className="columna">
                <label>Fecha desde</label>
                <div className="input_icon">
                  <DatePicker name='fecha' dateFormat="yyyy/MM/dd" selected={fechaDesde} onChange={(date) => setFechaDesde(date)} />
                  <MesIcon />
                </div>
              </div>

              <div className="columna">
                <label>Fecha hasta</label>
                <div className="input_icon">
                  <DatePicker name='fechavence' dateFormat="yyyy/MM/dd" selected={fechaHasta} onChange={(date) => setFechaHasta(date)} />
                  <MesIcon />
                </div>
              </div>

            </div>}

            <ReportsExcel
              valida={validaCampos}
              data={{
                ...agente,
                rango: rango,
                tipo: tipo,
                desde: new Date(fechaDesde.getFullYear(), fechaDesde.getMonth(), fechaDesde.getDate(), 0, 0, 0, 0).getTime() / 1000,
                hasta: new Date(fechaHasta.getFullYear(), fechaHasta.getMonth(), fechaHasta.getDate(), 23, 59, 59, 0).getTime() / 1000
              }} />
          </form >
        </div >
      }
    </>
  );
}

export default NuevoMetodoPago;
