import * as React from 'react';
import ListaProductos from "../Productos/components/ListaProductos";
import { useNavigate, useLocation } from "react-router-dom";
import SwapVerticalIcon from 'mdi-react/SwapVerticalIcon';
import ArrowRigth from "mdi-react/ChevronRightIcon";
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import MenuRow from "./components/MenuRow";
import funciones from "services/funciones";
import Loading from "shared/Loading";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";
import Cell from "shared/Cell";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Ordenes = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;
    const [asc, setAsc] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [criterio, setCriterio] = React.useState((data && data.criterio) ? data.criterio : "");
    const [showList, setShowList] = React.useState(false);
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [filtro, setFiltro] = React.useState((data && data.criterio) ?
        {
            type: "search",
            criterio: data.criterio
        }
        :
        {
            type: auth.nivel == 4 ? "user" : "all",
            id: window.atob(auth.auth)
        });


    const Row = (params) => {
        let estado = funciones.estadoOrden(params.data.estado);

        return (
            <MenuRow {...params}
                current={selected.id}
                onSel={setSelected}
                reload={getOrdenes}>

                <td>{params.data.id}</td>
                <td>{estado}</td>
                <td>{params.data.nombre_vendedor}</td>
                <td>{params.data.nombre} {params.data.apellido}</td>
                <td>{params.data.packetes}</td>
                <td>{params.data.descuento}%</td>
                <td>{funciones.formatFecha(params.data.vigencia)}</td>
                <td>{funciones.formatFecha(params.data.fecha_ejecucion)}</td>
                <td>{funciones.formatFecha(params.data.fecha_creacion)}</td>
                <td style={{textAlign: "right"}}>{params.data.valor_total > 0 ? "$" + funciones.formatPrice(params.data.valor_total).format : "0"}</td>
                <td style={{textAlign: "right"}}>{params.data.abonos > 0 ? "$" + funciones.formatPrice(params.data.abonos).format : "0"}</td>
                <td style={{textAlign: "right"}}>{params.data.saldo > 0 ? "$" + funciones.formatPrice(params.data.saldo).format : "0"}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} resalta={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return <div className="cont_tabla">
                <table>
                    <thead>
                        <tr>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("id")} />Código</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("estado")} />Estado</Cell>
                            <Cell style={{ width: 95, minWidth: 95, maxWidth: 110 }}><SwapVerticalIcon onClick={() => sortBy("nombre_vendedor")} />Vendedor</Cell>
                            <Cell style={{ width: 95, minWidth: 95, maxWidth: 120 }}><SwapVerticalIcon onClick={() => sortBy("nombre")} />Cliente</Cell>
                            <Cell>Paquete</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("descuento")} />Descuento</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("vigencia")} />Vigencia</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("fecha_ejecucion")} />F. actividad</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("fecha_creacion")} />F. Creado</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("valor_total")} />Valor</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("abonos")} />Abono</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("saldo")} />Saldo</Cell>
                        </tr>
                    </thead>

                    <tbody>
                        {_data}
                    </tbody>
                </table>
            </div>;
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const sortByNum = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: parseInt(item[_key]) };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                return a.value - b.value;
            } else {
                return b.value - a.value;
            }
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const handleKey = (e) => {
        e.preventDefault();

        if (e.key === "Enter") {
            handleSearch(e);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();

        if (criterio.length > 0) {
            setShowLoading(true);

            setFiltro({
                type: "search",
                criterio: criterio
            });
        }
    }

    const handleShowAll = (e) => {
        e.preventDefault();

        setShowLoading(true);

        setCriterio("");

        setFiltro({
            type: auth.nivel == 4 ? "user" : "all",
            id: window.atob(auth.auth)
        });
    }

    const getOrdenes = async () => {
        let _data = await api.fetchJson({
            url: "get-ordenes",
            data: filtro
        });

        if (_data.response == "1") {
            _data.data.map(item => {
                let _valor_total = parseInt(parseInt(item.valor_total) - (parseInt(item.valor_total) * parseInt(item.descuento) / 100));
                item.valor_total = _valor_total;
                item.saldo = item.abonos ? parseInt(_valor_total - parseInt(item.abonos)) : _valor_total;

                return item;
            });

            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo((auth && auth.nivel) == 4 ? "Mis Compras" : "Ordenes");

        getOrdenes();
    }, [filtro]);

    return (
        <>
            {showList ?
                <ListaProductos hiddenList={setShowList} />
                :
                <div className="cont_principal deg_top">
                    <div className="cont_titulo only_searsh">
                        <h2>{(auth && auth.nivel) == 4 ? "Mis Compras" : "Ordenes"} </h2>
                        {(auth && auth.nivel) <= 3 && <button className="btn_lista" onClick={() => setShowList(true)}>Nueva Cotización <ArrowRigth /></button>}
                        {filtro.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                        <div className="search_bar">
                            <input type="text" placeholder="Buscar por c.c, nombre, apellidos o teléfono"
                                value={criterio} onChange={e => setCriterio(e.target.value)}
                                onKeyUp={handleKey} />
                            <MagnifyIcon onClick={handleSearch} />
                        </div>

                        {/* <Filtro /> */}
                    </div>

                    <AllRow />

                    {showLoading && <Loading />}
                </div>
            }
        </>
    );
}

export default Ordenes;