import * as React from "react";
import ListaMetodosPago from "./components/ListaMetodosPago";
import ListaTodos from "./components/ListaTodos";
import * as Rdom from "react-router-dom";
import { Alert, Confirm } from "shared/Alert";
import funciones from "services/funciones";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";


const Caja = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const [total, setTotal] = React.useState(0);
    const [tabSelect, setTabSelect] = React.useState(0);
    const tabs = ["Métodos de pago", "Todos"];
    const [showLoading, setShowLoading] = React.useState(true);

    const handleCierre = async (e) => {
        e.preventDefault();

        Confirm("¿Está seguro de cerrar la caja?", async (resp) => {
            if (resp) {
                setShowLoading(true);
                let _data = await api.fetchJson({
                    url: "save-cierre-caja",
                    data: {}
                });

                if (_data.response == "1") {
                    setTotal(0);
                    Alert("Caja cerrada correctamente", "ok", () => navigate(-1));
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
                } else {
                    let _msg = _data.data ? _data.data.msg : _data.msg;

                    Alert(_msg, "warning");
                }
            }
        });
    }

    React.useEffect(() => {
        setTitulo("Cuadre de caja");
    }, []);

    return (
        <div className="cont_principal deg_top">

            <div className="cont_titulo info_cierre">
                <h2>Cuadre de caja</h2>
                <p>Revisa cuidadosamente los valores de la lista antes de cerrar tu caja ya que una vez cerrada la caja no se podrán hacer cambios.</p>

                <div className="cont_info_cierre">
                    <h3>{total >= 1 ? "Total: $" + funciones.formatPrice(total).format + " COP" : "¡No hay ventas!"} </h3>
                    <button onClick={handleCierre}>Cerrar caja</button>
                </div>
            </div>

            <div className="_tabs_header">
                {tabs.map((item, index) => {
                    return (
                        <div key={index}
                            className={tabSelect == index ? "_tab _active" : "_tab"}
                            onClick={() => setTabSelect(index)}>
                            <span>{item}</span>
                        </div>
                    );
                })}
            </div>

            {tabSelect == 0 && <ListaMetodosPago total={setTotal} loading={setShowLoading} />}

            {tabSelect == 1 && <ListaTodos total={setTotal} loading={setShowLoading} />}
            {showLoading && <Loading />}
        </div>
    );

}

export default Caja;