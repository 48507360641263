import * as React from 'react';
import { useNavigate, Link } from "react-router-dom";
import ArrowRigth from "mdi-react/ChevronRightIcon";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";


const CierreCaja = (params) => {
    const { showList } = params;
    const navigate = useNavigate();
    const { verCaja, abrirCaja } = useAuth();
    const [caja, setCaja] = React.useState({ estado: 0 });
    const isMounted = React.useRef(true);


    const goCaja = (e) => {
        e.preventDefault();

        navigate("/caja");
    }

    const openCaja = (e) => {
        e.preventDefault();

        abrirCaja().then((_data) => {
            _data.estado = parseInt(_data.estado);

            if (_data.estado == 1) {
                Alert("La caja se abrió correctamente", "ok");
                setCaja(_data);
            }
        }).catch((_error) => {
            setCaja({ estado: 2 });
        });
    }

    React.useEffect(() => {
        verCaja().then((_data) => {
            if (isMounted.current) {
                _data.estado = parseInt(_data.estado);
                setCaja(_data);
            }
        }).catch((_error) => {
            if (isMounted.current) {
                setCaja({ estado: 2 });
            }
        });

        return () => { isMounted.current = false; };
    }, []);

    return (
        <div className='cierre_de_caja'>
            <Link to="/ordenes">Ver todas <ArrowRigth /></Link>
            <button className='a_link' onClick={() => showList(true)}>Nueva Cotización <ArrowRigth /></button>
            {caja.estado === 1 ?
                <button onClick={goCaja}>Cuadre de caja</button>
                :
                <>
                    {caja.estado === 0 ?
                        <button>Comprobando caja...</button>
                        :
                        <button onClick={openCaja}>Abrir caja</button>
                    }
                </>
            }
        </div>
    );

}

export default CierreCaja;