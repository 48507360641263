import * as React from 'react';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import CityVariantOutlineIcon from 'mdi-react/CityVariantOutlineIcon';
import PhoneInTalkOutlineIcon from 'mdi-react/PhoneInTalkOutlineIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CardIcon from 'mdi-react/CardAccountPhoneOutlineIcon';
import { Alert, alerta, alertaRemove } from "shared/Alert";
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import CityAddIcon from 'mdi-react/HomeGroupPlusIcon';
import WebIcon from 'mdi-react/WebSyncIcon';
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint react-hooks/exhaustive-deps: 0 */

const EditarOperador = () => {
    const { setTitulo } = useTitle();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const data = location.state?.data;
    const returTo = location.state?.from?.pathname || "/operadores";
    const [showLoading, setShowLoading] = React.useState(false);
    const [nombre, setNombre] = React.useState(data.nombre || "");
    const [nit, setNit] = React.useState(data.nit || "");
    const [ciudades, setCiudades] = React.useState([{ post: 0, nombre: '' }]);
    const [website, setWebsite] = React.useState(data.url || "");
    const [correo, setCorreo] = React.useState(data.correo || "");
    const [telefono, setTelefono] = React.useState(data.telefono || "");
    const [contacto, setContacto] = React.useState(data.representante || "");
    const [telcontact, setTelcontact] = React.useState(data.celular || "");
    const form_ref = React.useRef(null);


    const validaNombre = () => {
        alertaRemove(form_ref.current.nombre);

        if (nombre == null || nombre.length < 1) {
            alerta(form_ref.current.nombre, "¡Este campo es requerido!");
            form_ref.current.nombre.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaNit = () => {
        alertaRemove(form_ref.current.nit);

        if (nit == null || nit.length < 1) {
            alerta(form_ref.current.nit, "¡Este campo es requerido!");
            form_ref.current.nit.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaCiudad = () => {
        let toReturn = true;
        alertaRemove(form_ref.current.city);

        if (ciudades == null || ciudades.length < 1) {
            alerta(form_ref.current.city, "¡Este campo es requerido!");
            form_ref.current.city.focus();
            return false;
        } else {
            ciudades.forEach(element => {
                if (element.nombre == null || element.nombre.length < 1) {
                    alerta(form_ref.current["city_" + element.post], "¡Este campo es requerido!");
                    form_ref.current["city_" + element.post].focus();
                    toReturn = false;
                }
            });

            return toReturn;
        }
    }

    const validaCorreo = () => {
        alertaRemove(form_ref.current.correo);

        if (correo == null || correo.length < 1) {
            alerta(form_ref.current.correo, "¡Este campo es requerido!");
            form_ref.current.correo.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaTelefono = () => {
        alertaRemove(form_ref.current.telefono);

        if (telefono == null || telefono.length < 1) {
            alerta(form_ref.current.telefono, "¡Este campo es requerido!");
            form_ref.current.telefono.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaContacto = () => {
        alertaRemove(form_ref.current.contacto);

        if (contacto == null || contacto.length < 1) {
            alerta(form_ref.current.contacto, "¡Este campo es requerido!");
            form_ref.current.contacto.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaTelContac = () => {
        alertaRemove(form_ref.current.celular);

        if (telcontact == null || telcontact.length < 1) {
            alerta(form_ref.current.celular, "¡Este campo es requerido!");
            form_ref.current.celular.focus();
            return false;
        } else {
            return true;
        }
    }


    const handleSave = async (e) => {
        e.preventDefault();

        if (validaNombre()
            && validaNit()
            && validaCorreo()
            && validaTelefono()
            && validaContacto()
            && validaTelContac()
            && validaCiudad()
        ) {
            setShowLoading(true);
            let _ciudades = "";

            // Liaramos el string de ciudades
            ciudades.forEach(element => {
                _ciudades += element.nombre + ",";
            });

            // Eliminamos la ultima coma
            _ciudades = _ciudades.substring(0, _ciudades.length - 1);

            let _data = await api.fetchJson({
                url: "save-operador",
                data: {
                    id: data.id,
                    nombre: nombre,
                    nit: nit,
                    ciudades: _ciudades,
                    website: website,
                    correo: correo,
                    telefono: telefono,
                    contacto: contacto,
                    telcontact: telcontact
                }
            });

            if (parseInt(_data.response) === 1) {

                setTimeout(() => {
                    setShowLoading(false);
                    let _ciudades = "";

                    // Liaramos el string de ciudades
                    ciudades.forEach(element => {
                        _ciudades += element.nombre + ",";
                    });

                    // Eliminamos la ultima coma
                    _ciudades = _ciudades.substring(0, _ciudades.length - 1);

                    Alert("Operador modificado con éxito", "ok", () => {
                        navigate(returTo, {
                            state: {
                                data: {
                                    ...data,
                                    nit: nit,
                                    nombre: nombre,
                                    url: website,
                                    correo: correo,
                                    telefono: telefono,
                                    celular: telcontact,
                                    representante: contacto,
                                    ciudades: _ciudades
                                },
                                from: { ...location, pathname: returTo }
                            }
                        });
                    });
                }, 2000);


            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;
                setShowLoading(false);
                Alert(_msg, "warning");
            }
        }
    }

    const handleAddCity = (e) => {
        e.preventDefault();

        setCiudades([...ciudades, { post: ciudades.length, nombre: '' }]);
    }

    const armaCiudades = () => {
        let _ciudades = [];

        data.ciudades.split(',').forEach((ciudad, index) => {
            _ciudades.push({ post: index, nombre: ciudad });
        });

        setCiudades(_ciudades);
    }

    React.useEffect(() => {
        setTitulo("Editar Operador");
        armaCiudades();
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                <h2>Editar Operador ({data.id})</h2>

                <div className="fila">

                    <div className="columna">
                        <label>Nombre</label>
                        <div className="input_icon">
                            <AccountOutlineIcon />
                            <input type="text" name="nombre" placeholder='Nombre'
                                maxLength={60}
                                value={nombre} onChange={(e) => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Nit</label>
                        <div className="input_icon">
                            <CardAccountDetailsOutlineIcon />
                            <input type="number" name="nit" placeholder='Nit de la empresa'
                                maxLength={12}
                                value={nit} onChange={(e) => { alertaRemove(form_ref.current.nit); setNit(e.target.value) }} />
                        </div>
                    </div>

                    <div className="columna">
                        <label>Sitio web</label>
                        <div className="contrasenia">
                            <WebIcon />
                            <input type="text" name="website" placeholder='Sitio web'
                                maxLength={50}
                                value={website} onChange={(e) => setWebsite(e.target.value)} />
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Correo</label>
                        <div className="input_icon">
                            <EmailOutlineIcon />
                            <input type="email" name="correo" placeholder='Correo electrónico'
                                maxLength={45}
                                value={correo} onChange={(e) => { alertaRemove(form_ref.current.correo); setCorreo(e.target.value) }} />
                        </div>
                    </div>

                    <div className="columna">
                        <label>Teléfono empresa</label>
                        <div className="input_icon">
                            <PhoneInTalkOutlineIcon />
                            <input type="tel" name="telefono" placeholder='Número de contacto'
                                maxLength={12}
                                value={telefono} onChange={(e) => { alertaRemove(form_ref.current.telefono); setTelefono(e.target.value) }} />
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Persona de Contacto</label>
                        <div className="input_icon">
                            <CardIcon />
                            <input type="text" name="contacto" placeholder='Nombre del contacto'
                                value={contacto} onChange={(e) => { alertaRemove(form_ref.current.contacto); setContacto(e.target.value) }} />
                        </div>
                    </div>

                    <div className="columna">
                        <label>Teléfon personal</label>
                        <div className="input_icon">
                            <CellphoneIcon />
                            <input type="text" name="celular" placeholder='Número de contacto'
                                value={telcontact} onChange={(e) => { alertaRemove(form_ref.current.celular); setTelcontact(e.target.value) }} />
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        {ciudades.map((ciudad, index) => {

                            return (
                                <div key={index}>
                                    <label>Ciudad {ciudad.post + 1}</label>
                                    <div className="contrasenia">
                                        <CityVariantOutlineIcon />
                                        <input type="text"
                                            placeholder='Ciudad'
                                            maxLength={45}
                                            name={"city_" + ciudad.post}
                                            value={ciudad.nombre} onChange={(e) => {
                                                alertaRemove(form_ref.current["city_" + ciudad.post]);

                                                setCiudades(ciudades.map((c, i) => {

                                                    if (i === index) {
                                                        // solo la primera en mayuscula y la resto en minuscula
                                                        let _nombre = e.target.value.toLowerCase();
                                                        _nombre = _nombre.charAt(0).toUpperCase() + _nombre.slice(1);
                                                        return { ...c, nombre: _nombre }
                                                    } else {
                                                        return c;
                                                    }
                                                }))

                                            }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>


                    <div className="columna" style={{ alignItems: "center", justifyContent: "flex-end" }}>
                        <button type="button" className="btn_icon" style={{ marginRight: 28 }}
                            onClick={handleAddCity}>
                            <CityAddIcon />Agregar Ciudad
                            <input type="hidden" name="productos" />
                        </button>
                    </div>

                </div>

                <div className="botones">
                    <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
                    <button type="button" onClick={handleSave}>Aceptar</button>
                </div>

            </form>

            {showLoading && <Loading />}
        </div>
    );
}

export default EditarOperador;
