import * as React from "react";
import * as Rdom from "react-router-dom";
import Loading from "shared/Loading";
import api from "services/api";


/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const VerificaCorreo = () => {
    const navigate = Rdom.useNavigate();
    const params = Rdom.useParams();
    const correo = window.atob(params.correoBase);
    const [mensaje, setMensaje] = React.useState("Verificando el correo " + correo);
    const [correcto, setCorrecto] = React.useState(false);

    const [showLoading, setShowLoading] = React.useState(true);

    const goLogin = (e) => {
        e.preventDefault();
        navigate("/log-in");
    }

    const verificaCorreo = async () => {

        let _data = await api.fetchJson({
            url: "verifica-correo",
            data: {
                correo: correo,
            }
        });

        if (parseInt(_data.response) === 1) {

            setTimeout(() => {
                setShowLoading(false);
                setMensaje("El correo " + correo + " \nse ha verificado correctamente \nYa puedes iniciar sesión.");
                setCorrecto(true);
            }, 2000);
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;
            setShowLoading(false);
            setMensaje(_msg);
            setCorrecto(false);
        }

    }

    React.useEffect(() => {
        document.title = "Verifica Correo";
        verificaCorreo();
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            <div className="form_principal">
                <h4 className="titulo_msg" style={{marginTop: 62, marginBottom: 62}}>{mensaje}</h4>


                <div className="botones">
                    {correcto && <button type="button" onClick={goLogin}>Ir a iniciar sesión</button>}
                </div>
            </div>

            {showLoading && <Loading />}
        </div>
    );
}

export default VerificaCorreo;