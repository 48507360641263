import * as React from 'react';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import PhoneInTalkOutlineIcon from 'mdi-react/PhoneInTalkOutlineIcon';
import CityVariantOutlineIcon from 'mdi-react/CityVariantOutlineIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import { useNavigate } from "react-router-dom";
import useTitle from "hooks/useTitle";


const AgregarDestino = () => {
  const navigate = useNavigate();
  const { setTitulo } = useTitle();
  const [nombre, setNombre] = React.useState('');
  const [representa, setRepresenta] = React.useState('');

  React.useEffect(() => {
    setTitulo("Nuevo destino");
  }, []);

  return (
    <div className="cont_principal deg_top cont_forms">

      <div className="form_principal">
        <h2>Nuevo destino</h2>

        <div className="fila">

          <div className="columna">
            <label>Nombre</label>
            <div className="input_icon">
              <AccountOutlineIcon />
              <input type="text" name="nombre" placeholder='Nombre de la empresa'
                value={nombre} onChange={(e) => setNombre(e.target.value)} />
            </div>
          </div>

          <div className="columna">
            <label> &nbsp; Representante</label>
            <div className="input_icon">
              <AccountOutlineIcon />
              <input type="text" name="apellido" placeholder='Representante'
                value={representa} onChange={(e) => setRepresenta(e.target.value)} />
            </div>
          </div>

        </div>

        <div className="fila">

          <div className="columna">
            <label>Nit</label>
            <div className="input_icon">
              <CardAccountDetailsOutlineIcon />
              <input type="number" name="documento" placeholder='Nit' />
            </div>
          </div>

          <div className="columna">
            <label>Ciudad</label>
            <div className="input_icon">
              <CityVariantOutlineIcon />
              <input type="text" name="cargo" placeholder='Ciudad' />
            </div>
          </div>

        </div>

        <div className="labels">
        </div>

        <div className="fila">

          <div className="columna">
            <label>Correo</label>
            <div className="input_icon">
              <EmailOutlineIcon />
              <input type="email" name="correo" placeholder='Correo electronico' />
            </div>
          </div>

          <div className="columna">
            <label>Teléfono</label>
            <div className="input_icon">
              <PhoneInTalkOutlineIcon />
              <input type="tel" name="telefono" placeholder='Número de contacto' />
            </div>
          </div>

        </div>

        <div className="botones">
          <button onClick={() => navigate(-1)}>Cancelar</button>
          <button>Agregar</button>
        </div>

      </div>

    </div>
  );
}

export default AgregarDestino;
