import * as React from "react";
import BoxCloseIcon from "mdi-react/PackageVariantClosedIcon";
import { useLocation, useNavigate, Link } from "react-router-dom";
import BoxOpenIcon from "mdi-react/PackageVariantIcon";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import { Alert, Confirm } from "shared/Alert";
import EditIcon from "mdi-react/EditOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import * as Iconos from "../../../images";

import classNames from 'classnames';

import api from "services/api";
// import PrintLabel from "./PrintLabel";
// import PrintOrder from "./PrintOrder";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { data, resalta, children, onSel, reload, current } = params;
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const navigate = useNavigate();
    const row = React.useRef(null);

    const remarcado = classNames({
        'remarcado': resalta === 1,
        'seleccionado': current === data.id
    });



    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("ver-detalle", { state: { data: data } });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("ver-detalle", { state: { data: data } });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de borrar la orden Nº ${data.consecutive}?`, async (response) => {
            if (response) {
                let _data = await api.fetchJson({
                    url: "delete-order",
                    data: {
                        id_order: data.id,
                        tipo: "pick-up order"
                    }
                });

                if (_data.response == 1) {
                    reload();
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true }));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 160 ? e.pageY - 160 : e.pageY;


        setPos({ top: _to_y + "px", left: _posx + "px" });
        setShowMenu(true);
    }

    const handleModal = (_option) => {
        let _window;

        switch (_option) {

            case "print":
                // _window = <PrintOrder data={data} pos={_pos} close={setShowModal} />;
                break;
            case "print_label":
                // _window = <PrintLabel data={data} pos={_pos} close={setShowModal} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
        setShowModal(true);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
        }
    }, []);

    return (
        <tr className={remarcado} ref={row}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="row_menu"
                onClick={stopClick}
                onDoubleClick={stopClick}
                onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Ver detalles</strong></span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>
                                
                                <hr />

                                <li><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>

                            </ul>
                        </nav>
                    </>}

                {showModal && modalView}
            </td>
        </tr>
    );

}

export default MenuRow;