import * as React from "react";
import PhoneInTalkOutlineIcon from "mdi-react/PhoneInTalkOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import KeyOutlineIcon from "mdi-react/KeyOutlineIcon";
import logo from "../../images/logo.png";
import * as Rdom from "react-router-dom";
import Loading from "shared/Loading";
import CryptoJS from "crypto-js";
import api from "services/api";

/* eslint no-useless-escape: 0 */

const SignUp = () => {
  const navigate = Rdom.useNavigate();
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [passConfirm, setPassConfirm] = React.useState("");
  const form_ref = React.useRef(null);

  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  }

  const validaCampos = (e) => {
    e.preventDefault();

    alertaRemove(form_ref.current.nombre);
    alertaRemove(form_ref.current.apellido);
    alertaRemove(form_ref.current.telefono);
    alertaRemove(form_ref.current.correo);


    if (nombre.trim() === "") {
      alerta(form_ref.current.nombre, "¡Este campo es requerido!");
      form_ref.current.nombre.focus();
      return false;
    }

    if (apellido.trim() === "") {
      alerta(form_ref.current.apellido, "¡Este campo es requerido!");
      form_ref.current.apellido.focus();
      return false;
    }

    if (telefono.trim() === "") {
      alerta(form_ref.current.telefono, "¡Este campo es requerido!");
      form_ref.current.telefono.focus();
      return false;
    }

    if (email.trim() === "") {
      alerta(form_ref.current.correo, "¡Este campo es requerido!");
      form_ref.current.correo.focus();
      return false;
    }

    let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(email).toLowerCase())) {
      alerta(form_ref.current.correo, "¡El formato del correo es incorrecto!");
      form_ref.current.correo.focus();
      return false;
    }

    if (pass.trim() === "") {
      alerta(form_ref.current.new_password, "¡Este campo es requerido!");
      form_ref.current.new_password.focus();
      return false;
    }

    if (passConfirm.trim() === "") {
      alerta(form_ref.current.confirm_password, "¡Este campo es requerido!");
      form_ref.current.confirm_password.focus();
      return false;
    }

    if (pass !== passConfirm) {
      alerta(form_ref.current.confirm_password, "¡Las contraseñas no coinciden!");
      form_ref.current.confirm_password.focus();
      return false;
    }

    saveData();
  }

  const saveData = async () => {

    setShowLoading(true);

    let _data = await api.fetchJson({
      url: "sign-up",
      data: {
        nombre: nombre,
        apellido: apellido,
        telefono: telefono,
        correo: email,
        pass: CryptoJS.SHA256(pass).toString(),
      }
    });

    if (parseInt(_data.response) === 1) {

      setTimeout(() => {
        setShowLoading(false);
        Alert("El usuario fue creado con éxito.\nPor favor ingresa al correo " + email + " y confirma tu cuenta.", "ok", () => {
          navigate(-1);
        });
      }, 2000);

    } else {
      let _msg = _data.data ? _data.data.msg : _data.msg;
      setShowLoading(false);
      Alert(_msg, "warning");
    }

  }

  React.useEffect(() => {
    document.title = "Registro de usuario";
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="cont_principal deg_top cont_forms">

      <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>

        <div className="cont_logo">
          <img src={logo} alt="logo principal" />
        </div>

        <label>Nombre</label>
        <div className="input_icon">
          <AccountOutlineIcon />
          <input type="text"
            name="nombre"
            placeholder='Nombre'
            value={nombre}
            onChange={e => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
        </div>

        <label>Apellido</label>
        <div className="input_icon">
          <AccountOutlineIcon />
          <input type="text"
            name="apellido"
            placeholder='Apellido'
            value={apellido}
            onChange={e => { alertaRemove(form_ref.current.apellido); setApellido(e.target.value) }} />
        </div>

        <label>Teléfono</label>
        <div className="input_icon">
          <PhoneInTalkOutlineIcon />
          <input type="tel"
            name="telefono"
            placeholder='Número de contacto'
            value={telefono}
            onChange={e => { alertaRemove(form_ref.current.telefono); setTelefono(e.target.value) }} />
        </div>

        <label>Correo</label>
        <div className="input_icon">
          <EmailOutlineIcon />
          <input type="email"
            name="correo"
            placeholder='Correo electronico'
            value={email}
            onChange={e => { alertaRemove(form_ref.current.password); setEmail(e.target.value); }} />
        </div>

        <label>Nueva contraseña</label>
        <div className="contrasenia">
          <KeyOutlineIcon />
          <input type={isPasswordShown ? 'text' : 'password'}
            name="password"
            placeholder='Contraseña'
            value={pass}
            onChange={e => { alertaRemove(form_ref.current.password); setPass(e.target.value) }} />
          {isPasswordShown ? <EyeOffOutlineIcon className="eye_icon" onClick={() => handleShowPassword()} /> : <EyeOutlineIcon className="eye_icon" onClick={() => handleShowPassword()} />}

        </div>

        <label>Confirma contraseña</label>
        <div className="contrasenia">
          <KeyOutlineIcon />
          <input type={isPasswordShown ? 'text' : 'password'}
            name="confirma_contraseña"
            placeholder='Confirma contraseña'
            value={passConfirm}
            onChange={e => { alertaRemove(form_ref.current.confirma_contraseña); setPassConfirm(e.target.value) }} />
        </div>

        <button type="button" onClick={validaCampos}>Registrar</button>

      </form>

      <p className="footer">© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
      {showLoading && <Loading />}
    </div>
  );
}

export default SignUp;