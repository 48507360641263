import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ArrowRigth from "mdi-react/ChevronRightIcon";
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";
import Cell from "shared/Cell";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MetodoPago = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = useNavigate();
    const location = useLocation();
    const [dataList, setDataList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [filtro, setFiltro] = React.useState({ type: "all" });
    const [showLoading, setShowLoading] = React.useState(true);


    const Row = (params) => {
        let estado = "...";

        switch (parseInt(params.data.estado)) {
            case 1:
                estado = "Activo";
                break;
            case 2:
                estado = "Pendiente";
                break;
            default:
                estado = "Inactivo";
                break;
        }

        return (
            <MenuRow {...params}
                current={selected.id}
                onSel={setSelected}
                reload={getInfo}>

                <td>{params.data.nombre}</td>
                <td>{params.data.descripcion.length > 40 ? params.data.descripcion.substring(0, 40) + "..." : params.data.descripcion}</td>
                <td>{params.data.numero_cta}</td>
                <td>{estado}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} resalta={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return <div className="cont_tabla">
                <table>
                    <thead>
                        <tr>
                            <Cell>Nombre</Cell>
                            <Cell>Descripción</Cell>
                            <Cell>Nº Cuenta</Cell>
                            <Cell>Estado</Cell>
                        </tr>
                    </thead>

                    <tbody>
                        {_data}
                    </tbody>
                </table>
            </div>;
        }
    }

    const handleKey = (e) => {
        e.preventDefault();

        if (e.key === "Enter") {
            handleSearch(e);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();

        if (criterio.length > 0) {
            setShowLoading(true);

            setFiltro({
                type: "search",
                criterio: criterio
            });
        }
    }

    const handleShowAll = (e) => {
        e.preventDefault();

        setShowLoading(true);

        setCriterio("");

        setFiltro({ type: "all" });
    }

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-metodos-pago",
            data: filtro
        });

        if (_data.response == "1") {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo("Métodos de pago");
        getInfo();
    }, [filtro]);

    return (
        <div className="cont_principal deg_top">

            <div className="cont_titulo only_searsh">
                <h2>Métodos de pago</h2>
                {(auth && auth.nivel) <= 2 && <Link to="/nuevo-metodo-pago">Crear nuevo <ArrowRigth /></Link>}

                {filtro.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                <div className="search_bar">
                    <input type="text" placeholder="Buscar por nombre"
                        value={criterio} onChange={e => setCriterio(e.target.value)}
                        onKeyUp={handleKey} />
                    <MagnifyIcon onClick={handleSearch} />
                </div>
            </div>

            <AllRow />
            {showLoading && <Loading />}
        </div>
    );

}

export default MetodoPago;