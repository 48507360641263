import * as React from "react";
import { useLocation, useNavigate, NavLink, Outlet } from "react-router-dom";
import PlantillasIcon from "mdi-react/FileDocumentBoxMultipleIcon";
import PackageIcon from "mdi-react/PackageVariantClosedIcon";
import DownloadIcon from "mdi-react/ClipboardArrowDownIcon";
import HomeChartIcon from "mdi-react/HomeAnalyticsIcon";
import logo_black from "../../images/logo_black.png";
import ArrowRight from "mdi-react/ChevronRightIcon";
import LoginIcon from "mdi-react/AccountCircleIcon";
import UsersIcon from "mdi-react/AccountGroupIcon";
import PerfilIcon from "mdi-react/AccountCogIcon";
import CashIcon from "mdi-react/CashMultipleIcon";
import ArrowLeft from "mdi-react/ChevronLeftIcon";
import OperadorIcon from "mdi-react/KayakingIcon";
import KeyIcon from "mdi-react/KeyArrowRightIcon";
import ExitIcon from "mdi-react/ExitToAppIcon";
import BasketIcon from "mdi-react/BasketIcon";
import CartIcon from "mdi-react/CartIcon";
import HomeIcon from "mdi-react/HomeIcon";
import useBasket from "hooks/useBasket";
import classNames from "classnames";
import useAuth from "hooks/useAuth";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */

const Layout = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { basket } = useBasket();
  const { pathname } = useLocation();
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [cantidad, setCantidad] = React.useState(0);
  const sidebarClass = classNames({
    'sidebar': true,
    'sidebar--show': showSidebar,
  });

  const changeVisibility = (e) => {
    e.preventDefault();
    setShowSidebar(!showSidebar);
  }

  const handleLogin = (e) => {
    e.preventDefault();

    if (auth === null) {

      if (pathname !== '/log-in') {
        navigate('/log-in');
      }
    } else {
      setShowMenu(!showMenu);
    }
  }

  const handleOption = (e, _opt) => {
    e.preventDefault();
    e.stopPropagation();

    setShowMenu(!showMenu);

    switch (_opt) {
      case "exit":
        navigate('/log-in');
        break;
      case "perfil":
        navigate("/detalle-usuario", { state: { data: { ...auth, id: window.atob(auth.auth) } } });
        break;
      case "change":
        navigate('/cambia-clave');
        break;
    }
  }

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate("/cotizacion");
  }

  React.useEffect(() => {
    let _cantidad = 0;
    basket.forEach(item => {
      _cantidad += item.cantidad;
    });
    setCantidad(_cantidad);
  }, [basket]);

  return (
    <div className={sidebarClass}>
      <header>
        <div className="logo">
          <img src={logo_black} alt="Logo principal" />
        </div>

        {basket.length > 0 &&
          <div className="basket_data"
            onClick={handleOpen}>
            <BasketIcon />
            <span>{cantidad}</span>
          </div>}

        <div className="user_data" onClick={handleLogin}>
          <LoginIcon />
          <span>{auth ? auth.nombre_completo : 'Iniciar Sesión'}</span>
          {showMenu &&
            <>
              <div className="bkg_menu"></div>
              <ul>
                <li onClick={e => handleOption(e, "perfil")}>
                  <p><PerfilIcon />Mi perfil</p>
                </li>

                <li onClick={e => handleOption(e, "change")}>
                  <p><KeyIcon />Cambiar clave</p>
                </li>

                <li onClick={e => handleOption(e, "exit")}>
                  <p><ExitIcon />Cerrar sesión</p>
                </li>
              </ul>
            </>
          }
        </div>

        {pathname === '/log-in' || pathname === '/sign-up' || pathname === '/forgot-password' ?
          null
          :
          <nav>
            <div className="toggle_menu" onClick={changeVisibility}>
              {showSidebar ?
                <ArrowLeft />
                :
                <ArrowRight />
              }
            </div>

            <ul>
              <li>
                <NavLink to="/">
                  {(!auth || auth.nivel == 4) ?
                    <HomeIcon />
                    :
                    <HomeChartIcon />
                  }
                  <span>Inicio</span>
                </NavLink>
              </li>

              <li>
                {(auth && auth.nivel == 4) ?
                  <NavLink to="/mis-compras"><CartIcon /> <span>Mis Compras</span></NavLink>
                  :
                  <NavLink to="/ordenes"><CartIcon /> <span>Ordenes</span></NavLink>
                }
              </li>
              {(auth && auth.nivel <= 2) &&
                <>
                  <li>
                    <NavLink to="/productos"><PackageIcon /> <span>Productos</span></NavLink>
                  </li>

                  {(auth && auth.nivel < 2) &&
                    <li>
                      <NavLink to="/plantillas"><PlantillasIcon /> <span>Plantillas</span></NavLink>
                    </li>
                  }

                  <li>
                    <NavLink to="/operadores"> <OperadorIcon /> <span>Operadores</span></NavLink>
                  </li>

                  <li>
                    <NavLink to="/metodos-pago"><CashIcon /> <span>Mét. de pago</span></NavLink>
                  </li>
                </>
              }

              {(auth && auth.nivel < 2) &&
                <>
                  <li>
                    <NavLink to="/usuarios"><UsersIcon /> <span>Usuarios</span></NavLink>
                  </li>

                  <li>
                    <NavLink to="/reportes"><DownloadIcon /> <span>Reportes</span></NavLink>
                  </li>
                </>
              }
              <li>
                <p style={{ height: 20 }}></p>
              </li>
            </ul>
          </nav>
        }
      </header>

      <main className="wrapper">
        <Outlet />
        <div className="footer"></div>
      </main>
    </div>
  );
}

export default Layout;