import * as React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import KeyOutlineIcon from "mdi-react/KeyOutlineIcon";
import logo from "../../images/logo.png";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";


const LogIn = () => {
  const { setAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const returTo = location.state?.from?.pathname || "/";
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [pass, setPass] = React.useState(null);

  const inputMail = React.useRef(null);
  const inputPass = React.useRef(null);


  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    if (validaMail() && validateFormatEmail() && validaPass()) {
      setShowLoading(true);
      let _data = await api.signIn(email, pass);

      if (_data.response === "1") {
        let _user = {
          auth: window.btoa(_data.data.id),
          nivel: _data.data.nivel,
          correo: _data.data.correo,
          telefono: _data.data.telefono,
          tipo_doc: _data.data.tipo_doc,
          num_doc: _data.data.num_doc,
          nombre: _data.data.nombre,
          apellido: _data.data.apellido,
          nombre_completo: _data.data.nombre + " " + _data.data.apellido,
        };

        setAuth(_user);

        setTimeout(() => {
          setShowLoading(false);
          // navigate(returTo, { replace: true, state: { from: location } });
          navigate(returTo, { replace: true });
        }, 2000);

      } else {
        let _msg = _data.data ? _data.data.msg : _data.msg;
        setShowLoading(false);
        Alert(_msg, "warning");
      }
    }
  }

  const validateFormatEmail = () => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(String(email).toLowerCase())) {
      alerta(inputMail.current, "El formato del correo es incorrecto");

      return false;
    } else {
      return true;
    }
  }

  const validaMail = () => {
    alertaRemove(inputMail.current);

    if (email == null || email.length < 1) {
      alerta(inputMail.current, "El correo es requerido");
      //inputMail.current.focus();
      return false;
    } else {
      return true;
    }
  }

  const validaPass = () => {
    alertaRemove(inputPass.current);

    if (pass == null || pass.length < 1) {
      alerta(inputPass.current, "La contraseña es requerida");
      //inputPass.current.focus();
      return false;
    } else if (pass.length < 8) {
      alerta(inputPass.current, "The password is too short");

      return false;
    } else {
      return true;
    }
  }

  const clearSesion = () => {
    setAuth(null);
  }

  React.useEffect(() => {
    document.title = "Iniciar Sesión";
    clearSesion();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="cont_principal deg_top cont_forms">

      <div className="form_principal">

        <div className="cont_logo">
          <img src={logo} alt="logo principal" />
          <p>Bienvenido, inicia tu sesión para poder ingresar al sistema.</p>
        </div>

        <label>Correo</label>
        <div className="input_icon">
          <EmailOutlineIcon />
          <input type="text"
            name="correo"
            placeholder='Correo electronico'
            ref={inputMail}
            onChange={({ target: { value } }) => { alertaRemove(inputMail.current); setEmail(value) }} />
        </div>

        <label>Cotraseña</label>

        <div className="contrasenia">
          <KeyOutlineIcon />
          <input type={isPasswordShown ? 'text' : 'password'}
            name="password"
            placeholder='Contraseña'
            ref={inputPass}
            onChange={({ target: { value } }) => { alertaRemove(inputPass.current); setPass(value) }} />
          {isPasswordShown ? <EyeOffOutlineIcon className="eye_icon" onClick={() => handleShowPassword()} /> : <EyeOutlineIcon className="eye_icon" onClick={() => handleShowPassword()} />}
        </div>

        <div className="cont_to_rigth">
          <Link to='/forgot-password'>¿Olvidasate contraseña?</Link>
        </div>

        <button onClick={handleLogin}>Inicar Sesión</button>

        <div className="registrate">
          <p>¿No tienes una cuenta? <Link to='/sign-up'> Registrate Aquí</Link></p>
        </div>

      </div>

      <p className="footer">© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
      {showLoading && <Loading />}
    </div>
  );
}

export default LogIn;