import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SwapVerticalIcon from 'mdi-react/SwapVerticalIcon';
import funciones from "services/funciones";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";
import MenuRow from "./MenuRowMetodosPago";
import Cell from "shared/Cell";
import api from "services/api";


const ListaMetodosPago = ({total, loading}) => {
    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [dataList, setDataList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [asc, setAsc] = React.useState(true);
    

    const Row = (params) => {

        return (
            <MenuRow {...params}
                current={selected.id}
                onSel={setSelected}
            // reload={getList}
            >
                <td>{params.data.nombre_metodo_pago}</td>
                <td style={{ textAlign: "center" }}>{params.data.cantidad}</td>
                <td>{params.data.suma_valor ? "$" + funciones.formatPrice(params.data.suma_valor).format : "0"}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];
        dataList.map((item, index) => {
            _data.push(<Row key={item.id} resalta={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return <div className="cont_tabla">
                <table>
                    <thead>
                        <tr>
                            <Cell><SwapVerticalIcon onClick={() => sortBy("nombre_metodo_pago")} />Método de pago</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("cantidad")} />Cantidad ordnes</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("suma_valor")} />Valor</Cell>
                        </tr>
                    </thead>

                    <tbody>
                        {_data}
                    </tbody>
                </table>
            </div>;
        }
    }

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-pagos",
            data: {
                type: "por_metodo_pago",
                id: window.atob(auth.auth)
            }
        });

        if (_data.response == "1") {
            let _totales = 0;
            setDataList(_data.data);
            _data.data.map((item, index) => {
                _totales += parseInt(item.suma_valor);
            });
            total(_totales);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
        loading(false);
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const sortByNum = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: parseInt(item[_key]) };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                return a.value - b.value;
            } else {
                return b.value - a.value;
            }
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    React.useEffect(() => {
        getInfo();
    }, []);

    return (
        <div className="_tabs_content">
            <AllRow />
        </div>
    );

}

export default ListaMetodosPago;