import * as React from 'react';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import CityVariantOutlineIcon from 'mdi-react/CityVariantOutlineIcon';
import PhoneInTalkOutlineIcon from 'mdi-react/PhoneInTalkOutlineIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CardIcon from 'mdi-react/CardAccountPhoneOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import EditIcon from "mdi-react/EditOutlineIcon";
import WebIcon from 'mdi-react/WebSyncIcon';
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DetalleOperador = () => {
  const navigate = Rdom.useNavigate();
  const location = Rdom.useLocation();
  const { setTitulo } = useTitle();
  const [ciudades, setCiudades] = React.useState([{ post: 0, nombre: '' }]);
  const data = location.state?.data;

  const goEdit = (e) => {
    e.preventDefault()

    navigate("/editar-operador", { state: { data: data, from: location } });
  }

  const handleClose = (e) => {
    e.preventDefault()

    if (location.state?.from?.pathname == "/operadores") {
      navigate(-1);
    } else {
      navigate("/operadores", { replace: true });
    }
  }

  const armaCiudades = () => {
    let _ciudades = [];

    if (data.ciudades) {
      data.ciudades.split(',').forEach((ciudad, index) => {
        _ciudades.push({ post: index, nombre: ciudad });
      });

      setCiudades(_ciudades);
    }
  }

  React.useEffect(() => {
    setTitulo("Detalle operador");
    armaCiudades();
  }, []);

  return (
    <div className="cont_principal deg_top cont_forms">

      <div className="form_principal">
        <h2 className="title_close">Detalle Operador ({data.id}) <CloseIcon className="close" onClick={handleClose} /> </h2>

        <div className="fila">

          <div className="columna">
            <label>Nombre</label>
            <div className="input_icon">
              <AccountOutlineIcon />
              <p className="_to_p">{data.nombre}</p>
            </div>
          </div>

        </div>

        <div className="fila">

          <div className="columna">
            <label>Nit</label>
            <div className="input_icon">
              <CardAccountDetailsOutlineIcon />
              <p className="_to_p">{data.nit}</p>
            </div>
          </div>

          <div className="columna">
            <label>Sitio web</label>
            <div className="input_icon">
              <WebIcon />
              <p className="_to_p">{data.url || "..."}</p>
            </div>
          </div>

        </div>

        <div className="fila">

          <div className="columna">
            <label>Correo</label>
            <div className="input_icon">
              <EmailOutlineIcon />
              <p className="_to_p">{data.correo}</p>
            </div>
          </div>

          <div className="columna">
            <label>Teléfono empresa</label>
            <div className="input_icon">
              <PhoneInTalkOutlineIcon />
              <p className="_to_p">{data.telefono}</p>
            </div>
          </div>

        </div>

        <div className="fila">

          <div className="columna">
            <label>Persona de Contacto</label>
            <div className="input_icon">
              <CardIcon />
              <p className="_to_p">{data.representante}</p>
            </div>
          </div>

          <div className="columna">
            <label>Teléfon personal</label>
            <div className="input_icon">
              <CellphoneIcon />
              <p className="_to_p">{data.celular}</p>
            </div>
          </div>

        </div>

        <div className="fila">

          <div className="columna">
            {ciudades.map((ciudad, index) => {

              return (
                <div key={index}>
                  <label>Ciudad {ciudad.post + 1}</label>
                  <div className="input_icon">
                    <CityVariantOutlineIcon />
                    <p className="_to_p">{ciudad.nombre || "..."}</p>
                  </div>
                </div>
              )
            })}
          </div>


          <div className="columna" style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
            <button type="button" className="btn_link" onClick={goEdit}>Editar <EditIcon /></button>
          </div>

        </div>

      </div>

    </div>
  );
}

export default DetalleOperador;
