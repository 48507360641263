import * as React from "react";
import { numeroALetras } from "services/funciones";
import logo from "../../../images/logo_black.png";
import { useReactToPrint } from "react-to-print";
import Autorenew from 'mdi-react/AutorenewIcon';
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import Check from "mdi-react/CheckIcon";
import { Alert } from "shared/Alert";
import QRCode from "react-qr-code";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

/**
 * 
 * Barcodes Type
 *
 *   CODE128
 *   EAN / UPC
 *   CODE39
 *   ITF-14
 *   MSI
 *   Pharmacode
 *   Codabar
 *
 */

const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

const Print = (param) => {
    const { data, pos, close } = param;
    const [allLabels, setAllLabels] = React.useState(<h4 className="_loadind"><Autorenew /> Generando impresión...</h4>);
    const [_style, setStyle] = React.useState(pos);
    const template_print = React.useRef();
    const navigate = Rdom.useNavigate();


    const printOrder = useReactToPrint({
        content: () => template_print.current
    });

    const getDetalle = async () => {
        let _list = [];
        let _data = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "por_id",
                id: data.id
            }
        });

        let _packs = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "productos_x_orden",
                id: data.id
            }
        });

        let _templates = await api.fetchJson({
            url: "get-templates",
            data: {
                type: "por_orden",
                id: data.id
            }
        });


        if (_data.response == 1) {
            _data = _data.data[0];
            let _total = parseInt(_data.valor_total - (_data.valor_total * parseInt(_data.descuento) / 100));

            _total = {
                neto: parseInt(_data.valor_total),
                abonos: parseInt(_data.abonos),
                total: _total,
                saldo: _data.abonos ? parseInt(_total - _data.abonos) : _total
            };

            _list.push(
                <div className="impress_label" key={_data.id}>
                    <div className="_header">
                        <img src={logo} alt="Logo de la emprsa" />

                        <div className="_header_info">
                            <h3>TOBIAEXTREME</h3>
                            <p>NIT 80149023-1</p>
                            <p>PBX: 7559952</p>
                            <p>ventas@tobiaextreme.com</p>
                        </div>

                        <QRCode
                            size={150}
                            value={process.env.REACT_APP_URL_ORDER + data.id} />

                        <div className="_header_num">
                            <p>{funciones.estadoOrden(data.estado)}</p>
                            <h2>N°: {meses[new Date(data.fecha_ejecucion * 1000).getMonth()]}-{data.id}</h2>
                        </div>
                    </div>

                    <div className="_body">
                        <div className="_body_info">
                            <div className="_body_info_user">
                                <div className="_info_row">
                                    <div className="_row">
                                        <h3>Señor@</h3>
                                        <p>{data.nombre} {data.apellido}</p>
                                    </div>
                                    <div className="_row">
                                        <h3>{funciones.tipoDoc(data.tipo_doc)}</h3>
                                        <p>{data.num_doc}</p>
                                    </div>
                                </div>

                                <div className="_info_row">
                                    <div className="_row">
                                        <h3>Correo</h3>
                                        <p>{data.correo}</p>
                                    </div>
                                    <div className="_row">
                                        <h3>Teléfono</h3>
                                        <p>{data.telefono}</p>
                                    </div>
                                </div>

                                <div className="_info_row">
                                    <div className="_row">
                                        <h3>Nota</h3>
                                        <p>{data.notas}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="_body_info_date">
                                <h2>Fecha y caducidad</h2>

                                <div className="_info_row">
                                    <h3>Fecha creada</h3>
                                    <p>{funciones.formatFecha(data.fecha_creacion)}</p>
                                </div>

                                <div className="_info_row">
                                    <h3>Fecha actividad</h3>
                                    <p>{funciones.formatFecha(data.fecha_ejecucion)}</p>
                                </div>
                            </div>
                        </div>

                        <table className="_body_items">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Destino</th>
                                    <th>Vr. unitario</th>
                                    <th>Cantidad</th>
                                    <th>Vr. Total</th>
                                </tr>
                            </thead>

                            <tbody>
                                {_packs.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.producto}</td>
                                            <td>{item.nombre_producto}</td>
                                            <td>{item.descripcion}</td>
                                            <td>{item.nombre_ciudad}</td>
                                            <td>${funciones.formatPrice(item.valor_producto).format}</td>
                                            <td>{item.cantidad}</td>
                                            <td>${funciones.formatPrice(item.valor_producto * item.cantidad).format}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>

                    <div className="_valores">
                        <p>
                            <b>Total en letras: </b> {numeroALetras(_total.total).toLocaleLowerCase()}
                            <br />
                            <br />
                            <b>Saldo en letras: </b> {numeroALetras(_total.saldo).toLocaleLowerCase()}
                        </p>

                        <h4>
                            {data.descuento >= 1 && <>
                                <b>Neto:</b> <span>${funciones.formatPrice(_total.neto).format} COP</span><br />
                                <samp>Descuento del {data.descuento}%</samp>
                            </>}

                            <b>Total:</b> <span>${funciones.formatPrice(_total.total).format} COP</span><br />
                            <hr />
                            <b>Abonado:</b> <span>{_total.abonos ? "$" + funciones.formatPrice(_total.abonos).format + " COP" : "0"}</span><br />
                            <hr />
                            <b>Saldo:</b> <span>{_total.saldo > 0 ? "$" + funciones.formatPrice(_total.saldo).format + "COP" : "0"}</span><br />
                            <samp className="_last">{data.metodos_pago ? data.metodos_pago : "..."}</samp>
                        </h4>
                    </div>

                    <div className="_anexo">
                        {_templates.data.map((doc, index) => {
                            switch (parseInt(doc.tipo)) {
                                case 1:
                                    return <h3 key={index}>{doc.contenido}</h3>;
                                case 2:
                                    return <p key={index}>{doc.contenido}</p>;
                                case 3:
                                    return <p key={index} className="_option"><Check />{doc.contenido}</p>;
                            }
                        })}
                    </div>

                    <div className="_footer">
                        <p>© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
                    </div>

                </div>
            );

            setAllLabels(_list);
            setStyle({
                // ...pos,

                width: "100%",
                position: "relative",

                borderRadius: 0,
                boxShadow: "none",
                padding: 0
            });
            printOrder();
            close(false);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate(-1));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {
        setTimeout(() => {
            getDetalle();
        }, 1000);
    }, [data]);

    return (
        <div className="content_modal">
            {/* <div className="back_form _bkg_white"></div> */}
            <div className="win_modal _impress" style={_style} ref={template_print}>

                {allLabels}
            </div>
        </div>
    );
}

export default Print;