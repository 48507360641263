import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyCAS_Qjo4dciKlcUb-GCNzL9lTy8_G0Rvs",
  authDomain: "tobia-extreme.firebaseapp.com",
  projectId: "tobia-extreme",
  storageBucket: "tobia-extreme.appspot.com",
  messagingSenderId: "189520255001",
  appId: "1:189520255001:web:147b26033bb932169ab288"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;