import CryptoJS from "crypto-js";
const WEB_API = process.env.REACT_APP_WEB_API;
const SERVER_NAME = process.env.REACT_APP_SERVER_NAME;


class API {

    /**
     * Genera la firma de autenticación
     * const authorization = 'Digest auth="YWRtaW4=", time="1620369347", response="8531882e866fafe922b2d8636ff8a911"';
     * @return: 
     */
    getAuth() {
        let _data = JSON.parse(localStorage.getItem('usuario'));
        let auth = _data == null ? window.btoa(0) : _data.auth;
        let respuesta_valida;
        let live_time;

        if (typeof android !== "undefined") {
            live_time = new Date().getTime() / 1000;
            respuesta_valida = CryptoJS.MD5(auth + ":" + SERVER_NAME + ":" + live_time).toString();

            return `Digest auth="${auth}", time="${live_time}", response="${respuesta_valida}"`;
        } else {
            let current_time = new Date().getTime() / 1000;

            live_time = _data == null ? 0 : _data.time;

            console.log("Minutos: ", ((current_time - live_time) / 60).toFixed(0));

            if (current_time - live_time > 3600) {

                localStorage.removeItem('usuario');

                respuesta_valida = CryptoJS.MD5(auth + ":" + SERVER_NAME + ":" + current_time).toString();

                return `Digest auth="${auth}", time="${live_time}", response="${respuesta_valida}"`;
            } else {

                respuesta_valida = _data == null ? 0 : CryptoJS.MD5(auth + ":" + SERVER_NAME + ":" + current_time).toString();

                localStorage.setItem('usuario', JSON.stringify({
                    ..._data,
                    time: parseInt(current_time),
                    token: respuesta_valida
                }));

                return `Digest auth="${auth}", time="${current_time}", response="${respuesta_valida}"`;
            }
        }

    }

    /**
     * Verifica el estado de la respuesta en la consulta 
     * @param response: La respuesta generada en la consulta 
     */
    verificaEstado(response) {
        switch (response.status) {
            case 200:
            case 210:
                //console.log('Conexión corecta', response.status);
                break;
            case 401:
            case 403:
            case 422:
                console.log('Error', response.status);
                break;
            case 404:
                throw new Error('404 - No se encuentra el recurso!');
            case 500:
                throw new Error('500 - Ocurrio un error en la sintaxis!');
            case 503:
                throw new Error('503 - Servicio no disponible!');
            default:
                throw new Error(`(${response.status}) es un código inesperado, puedes reportarlo al correo "desarrollo@sarguero.com" para revisarlo mas a fondo`);
        }
    }

    /**
     * Verifica el correo y la clave en la base de input_icon 
     * @param {String} email: el correo del usuario en texto plano
     * @param {String} pass: La clave del usuario en texto plano
     * @return {Promise<JSON>}: Json con los input_icon del usuario 
     */
    async signIn(email, pass) {
        let clave = CryptoJS.SHA256(pass);
        let input_icon;
        let token = "";

        if (typeof android !== "undefined") {
            // eslint-disable-next-line no-undef
            token = await android.getToken();

            console.log("token: " + token);
        }

        await fetch(`${WEB_API}sign-in`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: JSON.stringify({
                email: email.trim(),
                pass: clave.toString(),
                token: token
            })
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                input_icon = data;
            })
            .catch((error) => {
                input_icon = { "response": -1, "msg": error.toString() };
            });

        return input_icon;
    }

    /**
     * Hace una consulta al servidor 
     * @param {JSON} params: json con { `url`: string, `data`: json }
     * @return {Promise<JSON>}: `json` con los input_icon solicitados 
     */
    async fetchJson(params) {
        let input_icon;

        //console.log(`Url: ${params.url} data:`, params.data);

        await fetch(WEB_API + params.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                'Authorization': this.getAuth()
            },
            body: JSON.stringify(params.data)
        })
            .then(response => {
                this.verificaEstado(response);

                return response.json();
            })
            .then(data => {
                input_icon = data;
            })
            .catch((error) => {
                console.log('Ocurrio un error:', error);
                input_icon = { "response": -1, "msg": error.toString() };
            });

        //console.log("Respondio: ", input_icon);
        return input_icon;
    }
}

export default new API();