import * as React from "react";
import ListaProductos from "../Productos/components/ListaProductos";
import ChartAreaVentas from "./components/ChartAreaVentas";
import CardAdmin from "./components/CardAdmin";
import CardOperadores from "./components/CardOperadores";
import CardProductos from "./components/CardProductos";
import CierreCaja from "./components/CierreCaja";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useNavigate } from "react-router-dom";
import CardPagos from "./components/CardPagos";
import useTitle from "hooks/useTitle";
import useAuth from "hooks/useAuth";

/* eslint eqeqeq: 0 */
/* eslint react/jsx-pascal-case: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Home = () => {
    const [showList, setShowList] = React.useState(false);
    const { setTitulo } = useTitle();
    const navigate = useNavigate();
    const { auth } = useAuth();


    const ViewHome = () => {
        const [criterio, setCriterio] = React.useState("");
        let toReturn = null;

        const handleKey = (e) => {
            e.preventDefault();

            if (e.key === "Enter") {
                handleSearch(e);
            }
        }

        const handleSearch = (e) => {
            e.preventDefault();

            if (criterio.length > 0) {
                navigate("/ordenes", {
                    state: {
                        criterio: criterio
                    }
                });
            }
        }

        if (!auth || auth.nivel == 4) {
            toReturn = <ListaProductos />;
        } else {
            if (showList) {
                toReturn = <ListaProductos hiddenList={setShowList} home={true} />;
            } else {
                toReturn = <div className="cont_home">

                    <div className="card big">

                        <div className="in_row">
                            <h2>Ordenes</h2>

                            <div className="search_bar">
                                <input type="text" placeholder="Buscar por c.c, nombre, apellidos o teléfono"
                                    value={criterio} onChange={e => setCriterio(e.target.value)}
                                    onKeyUp={handleKey} />
                                <MagnifyIcon onClick={handleSearch} />
                            </div>
                        </div>

                        <CierreCaja showList={setShowList} />

                        {(auth && auth.nivel) <= 2 ?
                            <CardAdmin />
                            :
                            <ChartAreaVentas user={{ id: 0 }} />
                        }

                    </div>

                    <div className="cards_vertical">

                        <CardProductos />

                        {(auth && auth.nivel) <= 2 &&
                            <CardOperadores />
                        }

                        {(auth && auth.nivel) <= 2 &&
                            <CardPagos />
                        }

                    </div>
                </div>;
            }
        }

        return (toReturn);
    }

    React.useEffect(() => {
        setTitulo("Tobia Extreme");
    }, []);

    return (
        <>
            {(!auth || auth.nivel) == 4 ?
                <ListaProductos />
                :
                <ViewHome />
            }
        </>
    );
}

export default Home;