import * as React from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { Alert, alerta, alertDown, alertaRemove } from "shared/Alert";
import ComboIcon from "mdi-react/ClipboardTextMultipleOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import DocumentIcon from "mdi-react/TextBoxPlusOutlineIcon";
import DownIcon from "mdi-react/ArrowDownCircleOutlineIcon";
import OrderIcon from "mdi-react/ClipboardTextOutlineIcon";
import DeleteIcon from "mdi-react/DeleteEmptyOutlineIcon";
import CashMultipleIcon from "mdi-react/CashMultipleIcon";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import CityIcon from "mdi-react/CityVariantOutlineIcon";
import OperadorIcon from "mdi-react/KayakingIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import funciones from "services/funciones";
import * as Iconos from "../../../images";
import * as Rdom from "react-router-dom";
import DropZone from "shared/DropZone";
import useTitle from "hooks/useTitle";
import { Input } from "shared/Input";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const AgregarProducto = () => {
    const { auth } = useAuth();
    const fauth = getAuth();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const { setTitulo } = useTitle();
    const [showSelectOpt, setShowSelectOpt] = React.useState(true);
    const [showLoading, setShowLoading] = React.useState(false);
    const [showListProductos, setShowListProductos] = React.useState(false);
    const [showListDocuments, setShowListDocuments] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [nombre, setNombre] = React.useState("");
    const [operador, setOperador] = React.useState("");
    const [ciudad, setCiudad] = React.useState("");
    const [ciudades, setCiudades] = React.useState([]);
    const [precio, setPrecio] = React.useState("");
    const [costo, setCosto] = React.useState("");
    const [descripcion, setDescripcion] = React.useState("");
    const [tipo, setTipo] = React.useState(1);
    const [itemsProducts, setItemsProducts] = React.useState([]);
    const [itemsDocuments, setItemsDocuments] = React.useState([]);
    const [listaProductos, setListaProductos] = React.useState([]);
    const [listaDocumentos, setListaDocumentos] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [filtroProducto, setFiltroProducto] = React.useState({ type: "all" });
    const [filtroDocumentos, setFiltroDocumentos] = React.useState({ type: "all" });
    const form_ref = React.useRef(null);


    const handleShowDocuments = (e) => {
        e.preventDefault();
        alertaRemove(form_ref.current.documentos);

        if (showListDocuments) {
            document.body.style.overflow = "auto";
            setShowListDocuments(false);
            setCriterio("");
        } else {
            document.body.style.overflow = "hidden";
            setShowListDocuments(true);
        }
    }

    const handleShowList = (e) => {
        e.preventDefault();
        alertaRemove(form_ref.current.productos);

        if (showListProductos) {
            document.body.style.overflow = "auto";
            setShowListProductos(false);
            setCriterio("");
        } else {
            document.body.style.overflow = "hidden";
            setShowListProductos(true);
        }
    }

    const ListaDocumentos = () => {
        const search_documents_ref = React.useRef(null);
        let lista = [];

        const handleKey = (e) => {
            e.preventDefault();

            if (e.key === "Enter") {
                handleSearch(e);
            }
        }

        const handleSearch = (e) => {
            e.preventDefault();

            if (criterio.length > 0) {
                setShowLoading(true);

                setFiltroDocumentos({
                    type: "search",
                    criterio: criterio
                });
            }
        }

        const handleShowAll = (e) => {
            e.preventDefault();

            setShowLoading(true);

            setCriterio("");

            setFiltroDocumentos({
                type: "all"
            });
        }

        const handleAdd = (e, data) => {
            e.preventDefault();

            let _new = listaDocumentos.map((item) => {
                if (item.id === data.id) {
                    if (item.selected) {
                        item.selected = false;
                    } else {
                        item.selected = true;
                    }
                }

                return item;
            });

            setListaDocumentos(_new);
        }

        const handleAcept = (e) => {
            e.preventDefault();

            let _new = listaDocumentos.filter((item) => {
                if (item.selected) {
                    return item;
                }
            });

            setItemsDocuments(_new);
            document.body.style.overflow = "auto";
            setShowListDocuments(false);
        }

        listaDocumentos.map((item, index) => {
            lista.push(<label key={index}> <input type="checkbox" name="casilla" checked={item.selected} onChange={e => handleAdd(e, item)} />
                <b>{item.id}</b> - {item.nombre}
            </label>);
        });

        if (lista.length < 1) {
            lista = <h4 className="titulo_msg">No hay resultados</h4>;
        }

        React.useEffect(() => {
            if (criterio.length > 0) {
                search_documents_ref.current.focus();
            }
        }, [criterio]);

        return (
            <>
                <div className="back_shadow"></div>
                <div className="popup_productos">

                    <h2>Lista de plantillas</h2>
                    <p>Acontinuación selecciona la plantilla que se vinculará al producto.</p>

                    <div className="cont_titulo _in_list">
                        <div className="search_bar">
                            <input type="text" placeholder="Buscar por nombre"
                                ref={search_documents_ref}
                                value={criterio} onChange={e => setCriterio(e.target.value)}
                                onKeyUp={handleKey} />
                            <MagnifyIcon onClick={handleSearch} />
                        </div>
                        {filtroDocumentos.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                    </div>

                    <div className="cont_item_list">
                        {lista}
                    </div>

                    <div className="botones">
                        <button type="button" onClick={handleShowDocuments}>Cancelar</button>
                        <button type="button" onClick={handleAcept}>Agregar</button>
                    </div>

                </div>
            </>
        );
    }

    const ListaProductos = () => {
        const search_product_ref = React.useRef(null);
        let lista = [];

        const handleKey = (e) => {
            e.preventDefault();

            if (e.key === "Enter") {
                handleSearch(e);
            }
        }

        const handleSearch = (e) => {
            e.preventDefault();

            if (criterio.length > 0) {
                setShowLoading(true);

                setFiltroProducto({
                    type: "search",
                    criterio: criterio
                });
            }
        }

        const handleShowAll = (e) => {
            e.preventDefault();

            setShowLoading(true);

            setCriterio("");

            setFiltroProducto({
                type: "all"
            });
        }

        const handleAdd = (e, data) => {
            e.preventDefault();

            let _new = listaProductos.map((item) => {
                if (item.id === data.id) {
                    if (item.selected) {
                        item.selected = false;
                    } else {
                        item.selected = true;
                    }
                }

                return item;
            });

            setListaProductos(_new);
        }

        const handleAcept = (e) => {
            e.preventDefault();

            let _new = listaProductos.filter((item) => {
                if (item.selected) {
                    return item;
                }
            });

            if (tipo == 2) {
                getDocumentosPorProducto(_new);
            }

            setItemsProducts(_new);
            document.body.style.overflow = "auto";
            setShowListProductos(false);
        }

        listaProductos.map((item, index) => {
            lista.push(<label key={index}> <input type="checkbox" name="casilla" checked={item.selected} onChange={e => handleAdd(e, item)} />
                <b>{item.id}</b> - {item.nombre} - <span>{item.nombre_operador} ( ${funciones.formatPrice(item.valor_venta).format} COP )</span>
            </label>);
        });

        if (lista.length < 1) {
            lista = <h4 className="titulo_msg">No hay resultados</h4>;
        }

        React.useEffect(() => {
            if (criterio.length > 0) {
                search_product_ref.current.focus();
            }
        }, [criterio]);

        return (
            <>
                <div className="back_shadow"></div>
                <div className="popup_productos">

                    <h2>Lista de Productos</h2>
                    <p>Acontinuación seleccione el producto que desea añadir al combo.</p>

                    <div className="cont_titulo _in_list">
                        <div className="search_bar">
                            <input type="text" placeholder="Buscar por código o nombre"
                                ref={search_product_ref}
                                value={criterio} onChange={e => setCriterio(e.target.value)}
                                onKeyUp={handleKey} />
                            <MagnifyIcon onClick={handleSearch} />
                        </div>
                        {filtroProducto.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                    </div>

                    <div className="cont_item_list">
                        {lista}
                    </div>

                    <div className="botones">
                        <button type="button" onClick={handleShowList}>Cancelar</button>
                        <button type="button" onClick={handleAcept}>Agregar</button>
                    </div>

                </div>
            </>
        );
    }

    const ItemsDocumentos = () => {
        let items = [];

        const handleMove = (e, position) => {
            e.preventDefault();
            let _new = [];
            let _arr = itemsDocuments;
            let old_index = parseInt(position);
            let new_index = parseInt(position) + 1;

            if (new_index >= _arr.length) {
                _arr.splice(0, 0, _arr.splice(old_index, 1)[0]);
            } else {
                _arr.splice(new_index, 0, _arr.splice(old_index, 1)[0]);
            }

            _arr.map(item => {
                _new.push(item);
            });

            setItemsDocuments(_new);
        }

        const handleRemove = (e, data) => {
            e.preventDefault();

            let _new = itemsDocuments.filter((item) => {
                if (item.id !== data.id) {
                    return item;
                }
            });

            let _new_products = listaDocumentos.map((item) => {
                if (item.id === data.id) {
                    item.selected = false;
                }

                return item;
            });

            setItemsDocuments(_new);
            setListaDocumentos(_new_products);
        }

        itemsDocuments.map((item, index) => {
            items.push(<p key={index}>
                <DeleteIcon onClick={e => handleRemove(e, item)} />
                {itemsDocuments.length > 1 && <DownIcon className="arrow" onClick={e => handleMove(e, index)} />}
                <b>{item.id}</b> {item.nombre}
            </p>);
        });

        return (
            <div className="fila">
                <div className="items_agregados">
                    {items}
                </div>
            </div>
        );
    }

    const ItemsProductos = () => {
        let items = [];

        const handleRemove = (e, data) => {
            e.preventDefault();

            let _new = itemsProducts.filter((item) => {
                if (item.id !== data.id) {
                    return item;
                }
            });

            let _new_products = listaProductos.map((item) => {
                if (item.id === data.id) {
                    item.selected = false;
                }

                return item;
            });

            setItemsProducts(_new);
            setListaProductos(_new_products);
        }

        itemsProducts.map((item, index) => {
            items.push(<p key={index}> <DeleteIcon onClick={e => handleRemove(e, item)} />
                <b>{item.id}</b> {item.nombre} <br /><span>(${funciones.formatPrice(item.valor_venta).format} COP)</span>
            </p>);
        });

        return (
            <div className="fila">
                <div className="items_agregados">
                    {items}
                </div>
            </div>
        );
    }

    const validaNombre = () => {
        alertaRemove(form_ref.current.nombre);

        if (nombre == null || nombre.length < 1) {
            alerta(form_ref.current.nombre, "¡Este campo es requerido!");
            form_ref.current.nombre.focus();
            window.scrollTo({
                top: 120,
                left: 0,
                behavior: 'smooth'
            });
            return false;
        } else {
            return true;
        }
    }

    const validaOperador = () => {
        alertaRemove(form_ref.current.operador);

        if (operador == null || operador.length < 1) {
            alerta(form_ref.current.operador, "¡Este campo es requerido!");
            form_ref.current.operador.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaCiudad = () => {
        alertaRemove(form_ref.current.ciudad);

        if (ciudad.length < 1 || parseInt(ciudad) < 1) {
            alerta(form_ref.current.ciudad, "¡Este campo es requerido!");
            form_ref.current.ciudad.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaPrecio = () => {
        alertaRemove(form_ref.current.precio);

        if (precio == null || precio.length < 1) {
            alerta(form_ref.current.precio, "¡Este campo es requerido!");
            form_ref.current.precio.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaCosto = () => {
        alertaRemove(form_ref.current.costo);

        if (costo == null || costo.length < 1) {
            alerta(form_ref.current.costo, "¡Este campo es requerido!");
            form_ref.current.costo.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaDescripcion = () => {
        alertaRemove(form_ref.current.descripcion);

        if (descripcion == null || descripcion.length < 1) {
            alerta(form_ref.current.descripcion, "¡Este campo es requerido!");
            form_ref.current.descripcion.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaProductos = () => {

        if (tipo == 1) {
            return true;
        } else {
            let _new = itemsProducts.filter((item) => {
                if (item.selected) {
                    return item;
                }
            });

            if (_new.length < 1) {
                alertDown(form_ref.current.productos, "¡Debes agregar al menos una actividad!");
                form_ref.current.productos.focus();
                return false;
            } else {
                return true;
            }
        }
    }

    const validaDocumentos = () => {

        let _new = itemsDocuments.filter((item) => {
            if (item.selected) {
                return item;
            }
        });

        if (_new.length < 1) {
            alertDown(form_ref.current.documentos, "¡Debes agregar al menos una plantilla!");
            form_ref.current.documentos.focus();
            return false;
        } else {
            return true;
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();

        setItemsDocuments([]);
        setItemsProducts([]);
        setShowSelectOpt(true);
    }

    const validaDatos = (e) => {
        e.preventDefault();
        alertaRemove(form_ref.current.archivo);

        if (validaNombre()
            && validaOperador()
            && validaCiudad()
            && validaPrecio()
            && validaCosto()
            && validaDescripcion()
            && validaProductos()
            && validaDocumentos()) {

            if (filesToUpload.length < 1) {
                alertDown(form_ref.current.archivo, "¡Debes agregar una imagen!");
            } else {
                setShowLoading(true);

                let file = filesToUpload[0];

                uploadFile(file).then(response => {
                    console.log("Imagen subida: ", response);
                    saveData(response.url);
                }).catch(error => {
                    alertDown(form_ref.current.archivo, error);
                    setShowLoading(false);
                });
            }
        }
    }

    const uploadFile = async (file) => {
        const imgName = file.name;

        return new Promise((resolve, reject) => {
            const storageRef = ref(getStorage(), "productos/" + imgName);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed", snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + Math.round(progress) + "% done");
            },
                error => {
                    console.log("error", error.message);
                    reject(error.message);
                },
                () => {
                    const downloadTask = getDownloadURL(uploadTask.snapshot.ref);

                    downloadTask.then(url => {
                        let _new_file = Object.assign(file, { url: url });

                        resolve(_new_file);
                    }).catch(error => {
                        console.log("Error: ", error.message);
                        reject(error.message);
                    });
                }
            );
        });
    }

    const saveData = async (url_img) => {
        let _productos = "";
        let _documentos = "";

        setShowLoading(true);

        if (tipo == 2) {
            itemsProducts.map((item) => {
                _productos += item.id + ",";
            });

            _productos = _productos.substring(0, _productos.length - 1);
        }

        itemsDocuments.map((item, index) => {
            _documentos += index + "|" + item.id + "^";
        });

        _documentos = _documentos.substring(0, _documentos.length - 1);


        let _data = await api.fetchJson({
            url: "save-producto",
            data: {
                id: 0,
                tipo: tipo,
                productos: _productos,
                documentos: _documentos,
                nombre: nombre,
                operador: operador,
                ciudad: ciudad,
                precio: funciones.formatPrice(precio).int,
                costo: funciones.formatPrice(costo).int,
                descripcion: descripcion,
                imagen: url_img
            }
        });

        if (parseInt(_data.response) === 1) {

            setTimeout(() => {
                setShowLoading(false);
                Alert("Producto agregado con éxito", "ok", () => {
                    navigate(-1);
                });
            }, 2000);


        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;
            setShowLoading(false);
            Alert(_msg, "warning");
        }
    }

    const handleChange = (e, opt) => {
        e.preventDefault();

        setTipo(opt);
        setShowSelectOpt(false);
    }

    const handleSetOperator = async (item) => {
        setOperador(item.id);

        let _data = await api.fetchJson({
            url: "get-destinos",
            data: {
                id: item.id
            }
        });

        if (_data.response == "1") {
            setCiudades(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

    }

    const SelectOpt = () => {

        return (
            <div className="select_opt">
                <h2 className="title_close">Opciones de creación <CloseIcon className="close" onClick={() => navigate(-1)} /> </h2>
                <p>Pulsa la opción acorde a tipo de producto que quieres crear</p>

                <div className="select_opt_item" onClick={e => handleChange(e, 1)}>
                    <OrderIcon />

                    <h3>Producto</h3>
                    <p>Crea un solo producto.</p>
                </div>

                <div className="select_opt_item" onClick={e => handleChange(e, 2)}>
                    <ComboIcon />

                    <h3>Combo</h3>
                    <p>Crea un combo con varios productos.</p>
                </div>

            </div>
        );
    }

    const getDocumentos = async () => {
        let _data = await api.fetchJson({
            url: "get-templates",
            data: filtroDocumentos
        });

        if (_data.response == "1") {
            let _lista = _data.data.map((item) => {
                item.selected = false;
                return item;
            });

            setListaDocumentos(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    const getDocumentosPorProducto = async (_lista) => {
        let _doc_temp = [];
        let _contador = 0;

        _lista.map(async (producto) => {
            let _data = await api.fetchJson({
                url: "get-templates",
                data: {
                    type: "por_producto",
                    id: producto.id
                }
            });

            if (_data.response == "1") {
                _doc_temp = _doc_temp.concat(_data.data);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;

                Alert(_msg, "warning");
            }

            _contador++;

            if (_contador === _lista.length) {

                let _new_list = listaDocumentos.map((item) => {
                    item.selected = false;
                    return item;
                });

                _new_list = _new_list.map((item) => {
                    _doc_temp.map((doc) => {
                        if (item.id == doc.plantilla) {
                            item.selected = true;
                        }
                    });

                    return item;
                });

                _doc_temp = _new_list.filter((item) => {
                    if (item.selected) {
                        return item;
                    }
                });

                setListaDocumentos(_new_list);
                setItemsDocuments(_doc_temp);
            }
        });
    }

    const getProductos = async () => {
        let _data = await api.fetchJson({
            url: "get-productos",
            data: filtroProducto
        });

        if (_data.response == "1") {
            let _lista = _data.data.map((item, index) => {
                item.selected = false;
                return item;
            });

            setListaProductos(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        getDocumentos();
    }, [filtroDocumentos]);

    React.useEffect(() => {
        getProductos();
    }, [filtroProducto]);

    React.useEffect(() => {
        if (tipo == 1) {
            setTitulo("Nuevo producto");
        } else {
            setTitulo("Nuevo combo");
            getProductos();
        }
    }, [tipo]);

    React.useEffect(() => {
        if (auth && auth.nivel >= 3) {
            navigate("/", { replace: true, state: { from: location } });
        }

        onAuthStateChanged(fauth, (user) => {
            if (user) {
                const uid = user.uid;

                console.log("Usuario logado\n\n uid", uid);
            } else {
                console.log("User is signed out");

                signInAnonymously(fauth)
                    .then(() => {
                        console.log("Signed in firebase");
                    })
                    .catch((error) => {
                        let _error = { code: error.code, message: error.message };
                        console.log("Error: ", _error);
                    });
            }
        });
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            {showSelectOpt ?
                <SelectOpt />
                :
                <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                    <h2>Nuevo {tipo == 1 ? "Producto" : "Combo"}</h2>

                    <div className="fila">

                        <div className="columna">
                            <label>Nombre</label>
                            <div className="input_icon">
                                <AccountOutlineIcon />
                                <input type="text" name="nombre" placeholder='Nombre del producto'
                                    maxLength={60}
                                    value={nombre} onChange={(e) => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
                            </div>
                        </div>

                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Operador</label>
                            <Input type="tel" name="operador" find="operador" placeholder='Operador'
                                onMatch={(item) => handleSetOperator(item)}>
                                <OperadorIcon />
                            </Input>
                        </div>

                        <div className="columna">
                            <label>Ciudad</label>
                            <div className="input_icon">
                                <CityIcon />
                                <select name="ciudad" value={ciudad} onChange={(e) => { alertaRemove(form_ref.current.ciudad); setCiudad(e.target.value) }}>
                                    <option value="0">Selecciona...</option>
                                    {ciudades.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.nombre}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Valor de venta</label>
                            <div className="input_icon">
                                <CashMultipleIcon />
                                <input type="text" name="precio" placeholder='Valor de venta'
                                    value={funciones.formatPrice(precio).format} onChange={(e) => { alertaRemove(form_ref.current.precio); setPrecio(funciones.formatPrice(e.target.value).format) }} />
                            </div>
                        </div>

                        <div className="columna">
                            <label>Costo</label>
                            <div className="input_icon">
                                <CurrencyUsdIcon />
                                <input type="text" name="costo" placeholder='Costo'
                                    value={funciones.formatPrice(costo).format} onChange={(e) => { alertaRemove(form_ref.current.costo); setCosto(funciones.formatPrice(e.target.value).format) }} />
                            </div>
                        </div>

                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Descripción</label>
                            <div className="input_icon">
                                <textarea name="descripcion" rows="6" cols="20" placeholder={tipo == 1 ? "Descripción del producto" : "Descripción del combo"}
                                    value={descripcion} onChange={(e) => { alertaRemove(form_ref.current.descripcion); setDescripcion(e.target.value) }} />
                            </div>
                        </div>

                        <div className="columna">
                            <label>Adjuntar archivo</label>
                            <DropZone onChange={setFilesToUpload} files={filesToUpload} />
                            <div className="input_icon">
                                <input type="hidden" name="archivo" />
                            </div>
                        </div>

                    </div>

                    <div className="fila">
                        <ItemsDocumentos />

                        {tipo == 2 && <>
                            <hr className="linea_vertical" />
                            <ItemsProductos />
                        </>}
                    </div>

                    <div className="fila" style={{ justifyContent: "space-between" }}>

                        <button type="button" className="btn_icon" onClick={handleShowDocuments}>
                            <DocumentIcon />Agregar plantilla
                            <input type="hidden" name="documentos" />
                        </button>

                        {tipo == 2 &&
                            <button type="button" className="btn_icon" onClick={handleShowList}>
                                <Iconos.PackAdd />Agregar actividad
                                <input type="hidden" name="productos" />
                            </button>
                        }
                    </div>

                    <div className="botones">
                        <button type="button" onClick={handleCancel}>Cancelar</button>
                        <button type="button" onClick={validaDatos}>Aceptar</button>
                    </div>

                </form>
            }

            {showListDocuments && <ListaDocumentos />}
            {showListProductos && <ListaProductos />}
            {showLoading && <Loading />}
        </div >
    );
}

export default AgregarProducto;
