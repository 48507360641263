import * as React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as Rdom from "react-router-dom";
import { Alert } from "shared/Alert";
import api from "services/api";
import funciones from 'services/funciones';

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
  var d = new Date(+this);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const ChartAreaVentas = (params) => {
  const navigate = Rdom.useNavigate();
  const location = Rdom.useLocation();
  const [ordenes, setOrdenes] = React.useState([]);

  const [media_movil] = React.useState(window.matchMedia('(max-width: 576px)').matches);
  const days = media_movil ? ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'] : ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

  const week = [
    { name: days[1], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: days[2], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: days[3], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: days[4], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: days[5], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: days[6], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: days[0], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 }
  ];
  const month = [
    { name: months[0], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[1], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[2], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[3], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[4], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[5], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[6], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[7], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[8], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[9], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[10], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
    { name: months[11], cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
  ];

  const [isMontly, setIsMontly] = React.useState(false);
  const [dataWeekly, setDataWeekly] = React.useState(week);
  const [dataMontly, setDataMontly] = React.useState(month);
  const { user } = params;
  const isMounted = React.useRef(true);


  const handleChange = () => {
    setIsMontly(!isMontly);
  }

  const buildWeekly = () => {
    if (ordenes && ordenes.length > 0) {
      // let current_week = new Date("2021-11-17 09:00:00").getWeekNumber();
      let current_week = new Date().getWeekNumber();
      let _date;

      /** 
       * Estados de las ordenes
       * 
       * 0: Pendiente
       * 1: Cotización
       * 2: Aprobado
       * 3: Pagado
       * 4: Ejecutado
       * 5: Pagado pendiente
       * 6: Eliminado
      */

      /** 
       * Estado guardado de los pagos
       * 
       * 0: Pendiente
       * 1: Cotización
       * 2: Abono
       * 3: Pagado
       * 4: Ejecutado
       * 5: 
       * 6: Eliminado
      */
      let _week = {
        [days[1]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [days[2]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [days[3]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [days[4]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [days[5]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [days[6]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [days[0]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 }
      };

      ordenes.map(item => {
        _date = new Date(item.fecha * 1000);
        _date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());

        if (current_week == _date.getWeekNumber()) {

          switch (parseInt(item.estado_guardado)) {
            case 1:
              _week[days[_date.getDay()]].cot_count++;
              _week[days[_date.getDay()]].cot += parseFloat(item.valor_total);
              break;
            case 2:
              if (item.valor > 0) {
                _week[days[_date.getDay()]].otr_count++;
                _week[days[_date.getDay()]].otr += parseInt(item.valor);
              }
              break;
            case 4:
              _week[days[_date.getDay()]].exec++;
              break;
          }
        }
      });

      setDataWeekly([
        { name: days[1], cot: _week[days[1]].cot, cot_count: _week[days[1]].cot_count, otr: _week[days[1]].otr, otr_count: _week[days[1]].otr_count, exec: _week[days[1]].exec },
        { name: days[2], cot: _week[days[2]].cot, cot_count: _week[days[2]].cot_count, otr: _week[days[2]].otr, otr_count: _week[days[2]].otr_count, exec: _week[days[2]].exec },
        { name: days[3], cot: _week[days[3]].cot, cot_count: _week[days[3]].cot_count, otr: _week[days[3]].otr, otr_count: _week[days[3]].otr_count, exec: _week[days[3]].exec },
        { name: days[4], cot: _week[days[4]].cot, cot_count: _week[days[4]].cot_count, otr: _week[days[4]].otr, otr_count: _week[days[4]].otr_count, exec: _week[days[4]].exec },
        { name: days[5], cot: _week[days[5]].cot, cot_count: _week[days[5]].cot_count, otr: _week[days[5]].otr, otr_count: _week[days[5]].otr_count, exec: _week[days[5]].exec },
        { name: days[6], cot: _week[days[6]].cot, cot_count: _week[days[6]].cot_count, otr: _week[days[6]].otr, otr_count: _week[days[6]].otr_count, exec: _week[days[6]].exec },
        { name: days[0], cot: _week[days[0]].cot, cot_count: _week[days[0]].cot_count, otr: _week[days[0]].otr, otr_count: _week[days[0]].otr_count, exec: _week[days[0]].exec }
      ]);
    }
  }

  const buildMontly = () => {
    if (ordenes && ordenes.length > 0) {
      let _date;

      let _month = {
        [months[0]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[1]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[2]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[3]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[4]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[5]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[6]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[7]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[8]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[9]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[10]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
        [months[11]]: { cot: 0, cot_count: 0, otr: 0, otr_count: 0, exec: 0 },
      };

      ordenes.map(item => {
        _date = new Date(item.fecha * 1000);
        _date = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate());

        switch (parseInt(item.estado_guardado)) {
          case 1:
            _month[months[_date.getMonth()]].cot_count++;
            _month[months[_date.getMonth()]].cot += parseFloat(item.valor_total);
            break;
          case 2:
            if (item.valor > 0) {
              _month[months[_date.getMonth()]].otr_count++;
              _month[months[_date.getMonth()]].otr += parseInt(item.valor);
            }
            break;
          case 4:
            _month[months[_date.getMonth()]].exec++;
            break;
        }

      });

      setDataMontly([
        { name: months[0], cot: _month[months[0]].cot, cot_count: _month[months[0]].cot_count, otr: _month[months[0]].otr, otr_count: _month[months[0]].otr_count, exec: _month[months[0]].exec },
        { name: months[1], cot: _month[months[1]].cot, cot_count: _month[months[1]].cot_count, otr: _month[months[1]].otr, otr_count: _month[months[1]].otr_count, exec: _month[months[1]].exec },
        { name: months[2], cot: _month[months[2]].cot, cot_count: _month[months[2]].cot_count, otr: _month[months[2]].otr, otr_count: _month[months[2]].otr_count, exec: _month[months[2]].exec },
        { name: months[3], cot: _month[months[3]].cot, cot_count: _month[months[3]].cot_count, otr: _month[months[3]].otr, otr_count: _month[months[3]].otr_count, exec: _month[months[3]].exec },
        { name: months[4], cot: _month[months[4]].cot, cot_count: _month[months[4]].cot_count, otr: _month[months[4]].otr, otr_count: _month[months[4]].otr_count, exec: _month[months[4]].exec },
        { name: months[5], cot: _month[months[5]].cot, cot_count: _month[months[5]].cot_count, otr: _month[months[5]].otr, otr_count: _month[months[5]].otr_count, exec: _month[months[5]].exec },
        { name: months[6], cot: _month[months[6]].cot, cot_count: _month[months[6]].cot_count, otr: _month[months[6]].otr, otr_count: _month[months[6]].otr_count, exec: _month[months[6]].exec },
        { name: months[7], cot: _month[months[7]].cot, cot_count: _month[months[7]].cot_count, otr: _month[months[7]].otr, otr_count: _month[months[7]].otr_count, exec: _month[months[7]].exec },
        { name: months[8], cot: _month[months[8]].cot, cot_count: _month[months[8]].cot_count, otr: _month[months[8]].otr, otr_count: _month[months[8]].otr_count, exec: _month[months[8]].exec },
        { name: months[9], cot: _month[months[9]].cot, cot_count: _month[months[9]].cot_count, otr: _month[months[9]].otr, otr_count: _month[months[9]].otr_count, exec: _month[months[9]].exec },
        { name: months[10], cot: _month[months[10]].cot, cot_count: _month[months[10]].cot_count, otr: _month[months[10]].otr, otr_count: _month[months[10]].otr_count, exec: _month[months[10]].exec },
        { name: months[11], cot: _month[months[11]].cot, cot_count: _month[months[11]].cot_count, otr: _month[months[11]].otr, otr_count: _month[months[11]].otr_count, exec: _month[months[11]].exec },
      ]);
    }
  }

  const getData = async () => {
    let _user = user.id == 0 ? {
      type: "grafico_personal"
    } : {
      type: "grafico_vendedor",
      id_user: user.id
    }

    let _data = await api.fetchJson({
      url: "get-pagos",
      data: _user
    });

    if (isMounted.current) {
      if (_data.response == "1") {
        let _lista = _data.data.filter(i => i.estado_orden != 5);
        setOrdenes(_lista);
      } else if (_data.response === -2) {
        Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
      } else {
        let _msg = _data.data ? _data.data.msg : _data.msg;

        Alert(_msg, "warning");
      }
    }
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {

      switch (label) {
        case "Ene":
          label = "Enero";
          break;
        case "Feb":
          label = "Febrero";
          break;
        case "Mar":
          label = "Marzo";
          break;
        case "Abr":
          label = "Abril";
          break;
        case "May":
          label = "Mayo";
          break;
        case "Jun":
          label = "Junio";
          break;
        case "Jul":
          label = "Julio";
          break;
        case "Ago":
          label = "Agosto";
          break;
        case "Sep":
          label = "Septiembre";
          break;
        case "Oct":
          label = "Octubre";
          break;
        case "Nov":
          label = "Noviembre";
          break;
        case "Dic":
          label = "Diciembre";
          break;
      }

      return (
        <div className="custom_tooltip">
          <p className="tooltip_title">{label}</p>
          <p className="tooltip_label" style={{ color: payload[0].color }}>{`${payload[0].name} : ${payload[0].payload.cot < 1 ? payload[0].payload.cot : "(" + payload[0].payload.cot_count + ") $" + funciones.formatPrice(payload[0].payload.cot).format}`}</p>
          <p className="tooltip_label" style={{ color: payload[1].color }}>{`${payload[1].name} : ${payload[1].payload.otr < 1 ? payload[1].payload.otr : "(" + payload[1].payload.otr_count + ") $" + funciones.formatPrice(payload[1].payload.otr).format}`}</p>
        </div>
      );
    }

    return null;
  }

  React.useEffect(() => {
    buildWeekly();
    buildMontly();
  }, [ordenes]);

  React.useEffect(() => {
    isMounted.current = true;
    getData();

    return () => { isMounted.current = false; };
  }, [user]);

  return (
    <div className="grafico_ventas">
      {user.id != 0 && <h2>Ventas de <i>{user.nombre} {user.apellido}</i></h2>}

      <ResponsiveContainer height={250} className="dashboard__area">
        {isMontly ?
          <AreaChart data={dataMontly} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="name" tickLine={false} padding={{ bottom: 18 }} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <CartesianGrid />

            <Area type="monotone" fill="none" stroke="#f53c0d" dataKey="cot" name="Cotizaciones" />
            <Area type="monotone" fill="none" stroke="#369400" dataKey="otr" name="Abonos" />

          </AreaChart>
          :
          <AreaChart data={dataWeekly} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="name" tickLine={false} padding={{ bottom: 18 }} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <CartesianGrid />

            <Area type="monotone" fill="none" stroke="#f53c0d" dataKey="cot" name="Cotizaciones" />
            <Area type="monotone" fill="none" stroke="#369400" dataKey="otr" name="Abonos" />

          </AreaChart>
        }
      </ResponsiveContainer>
      <label htmlFor="sales" className="toggle-btn">
        <input className="toggle-btn__input" type="checkbox" name="sales" id="sales" value={isMontly} onChange={handleChange} />
        <span className="toggle-text-left">Semanal</span>
        <span className="toggle-btn__input-label" />
        <span className="toggle-text-right">Anual</span>
      </label>
    </div>
  );
};

export default ChartAreaVentas;
