import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SwapVerticalIcon from 'mdi-react/SwapVerticalIcon';
import ArrowRigth from "mdi-react/ChevronRightIcon";
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";
import Cell from "shared/Cell";
import api from "services/api";


const Destinos = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = useNavigate();
    const location = useLocation();
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [dataList, setDataList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const Row = (params) => {

        return (
            <MenuRow {...params}
                current={selected.id}
                onSel={setSelected}
            // reload={getList}
            >

                <td>{params.data.nit}</td>
                <td>{params.data.nombre}</td>
                <td>{params.data.ciudad}</td>
                <td>{params.data.correo}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} resalta={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return <div className="cont_tabla">
                <table>
                    <thead>
                        <tr>
                            <Cell><SwapVerticalIcon />Nit</Cell>
                            <Cell><SwapVerticalIcon />Nombre</Cell>
                            <Cell><SwapVerticalIcon />Ciudad</Cell>
                            <Cell><SwapVerticalIcon />Correo</Cell>
                        </tr>
                    </thead>

                    <tbody>
                        {_data}
                    </tbody>
                </table>
            </div>;
        }
    }

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-destinos",
            data: {
                id: window.atob(auth.auth)
            }
        });

        if (_data.response == "1") {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
    }

    React.useEffect(() => {
        setTitulo("Todos los destinos");
        getInfo();
    }, []);

    return (
        <div className="cont_principal deg_top">

            <div className="cont_titulo only_searsh">
                <h2>Destinos</h2>
                <Link to="/agregar-destino">Crear nuevo <ArrowRigth /></Link>

                <div className="search_bar">
                    <input type="text" placeholder="Buscar" />
                    <MagnifyIcon />
                </div>
            </div>

            <AllRow />

        </div>
    );

}

export default Destinos;