import * as React from "react";
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import AccountCircleOutlineIcon from "mdi-react/AccountCircleOutlineIcon";
import PhoneInTalkOutlineIcon from "mdi-react/PhoneInTalkOutlineIcon";
import NumDocIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import TipoDocIcon from "mdi-react/CardAccountMailOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PerfilIcon from "mdi-react/AccountSupervisorIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import KeyOutlineIcon from "mdi-react/KeyOutlineIcon";
import OperadorIcon from "mdi-react/KayakingIcon";
import * as Rdom from "react-router-dom";
import { Input } from "shared/Input";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import CryptoJS from "crypto-js";
import api from "services/api";


/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const EditarPerfil = () => {
    const { auth, setAuth } = useAuth();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const data = location.state?.data;
    const [isPasswordShown, setIsPasswordShown] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [confirmPass, setConfirmPass] = React.useState("");
    const [newPass, setNewPass] = React.useState("");
    const [operador, setOperador] = React.useState(data.operador || "");
    const [nombre, setNombre] = React.useState(data.nombre || "");
    const [apellido, setApellido] = React.useState(data.apellido || "");
    const [telefono, setTelefono] = React.useState(data.telefono || "");
    const [correo, setCorreo] = React.useState(data.correo || "");
    const [tipoDoc, setTipoDoc] = React.useState(data.tipo_doc || 0);
    const [numDoc, setNumDoc] = React.useState(data.num_doc || "");
    const [perfil, setPerfil] = React.useState(data.nivel || 0);
    const [cambiaPass, setCambiaPass] = React.useState(false);
    const form_ref = React.useRef(null);


    const handleShowPassword = () => {
        setIsPasswordShown(!isPasswordShown);
    }

    const validaCampos = (e) => {
        e.preventDefault();

        alertaRemove(form_ref.current.nombre);
        alertaRemove(form_ref.current.apellido);
        alertaRemove(form_ref.current.telefono);
        alertaRemove(form_ref.current.correo);
        auth.nivel <= 3 && alertaRemove(form_ref.current.perfil);
        auth.nivel <= 3 && alertaRemove(form_ref.current.operador);
        alertaRemove(form_ref.current.newPass);

        if (nombre.trim() === "") {
            alerta(form_ref.current.nombre, "¡Este campo es requerido!");
            form_ref.current.nombre.focus();
            return false;
        }

        if (apellido.trim() === "") {
            alerta(form_ref.current.apellido, "¡Este campo es requerido!");
            form_ref.current.apellido.focus();
            return false;
        }

        if (auth.nivel <= 3) {
            if (perfil === 0) {
                alerta(form_ref.current.perfil, "¡Este campo es requerido!");
                form_ref.current.perfil.focus();
                return false;
            }
        }

        if (telefono.trim() === "") {
            alerta(form_ref.current.telefono, "¡Este campo es requerido!");
            form_ref.current.telefono.focus();
            return false;
        }

        if (auth.nivel <= 3) {
            if (operador.trim() === "") {
                alerta(form_ref.current.operador, "¡Este campo es requerido!");
                form_ref.current.operador.focus();
                return false;
            }
        }

        if (correo.trim() === "") {
            alerta(form_ref.current.correo, "¡Este campo es requerido!");
            form_ref.current.correo.focus();
            return false;
        }

        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(correo).toLowerCase())) {
            alerta(form_ref.current.correo, "¡El formato del correo es incorrecto!");
            form_ref.current.correo.focus();
            return false;
        }

        if (cambiaPass) {

            if (newPass.trim() === "") {
                alerta(form_ref.current.new_password, "¡Este campo es requerido!");
                form_ref.current.new_password.focus();
                return false;
            }

            if (confirmPass.trim() === "") {
                alerta(form_ref.current.confirm_password, "¡Este campo es requerido!");
                form_ref.current.confirm_password.focus();
                return false;
            }

            if (newPass !== confirmPass) {
                alerta(form_ref.current.confirm_password, "¡Las contraseñas no coinciden!");
                form_ref.current.confirm_password.focus();
                return false;
            }
        }

        saveData();
    }

    const saveData = async () => {

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-user",
            data: {
                id: data.id,
                nombre: nombre,
                apellido: apellido,
                tipo_doc: tipoDoc,
                num_doc: numDoc,
                telefono: telefono,
                correo: correo,
                perfil: perfil,
                operador: operador,
                newPass: cambiaPass ? CryptoJS.SHA256(newPass).toString() : "",
            }
        });

        if (parseInt(_data.response) === 1) {

            if(parseInt(data.id) === parseInt(window.atob(auth.auth))) {
                let _user = {
                    auth: window.btoa(data.id),
                    nivel: perfil,
                    correo: correo,
                    telefono: telefono,
                    tipo_doc: tipoDoc,
                    num_doc: numDoc,
                    nombre: nombre,
                    apellido: apellido,
                    nombre_completo: nombre + " " + apellido,
                  };

                  setAuth(_user);
            }

            setTimeout(() => {
                setShowLoading(false);
                Alert("La información fue modificada correctamente", "ok", () => {
                    navigate(-1);
                });
            }, 2000);


        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;
            setShowLoading(false);
            Alert(_msg, "warning");
        }

    }

    React.useEffect(() => {
        document.title = parseInt(data.id) === parseInt(window.atob(auth.auth)) ? "Editar mi perfil" : "Editar Usuario";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="cont_principal deg_top cont_forms">

            {(parseInt(data.id) === parseInt(window.atob(auth.auth)) && auth.nivel <= 3) ?
                <h4 className="titulo_msg" style={{ marginTop: 18 }}>No puedes editar esta información.</h4>
                :
                <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                    <h2>Editar {parseInt(data.id) === parseInt(window.atob(auth.auth)) ? "mi perfil" : "Usuario"}</h2>

                    <div className="fila">

                        <div className="columna" style={{ alignItems: "center" }}>
                            <div className="foto_perfil"> <AccountCircleOutlineIcon /></div>
                        </div>

                        <div className="columna">
                            <div className="columna">
                                <label>Nombre</label>
                                <div className="input_icon">
                                    <AccountOutlineIcon />
                                    <input type="text" name="nombre" placeholder="Ingresa el nombre"
                                        value={nombre} onChange={e => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
                                </div>
                            </div>

                            <div className="columna not_i">
                                <label>Apellido</label>
                                <div className="input_icon">
                                    <CardAccountDetailsOutlineIcon />
                                    <input type="text" name="apellido" placeholder="Ingresa el apellido"
                                        value={apellido} onChange={e => { alertaRemove(form_ref.current.apellido); setApellido(e.target.value) }} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Tipo documento</label>
                            <div className="input_icon">
                                <TipoDocIcon />
                                <select name="tipoDoc" value={tipoDoc} onChange={(e) => { alertaRemove(form_ref.current.tipoDoc); setTipoDoc(e.target.value) }}>
                                    <option value="0">Selecciona...</option>
                                    <option value="1">Cédula de Ciudadanía</option>
                                    <option value="2">Cédula de Extranjería</option>
                                    <option value="3">Pasaporte</option>
                                </select>
                            </div>
                        </div>

                        <div className="columna">
                            <label>Nº documento</label>
                            <div className="input_icon">
                                <NumDocIcon />
                                <input type="number" name="numDoc" placeholder='Número de documento'
                                    value={numDoc} onChange={(e) => { alertaRemove(form_ref.current.numDoc); setNumDoc(e.target.value) }} />
                            </div>
                        </div>

                    </div>

                    {auth.nivel > 3 ?

                        <div className="fila">

                            <div className="columna">
                                <label>Teléfono</label>
                                <div className="input_icon">
                                    <PhoneInTalkOutlineIcon />
                                    <input type="text" name="telefono" placeholder="Ingresa el teléfono"
                                        value={telefono} onChange={e => { alertaRemove(form_ref.current.telefono); setTelefono(e.target.value) }} />
                                </div>
                            </div>

                            <div className="columna">
                                <label>Correo</label>
                                <div className="input_icon">
                                    <EmailOutlineIcon />
                                    <input type="text" name="correo" placeholder="Ingresa el correo"
                                        value={correo} onChange={e => { alertaRemove(form_ref.current.correo); setCorreo(e.target.value) }} />
                                </div>
                            </div>

                        </div>
                        :
                        <>
                            <div className="fila">

                                <div className="columna">
                                    <label>Perfil</label>
                                    <div className="input_icon">
                                        <PerfilIcon />
                                        <select name="perfil" value={perfil} onChange={e => { alertaRemove(form_ref.current.perfil); setPerfil(parseInt(e.target.value)) }}>
                                            <option value="0">Selecciona...</option>
                                            <option value="2">Administrador</option>
                                            <option value="3">Agente</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="columna">
                                    <label>Teléfono</label>
                                    <div className="input_icon">
                                        <PhoneInTalkOutlineIcon />
                                        <input type="text" name="telefono" placeholder="Ingresa el teléfono"
                                            value={telefono} onChange={e => { alertaRemove(form_ref.current.telefono); setTelefono(e.target.value) }} />
                                    </div>
                                </div>

                            </div>

                            <div className="fila">

                                <div className="columna">
                                    <label>Operador</label>
                                    <Input type="text" name="operador" find="operador" placeholder='Operador'
                                        first={data.nombre_operador || ""}
                                        onMatch={(item) => setOperador(item.id)}>
                                        <OperadorIcon />
                                    </Input>
                                </div>

                                <div className="columna">
                                    <label>Correo</label>
                                    <div className="input_icon">
                                        <EmailOutlineIcon />
                                        <input type="text" name="correo" placeholder="Ingresa el correo"
                                            value={correo} onChange={e => { alertaRemove(form_ref.current.correo); setCorreo(e.target.value) }} />
                                    </div>
                                </div>

                            </div>
                        </>
                    }

                    {parseInt(data.id) !== parseInt(window.atob(auth.auth)) && <label htmlFor="cambia_pass" className="label_checkbox">
                        <input type="checkbox" name="cambia_pass" id="cambia_pass"
                            value={cambiaPass}
                            onChange={e => setCambiaPass(e.target.checked)} />
                        <span>Cambiar contraseña</span>
                    </label>}

                    {cambiaPass && <div className="cambio_contra">

                        <label>Contraseña nueva</label>
                        <div className="contrasenia">
                            <KeyOutlineIcon />
                            <input type={isPasswordShown ? 'text' : 'password'}
                                name="new_password"
                                placeholder='Contraseña Nueva'
                                value={newPass}
                                onChange={({ target: { value } }) => { alertaRemove(form_ref.current.new_password); setNewPass(value) }} />
                            {isPasswordShown ? <EyeOffOutlineIcon className="eye_icon" onClick={() => handleShowPassword()} /> : <EyeOutlineIcon className="eye_icon" onClick={() => handleShowPassword()} />}
                        </div>

                        <label>Confirma contraseña</label>
                        <div className="contrasenia">
                            <KeyOutlineIcon />
                            <input type={isPasswordShown ? 'text' : 'password'}
                                name="confirm_password"
                                placeholder='Confirma Contraseña'
                                value={confirmPass}
                                onChange={({ target: { value } }) => { alertaRemove(form_ref.current.confirm_password); setConfirmPass(value) }} />

                        </div>

                    </div>}

                    <div className="botones">
                        <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
                        <button type="button" onClick={validaCampos}>Guardar</button>
                    </div>
                </form>
            }

            {showLoading && <Loading />}
        </div>
    );
}

export default EditarPerfil;