import * as React from 'react';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DetalleMetodoPago = () => {
  const { setTitulo } = useTitle();
  const navigate = Rdom.useNavigate();
  const location = Rdom.useLocation();
  const data = location.state.data;
  const nombre = data.nombre || "";
  const cuenta = data.numero_cta || "";
  const descripcion = data.descripcion || "";
  const form_ref = React.useRef(null);

  const goEdit = (e) => {
    e.preventDefault()

    navigate("/editar-metodo-pago", { state: { data: data, from: location } });
  }

  const handleClose = (e) => {
    e.preventDefault()

    if (location.state?.from?.pathname == "/metodos-pago") {
      navigate(-1);
    } else {
      navigate("/metodos-pago", { replace: true });
    }
  }

  React.useEffect(() => {
    setTitulo("Detalle método de pago");
  }, []);

  return (
    <div className="cont_principal deg_top cont_forms">

      <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
        <h2 className="title_close">Detalle método de pago <CloseIcon className="close" onClick={handleClose} /> </h2>

        <div className="fila">

          <div className="columna">
            <label>Nombre</label>
            <div className="input_icon">
              <AccountOutlineIcon />
              <p className="rerumen">{nombre}</p>
            </div>
          </div>

          <div className="columna">
            <label>Nº Cuenta</label>
            <div className="input_icon">
              <CardAccountDetailsOutlineIcon />
              <p className="rerumen">{cuenta}</p>
            </div>
          </div>

        </div>

        <div className="columna">
          <label>Descripción</label>
          <div className="input_icon">
            <textarea name="descripcion" cols="30" rows="5" placeholder='Descripción'
              style={{ background: "transparent" }}
              readOnly={true}
              value={descripcion}></textarea>
          </div>
        </div>

        <button type="button" className="btn_link" onClick={goEdit}>Editar <EditIcon /></button>
      </form >

    </div >
  );
}

export default DetalleMetodoPago;
