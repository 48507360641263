import * as React from "react";
import * as Rdom from "react-router-dom";
import CloseIconR from "mdi-react/CloseCircleIcon";
import { Alert, alertaRemove } from "./Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Suggesting = React.forwardRef((params, ref) => {
    const { list, onSelect, currentIndex, onTop = false } = params;
    let _options = [];

    list.map((item, index) => {
        _options.push(<li key={index} className={currentIndex == index ? "_selected" : ""} onClick={() => onSelect({ ...item, index: index })}><a>{item.text}</a></li>);
    });

    return (
        <ul className={onTop ? "_float_list _on_top" : "_float_list"} id="float_list" ref={ref}>
            {_options.length < 1 ?
                <li key={0}><a>Sin resultados.</a></li>
                :
                _options
            }
        </ul>
    );
});

/**
 * 
 * @param {string} type el tipo de input por defecto es `text`
 * @param {string} name el nombre del input
 * @param {string} placeholder el placeholder del input
 * @param {string} value el valor del input
 * @param {function} onMatch el callback cuando se encuentra una coincidencia
 * @returns {HTMLInputElement} el input con autosuggest
 */
export function Input(params) {
    const { first = "", type = "text", name = "search", placeholder = "", find = "producto", onMatch } = params;
    const [list, setLista] = React.useState([{ val: 0, text: "Buscando..." }]);
    const [suggestionIndex, setSuggestionIndex] = React.useState(-1);
    const [criterio, setCriterio] = React.useState("");
    const [show, setShow] = React.useState(false);
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();

    const ref_lista = React.useRef();
    const ref_input = React.useRef();


    const handleSelect = (data) => {

        setCriterio(data.text);
        setSuggestionIndex(data.index);
        setShow(false);
        onMatch(data);
    }

    const handlerCriteio = (e, value) => {
        e.preventDefault();
        setCriterio(value);
        alertaRemove(ref_input.current);

        if (typeof params.onTyping === "function") {
            params.onTyping(value || "");
        }

        if (value.length > 0) {
            setShow(true);
            search(value);
        } else {
            setShow(false);
        }
    }

    const handlerFocus = (_val) => {

        if (_val && criterio > 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    const handlerBlur = (e) => {
        //if (e.relatedTarget !== ref_lista.current) {
        setTimeout(() => {
            setShow(false);
        }, 200);

    }

    const handleKey = (e) => {
        if (ref_lista.current) {
            let _caja = ref_lista.current.offsetHeight;
            let _scroll = ref_lista.current.scrollHeight;
            //let _scrollTop = ref_lista.current.scrollTop;
            let _length = list.length;
            let _trozo = _scroll / _length;

            if (e.key === "Enter") {
                setCriterio(list[suggestionIndex].text);
                setShow(false);
                onMatch(list[suggestionIndex]);
            }

            if (e.key === "ArrowUp") {
                setSuggestionIndex(val => {
                    let _unit = val < 1 ? (_length - 1) : val - 1;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {
                        let _desplaza = _uni_height > 0 ? _uni_height : 0;

                        ref_lista.current.scrollTo({
                            top: _desplaza,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }

            if (e.key === "ArrowDown") {
                setSuggestionIndex(val => {
                    let _unit = val < (_length - 1) ? val + 1 : 0;
                    let _uni_height = _trozo * _unit;

                    if (_scroll > _caja) {

                        ref_lista.current.scrollTo({
                            top: _uni_height,
                            left: 0
                        });
                    }

                    return _unit;
                });
            }
        }
    }

    const search = async (_val) => {

        let _data = await api.fetchJson({
            url: "search/" + find,
            data: { criterio: _val }
        });


        if (_data.response == 1) {
            let _new_list = [];
            _data.data.map(item => {
                _new_list.push({ ...item, val: item.id });
            });

            setLista(_new_list);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    return (
        <div className="input_icon" onBlur={handlerBlur}>
            {params.children}
            <input
                ref={ref_input}
                name={name}
                type={type}
                value={criterio.length > 0 ? criterio : first}
                autoComplete="off"
                placeholder={placeholder}
                onFocus={() => handlerFocus(true)}
                onKeyUp={handleKey}
                style={{ paddingRight: "28px" }}
                onChange={(e) => handlerCriteio(e, e.target.value)} />
            {criterio.length >= 1 && <CloseIconR className="btn_close" onClick={(e) => handlerCriteio(e, "")} />}

            {show && <Suggesting
                list={list}
                ref={ref_lista}
                currentIndex={suggestionIndex}
                onSelect={handleSelect} />}
        </div>
    );
}