import * as React from "react";
import CardAccountDetailsOutlineIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import AccountCircleOutlineIcon from "mdi-react/AccountCircleOutlineIcon";
import PhoneInTalkOutlineIcon from "mdi-react/PhoneInTalkOutlineIcon";
import NumDocIcon from "mdi-react/CardAccountDetailsOutlineIcon";
import TipoDocIcon from "mdi-react/CardAccountMailOutlineIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import PerfilIcon from "mdi-react/AccountSupervisorIcon";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import OperadorIcon from "mdi-react/KayakingIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import useAuth from "hooks/useAuth";


/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const Detalle = () => {
    const { auth } = useAuth();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const data = location.state?.data;
    const nombre = data.nombre || "";
    const apellido = data.apellido || "";
    const telefono = data.telefono || "";
    const correo = data.correo || "";
    const tipoDoc = data.tipo_doc || 0;
    const numDoc = data.num_doc || "";
    const perfil = data.nivel || 0;
    const form_ref = React.useRef(null);

    const getPerfil = (_int) => {
        switch (parseInt(_int)) {
            case 1:
                return "Super Administrador";
            case 2:
                return "Administrador";
            case 3:
                return "Agente";
            case 4:
                return "Cliente";
            default:
                return "...";
        }
    }

    const goEdit = (e) => {
        e.preventDefault()

        navigate("/editar-usuario", { state: { data: data } });
    }

    React.useEffect(() => {
        document.title = parseInt(data.id) === parseInt(window.atob(auth.auth)) ? "Mi perfil" : "Detalle de usuario";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="cont_principal deg_top cont_forms">

            <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                <h2 className="title_close"><CloseIcon className="close" onClick={() => navigate(-1)} /> </h2>

                <div className="fila">

                    <div className="columna" style={{ alignItems: "center" }}>
                        <div className="foto_perfil"> <AccountCircleOutlineIcon /></div>
                    </div>

                    <div className="columna">
                        <div className="columna">
                            <label>Nombre</label>
                            <div className="input_icon">
                                <AccountOutlineIcon />
                                <p className="rerumen">{nombre}</p>
                            </div>
                        </div>

                        <div className="columna not_i">
                            <label>Apellido</label>
                            <div className="input_icon">
                                <CardAccountDetailsOutlineIcon />
                                <p className="rerumen">{apellido}</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Tipo documento</label>
                        <div className="input_icon">
                            <TipoDocIcon />
                            <p className="rerumen">{funciones.tipoDoc(tipoDoc)}</p>
                        </div>
                    </div>

                    <div className="columna">
                        <label>Nº documento</label>
                        <div className="input_icon">
                            <NumDocIcon />
                            <p className="rerumen">{numDoc}</p>
                        </div>
                    </div>

                </div>

                {auth.nivel > 3 ?
                    <div className="fila">

                        <div className="columna">
                            <label>Teléfono</label>
                            <div className="input_icon">
                                <PhoneInTalkOutlineIcon />
                                <p className="rerumen">{telefono}</p>
                            </div>
                        </div>

                        <div className="columna">
                            <label>Correo</label>
                            <div className="input_icon">
                                <EmailOutlineIcon />
                                <p className="rerumen">{correo}</p>
                            </div>
                        </div>

                    </div>
                    :
                    <>
                        <div className="fila">

                            <div className="columna">
                                <label>Perfil</label>
                                <div className="input_icon">
                                    <PerfilIcon />
                                    <p className="rerumen">{getPerfil(perfil)}</p>
                                </div>
                            </div>

                            <div className="columna">
                                <label>Teléfono</label>
                                <div className="input_icon">
                                    <PhoneInTalkOutlineIcon />
                                    <p className="rerumen">{telefono}</p>
                                </div>
                            </div>

                        </div>

                        <div className="fila">

                            <div className="columna">

                                <label>Operador</label>
                                <div className="input_icon">
                                    <OperadorIcon />
                                    <p className="rerumen">{data.nombre_operador || "..."}</p>
                                </div>

                            </div>

                            <div className="columna">
                                <label>Correo</label>
                                <div className="input_icon">
                                    <EmailOutlineIcon />
                                    <p className="rerumen">{correo}</p>
                                </div>
                            </div>

                        </div>
                    </>
                }

                {(auth && auth.nivel < 2 || parseInt(data.id) === parseInt(window.atob(auth.auth)) && auth.nivel > 3) && <button type="button" className="btn_link" onClick={goEdit}>Editar <EditIcon /></button>}
            </form>
        </div>
    );
}

export default Detalle;