import * as React from "react";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import CashMultipleIcon from "mdi-react/CashMultipleIcon";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import CityIcon from 'mdi-react/CityVariantOutlineIcon';
import OperadorIcon from 'mdi-react/KayakingIcon';
import EditIcon from "mdi-react/EditOutlineIcon";
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DetalleProducto = () => {
    const { setTitulo } = useTitle();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const data = location.state.data;
    const [showLoading, setShowLoading] = React.useState(true);
    const [showList, setShowList] = React.useState(false);
    const nombre = data.nombre || "";
    const [ciudades, setCiudades] = React.useState([]);
    const precio = data.valor_venta || "";
    const costo = data.costo || "";
    const descripcion = data.descripcion || "";
    const [itemsProducts, setItemsProducts] = React.useState([]);
    const [itemsDocuments, setItemsDocuments] = React.useState([]);
    const [listaProductos, setListaProductos] = React.useState([]);
    const tipo = parseInt(data.tipo) || 1;

    const form_ref = React.useRef(null);


    const goEdit = (e) => {
        e.preventDefault()

        navigate("/editar-producto", { state: { data: data, from: location } });
    }

    const handleClose = (e) => {
        e.preventDefault()

        if (location.state?.from?.pathname == "/productos") {
            navigate(-1);
        } else {
            navigate("/productos", { replace: true });
        }
    }

    const handleShowList = (e) => {
        e.preventDefault();

        if (showList) {
            document.body.style.overflow = "auto";
            setShowList(false);
        } else {
            document.body.style.overflow = "hidden";
            setShowList(true);
        }
    }

    const ListaProductos = () => {
        let lista = [];

        const handleAdd = (e, data) => {
            e.preventDefault();

            let _new = listaProductos.map((item) => {
                if (item.id === data.id) {
                    if (item.selected) {
                        item.selected = false;
                    } else {
                        item.selected = true;
                    }
                }

                return item;
            });

            setListaProductos(_new);
        }

        const handleAcept = (e) => {
            e.preventDefault();

            let _new = listaProductos.filter((item) => {
                if (item.selected) {
                    return item;
                }
            });

            setItemsProducts(_new);
            document.body.style.overflow = "auto";
            setShowList(false);
        }

        listaProductos.map((item, index) => {
            lista.push(<label key={index}> <input type="checkbox" name="casilla" checked={item.selected} onChange={e => handleAdd(e, item)} />
                <b>{item.id}</b> - {item.nombre} - <span>{item.nombre_operador} ( ${funciones.formatPrice(item.valor_venta).format} COP )</span>
            </label>);
        });

        if (lista.length < 1) {
            lista = <h4 className="titulo_msg">No hay resultados</h4>;
        }

        return (
            <>
                <div className="back_shadow"></div>
                <div className="popup_productos">

                    <h2>Lista de Productos</h2>
                    <p>Acontinuación seleccione el producto que desea añadir al combo.</p>

                    <div className="cont_item_list">
                        {lista}
                    </div>

                    <div className="botones">
                        <button type="button" onClick={handleShowList}>Cancelar</button>
                        <button type="button" onClick={handleAcept}>Agregar</button>
                    </div>

                </div>
            </>
        );
    }

    const ItemsDocumentos = () => {
        let items = [];

        itemsDocuments.map((item, index) => {
            items.push(<p key={index}>
                <b>{item.id}</b> {item.nombre}
            </p>);
        });

        return (
            <div className="fila">
                <div className="items_agregados">
                    {items}
                </div>
            </div>
        );
    }

    const ItemsProductos = () => {
        let items = [];

        itemsProducts.map((item, index) => {
            items.push(<p key={index}>
                <b>{item.id}</b> {item.nombre} <br /><span>(${funciones.formatPrice(item.valor_venta).format} COP)</span>
            </p>);
        });

        return (
            <div className="fila">
                <div className="items_agregados">
                    {items}
                </div>
            </div>
        );
    }

    const armaCiudad = async () => {

        let _data = await api.fetchJson({
            url: "get-destinos",
            data: {
                id: data.operador
            }
        });

        if (_data.response == "1") {
            setCiudades(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    const getOnliDocuments = async () => {
        let _data = await api.fetchJson({
            url: "get-templates",
            data: {
                type: "por_producto",
                id: data.id
            }
        });

        if (_data.response == "1") {
            let _lista = _data.data.map((item) => {
                item.selected = true;
                return item;
            });

            setItemsDocuments(_lista);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }
    }

    const getDocumentosPorProducto = async (_lista) => {
        let _doc_temp = [];

        let _template = await api.fetchJson({
            url: "get-templates",
            data: {
                type: "all"
            }
        });

        if (_template.response == "1") {

            let _data = await api.fetchJson({
                url: "get-templates",
                data: {
                    type: "por_producto",
                    id: data.id
                }
            });

            if (_data.response == "1") {
                _doc_temp = _data.data;
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;

                Alert(_msg, "warning");
            }

            _doc_temp = _doc_temp.filter((item) => {
                item.id = item.plantilla;
                return item;
            });

            setItemsDocuments(_doc_temp);

        } else if (_template.response === -2) {
            Alert(_template.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _template.data ? _template.data.msg : _template.msg;

            Alert(_msg, "warning");
        }
    }

    const getProductos = async () => {
        let _data = await api.fetchJson({
            url: "get-productos",
            data: {
                type: "all"
            }
        });

        if (_data.response == "1") {
            let _productos = data.productos ? data.productos.split(",") : [];

            let _lista = _data.data.map((item, index) => {

                if (_productos.indexOf(item.id.toString()) > -1) {
                    item.selected = true;
                } else {
                    item.selected = false;
                }

                return item;
            });


            let _new = _lista.filter((item) => {
                if (item.selected) {
                    return item;
                }
            });


            setListaProductos(_lista);
            setItemsProducts(_new);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }
    }

    React.useEffect(() => {
        getDocumentosPorProducto(itemsProducts);
    }, [itemsProducts]);

    React.useEffect(() => {
        if (tipo == 1) {
            setTitulo("Editar producto");
            getOnliDocuments();
        } else {
            setTitulo("Editar combo");
            getProductos();
        }

        armaCiudad();
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                <h2 className="title_close">Detalle {tipo == 1 ? "Producto" : "Combo"} ({data.id}) <CloseIcon className="close" onClick={handleClose} /> </h2>

                <div className="fila">

                    <div className="columna">
                        <label>Nombre</label>
                        <div className="input_icon">
                            <AccountOutlineIcon />
                            <p className="rerumen">{nombre}</p>
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Operador</label>
                        <div className="input_icon">
                            <OperadorIcon />
                            <p className="rerumen">{data.nombre_operador}</p>
                        </div>
                    </div>

                    <div className="columna">
                        <label>Ciudad</label>
                        <div className="input_icon">
                            <CityIcon />
                            <p className="rerumen">{ciudades.map((item, index) => {
                                if (item.id == data.ciudad) {
                                    return (item.nombre);
                                }
                            })}</p>
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Valor de venta</label>
                        <div className="input_icon">
                            <CashMultipleIcon />
                            <p className="rerumen">{funciones.formatPrice(precio).format}</p>
                        </div>
                    </div>

                    <div className="columna">
                        <label>Costo</label>
                        <div className="input_icon">
                            <CurrencyUsdIcon />
                            <p className="rerumen">{funciones.formatPrice(costo).format}</p>
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Descripción</label>
                        <div className="input_icon">
                            <textarea name="descripcion" rows="6" cols="20" placeholder={tipo == 1 ? "Descripción del producto" : "Descripción del combo"}
                                style={{ background: "transparent" }}
                                readOnly={true}
                                value={descripcion} />
                        </div>
                    </div>

                    <div className="columna">

                    </div>

                </div>

                <div className="fila">
                    <ItemsDocumentos />

                    {tipo == 2 && <>
                        <hr className="linea_vertical" />
                        <ItemsProductos />
                    </>}
                </div>

                <div className="columna" style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <button type="button" className="btn_link" onClick={goEdit}>Editar <EditIcon /></button>
                </div>

            </form>

            {showList && <ListaProductos />}
            {showLoading && <Loading />}
        </div>
    );
}

export default DetalleProducto;
