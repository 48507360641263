import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import * as Rdom from "react-router-dom";
import classNames from 'classnames';

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { data, resalta, children, onSel, current } = params;
    const [showMenu, setShowMenu] = React.useState(false);
    const navigate = Rdom.useNavigate();
    const row = React.useRef(null);

    const remarcado = classNames({
        'remarcado': resalta === 1,
        'seleccionado': current === data.id
    });


    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("ver-detalle", { state: { data: data } });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("ver-detalle", { state: { data: data } });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // let _width = document.getElementById("root").offsetWidth;
        // let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;


        // setPos({ top: e.pageY + "px", left: _posx + "px", });
        // setShowMenu(true);
    }


    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
        }
    }, []);

    return (
        <tr className={remarcado} ref={row}
            // onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="row_menu"
                onClick={stopClick}
                onDoubleClick={stopClick}
                onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu">
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Ver detalles</strong></span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>

                                <hr />

                                <li><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Borrar</span></li>

                            </ul>
                        </nav>
                    </>}

            </td>
        </tr>
    );

}

export default MenuRow;