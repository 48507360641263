import * as React from 'react';
import { Alert, alerta, alertaRemove } from "shared/Alert";
import CashIcon from 'mdi-react/CashMultipleIcon';
import funciones from "services/funciones";
import * as Iconos from "../../../images";
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";


const Abonos = () => {
    const { auth, verCaja, abrirCaja } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const data = location.state?.data || [];
    const [showLoading, setShowLoading] = React.useState(true);
    const [decuento, setDecuento] = React.useState(0);
    const [mPago, setMPago] = React.useState('');
    const [total, setTotal] = React.useState({ neto: 0, abonos: 0, total: 0, saldo: 0 });
    const [listMpagos, setListMpagos] = React.useState([]);
    const [basket, setBasket] = React.useState([]);
    const [abono, setAbono] = React.useState('');
    const [orden, setOrden] = React.useState([]);
    const [caja, setCaja] = React.useState({ estado: 0 });

    const form_ref = React.useRef(null);


    const validaAbono = () => {
        alertaRemove(form_ref.current.abono);

        if (abono == null || abono.length < 1) {
            alerta(form_ref.current.abono, "¡Este campo es requerido!");
            form_ref.current.abono.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaMpago = () => {
        alertaRemove(form_ref.current.m_pago);

        if (mPago == null || mPago < 1) {
            alerta(form_ref.current.m_pago, "¡Este campo es requerido!");
            form_ref.current.m_pago.focus();
            return false;
        } else {
            return true;
        }
    }

    const handleSave = async (e) => {
        e.preventDefault();

        if (validaAbono()
            && validaMpago()
        ) {
            setShowLoading(true);
            let _valor_abono = parseInt(funciones.formatPrice(abono).int);

            let _data = await api.fetchJson({
                url: "save-pago",
                data: {
                    id: data.id,
                    estado: total.saldo - _valor_abono <= 0 ? 3 : 2,
                    abono: _valor_abono,
                    metodo_pago: mPago,
                }
            });

            if (parseInt(_data.response) === 1) {

                setTimeout(() => {
                    setShowLoading(false);
                    Alert("Abono realizado con éxito", "ok", () => {
                        navigate(-1);
                    });
                }, 2000);

            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;
                setShowLoading(false);
                Alert(_msg, "warning");
            }
        }
    }

    const getMPagos = async () => {
        let _data = await api.fetchJson({
            url: "get-metodos-pago",
            data: {
                type: "all",
                id: window.atob(auth.auth)
            }
        });

        if (_data.response == "1") {
            setListMpagos(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }
    }

    const SelectPagos = () => {
        let metodos = listMpagos.map((item, index) => {
            return (
                <option key={index} value={item.id}>{item.nombre}</option>
            )
        });

        return (
            <div className="input_icon">
                <CashIcon />
                <select name="m_pago" value={mPago} onChange={(e) => { setMPago(e.target.value); }}>
                    <option value="0">Selecciona...</option>
                    {metodos}
                </select>
            </div>
        );
    }

    const getDetalle = async () => {
        let _data = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "por_id",
                id: data.id
            }
        });

        let _packs = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "productos_x_orden",
                id: data.id
            }
        });


        if (_data.response == "1") {
            setOrden(_data.data[0]);
            setDecuento(_data.data[0].descuento);
            let _total = parseInt(_data.data[0].valor_total - (_data.data[0].valor_total * parseInt(_data.data[0].descuento) / 100));

            setTotal({
                neto: parseInt(_data.data[0].valor_total),
                abonos: parseInt(_data.data[0].abonos),
                total: _total,
                saldo: _data.data[0].abonos ? parseInt(_total - _data.data[0].abonos) : _total
            });

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        if (_packs.response == "1") {
            // let _suma = 0;
            // _packs.data.map((item, index) => {
            //     _suma += parseInt(item.valor_producto) * parseInt(item.cantidad);
            // });


            setBasket(_packs.data);

            // setTotal({ neto: _suma, total: _suma - (_suma * parseInt(_data.data[0].descuento) / 100) });
        } else if (_packs.response === -2) {
            Alert(_packs.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _packs.data ? _packs.data.msg : _packs.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    const openCaja = (e) => {
        e.preventDefault();
        setShowLoading(true);

        abrirCaja().then((_data) => {
            _data.estado = parseInt(_data.estado);
            getDetalle();
            getMPagos();

            setCaja(_data);
        }).catch((_error) => {
            setTimeout(() => {
                setCaja({ estado: 2 });
                setShowLoading(false);
            }, 3000);
        });

    }

    React.useEffect(() => {
        setTitulo("Nuevo Abono");

        verCaja().then((_data) => {
            _data.estado = parseInt(_data.estado);
            getDetalle();
            getMPagos();

            setCaja(_data);
        }).catch((_error) => {
            setTimeout(() => {
                setCaja({ estado: 2 });
                setShowLoading(false);
            }, 3000);
        });
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            {caja.estado === 1 ?

                <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>

                    <div className="fila">
                        <div className="columna info_sell">
                            <h1>Nuevo Abono</h1>

                            {basket.map((item, index) => {
                                return (
                                    <div className="item_sell" key={index}>
                                        <h2>{item.nombre_producto}</h2>

                                        <h3><span>Cantidad:</span><i>{item.cantidad}</i></h3>

                                        <h3><span>Opera:</span> {item.nombre_operador}</h3>
                                        <h3><span>Lugar:</span> {item.nombre_ciudad}</h3>
                                        <p>
                                            <samp>${funciones.formatPrice(item.valor_producto).format} COP</samp>
                                            {item.descripcion}
                                        </p>
                                    </div>
                                );
                            })}

                        </div>
                    </div>

                    <fieldset style={{ marginBottom: 38 }}>
                        <legend>Información de contacto</legend>

                        <h2>{orden.nombre} {orden.apellido}</h2>
                        <p className="_info"><b>{funciones.tipoDoc(orden.tipo_doc)}:</b> {orden.num_doc}</p>
                        <p className="_info"><b>Correo:</b> {orden.correo}</p>
                        <p className="_info"><b>Teéfono:</b> {orden.telefono}</p>

                    </fieldset>

                    <div className="fila">
                        <p className="rerumen"><span>Descuento</span> {decuento}%</p>
                        <p className="rerumen"><span>Total</span> <i>${funciones.formatPrice(total.total).format} COP</i></p>
                    </div>

                    <div className="fila">
                        <p className="rerumen"><span>Abonado</span> <i>{total.abonos ? "$" + funciones.formatPrice(total.abonos).format + " COP" : "0"}</i></p>
                        <p className="rerumen"><span>Saldo</span> <i>{total.saldo > 0 ? "$" + funciones.formatPrice(total.saldo).format + " COP" : "0"} </i></p>
                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Valor a abonar</label>
                            <div className="input_icon">
                                <input type="text" name="abono" placeholder='ingresa el valor a abonar'
                                    value={funciones.formatPrice(abono).format} onChange={(e) => {
                                        alertaRemove(form_ref.current.abono);
                                        setAbono(e.target.value);
                                    }} />
                                <Iconos.Peso />
                            </div>
                        </div>

                        <div className="columna">
                            <label>Método de Pago</label>
                            <SelectPagos />
                        </div>

                    </div>


                    <div className="botones">
                        <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
                        <button type="button" onClick={handleSave}>Aceptar</button>
                    </div>

                </form>
                :
                <>
                    {caja.estado === 0 ?
                        <h2 className="titulo_msg">Comprobando caja...</h2>
                        :
                        <>
                            <h2 className="titulo_msg">¡La caja esta cerrada!</h2>
                            <p style={{ textAlign: "center" }}>Para continuar primero debes abrir la caja</p>
                            <button onClick={openCaja} style={{ width: "max-content", padding: "8px 18px", marginLeft: "50%", transform: "translateX(-50%)" }}>Abrir Caja</button>
                        </>
                    }
                </>

            }

            {showLoading && <Loading />}
        </div>
    );
}

export default Abonos;
