import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import ArrowRigth from "mdi-react/ChevronRightIcon";
import OperadorIcon from 'mdi-react/KayakingIcon';
import { Alert } from "shared/Alert";
import api from "services/api";

const CardOperadores = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [conteo, setConteo] = React.useState("...");
    const isMounted = React.useRef(true);

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-operadores",
            data: {
                type: "conteo",
            }
        });

        if (isMounted.current) {
            if (_data.response == "1") {
                setConteo(_data.data[0].conteo);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;

                Alert(_msg, "warning");
            }
        }
    }

    React.useEffect(() => {
        getInfo();

        return () => { isMounted.current = false; };
    }, []);

    return (
        <div className="card">
            <h2>Operadores</h2>
            <OperadorIcon className="logo_card" />

            <div className="cont_btns">
                <Link to="/agregar-operador">Crear nuevo <ArrowRigth /></Link>
                {/* <Link to="/">Ver todos <ArrowRigth /></Link> */}
            </div>

            <div className="cont_info">
                <h1>{conteo}</h1>
            </div>

        </div>
    );
}

export default CardOperadores;