import * as React from "react";
import CryptoJS from "crypto-js";
import { Alert } from "shared/Alert";
import api from "services/api";
import { useNavigate, useLocation, Link } from "react-router-dom";

const ContextTitle = React.createContext();
const ContextAuth = React.createContext();
const ContextBasket = React.createContext();


export function TitleProvider({ children }) {
    const [titulo, setTitle] = React.useState(document.title);
    const [showTitle, setShowTitle] = React.useState(true);

    const setTitulo = (_titulo, _show = true) => {
        document.title = _titulo;
        setTitle(_titulo);
        setShowTitle(_show);

        if (typeof android !== "undefined") {

            // eslint-disable-next-line no-undef
            android.setLastPage(window.location.href);
        }
    }

    return <ContextTitle.Provider
        value={{ showTitle, titulo, setTitulo }}>
        {children}
    </ContextTitle.Provider>
}

export function AuthProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const _data = JSON.parse(localStorage.getItem('usuario'));
    const [auth, setThisAuth] = React.useState(_data);

    const setAuth = (_user) => {
        if (_user !== null) {
            let current_time = new Date().getTime() / 1000;
            let respuesta_valida = _user == null ? 0 : CryptoJS.MD5(_user.auth + ":sarguero.com:" + current_time).toString();

            _user = {
                ..._user,
                time: parseInt(current_time),
                token: respuesta_valida
            };

            localStorage.setItem('usuario', JSON.stringify(_user));
            setThisAuth(_user);
        } else {
            localStorage.removeItem('usuario');
            setThisAuth(null);
        }
    }

    const getAuth = () => {
        let live_time;
        let current_time = new Date().getTime() / 1000;

        live_time = _data == null ? 0 : _data.time;

        console.log("Minutos: ", ((current_time - live_time) / 60).toFixed(0));

        if (current_time - live_time > 3600) {

            localStorage.removeItem('usuario');

            setThisAuth(null);
            return null;
        } else {
            let respuesta_valida = _data == null ? 0 : CryptoJS.MD5(_data.auth + ":sarguero.com:" + current_time).toString();
            let _auth = {
                ..._data,
                time: parseInt(current_time),
                token: respuesta_valida
            }

            localStorage.setItem('usuario', JSON.stringify(_auth));

            return _auth;
        }
    }

    const verCaja = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (auth && auth.nivel <= 3) {

                    let _data = await api.fetchJson({
                        url: "caja",
                        data: {
                            type: "ver_caja",
                        }
                    });

                    if (_data.response == "1") {
                        resolve(_data.data[0]);
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
                        reject(_data.msg);
                    } else {
                        let _msg = _data.data ? _data.data.msg : _data.msg;

                        Alert(_msg, "warning");
                        reject(_msg);
                    }
                } else {
                    reject("No tiene permisos para ver la caja");
                }
            } catch (error) {
                reject(error);
            }
        });
    }
    
    const abrirCaja = () => {
        return new Promise(async (resolve, reject) => {
            try {
                if (auth && auth.nivel <= 3) {

                    let _data = await api.fetchJson({
                        url: "caja",
                        data: {
                            type: "abre_caja",
                        }
                    });

                    if (_data.response == "1") {
                        resolve(_data.data[0]);
                    } else if (_data.response === -2) {
                        Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
                        reject(_data.msg);
                    } else {
                        let _msg = _data.data ? _data.data.msg : _data.msg;

                        Alert(_msg, "warning");
                        reject(_msg);
                    }
                } else {
                    reject("No tiene permisos para ver la caja");
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    React.useEffect(() => {
        getAuth();
    }, [auth]);

    return <ContextAuth.Provider
        value={{ auth, setAuth, getAuth, verCaja, abrirCaja }}>
        {children}
    </ContextAuth.Provider>
}

export function BasketProvider({ children }) {
    const [basket, setBasket] = React.useState([]);

    const addCount = (item) => {
        let _list = basket.map((i) => {
            if (i.id === item.id) {
                i.cantidad = i.cantidad + 1;
            }

            return i;
        });

        setBasket(_list);
    }

    const subCount = (item) => {

        if (item.cantidad > 1) {

            let _list = basket.map((i) => {
                if (i.id === item.id) {
                    i.cantidad = i.cantidad - 1;
                }

                return i;
            });


            setBasket(_list);
        } else {
            setBasket(b => b.filter(i => i.id !== item.id));
        }
    }

    return <ContextBasket.Provider
        value={{ basket, setBasket, addCount, subCount }}>
        {children}
    </ContextBasket.Provider>
}

export { ContextTitle, ContextAuth, ContextBasket };