import * as React from "react";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import { useNavigate } from "react-router-dom";
import { Alert, Confirm } from "shared/Alert";
import classNames from 'classnames';
import api from "services/api";


/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { data, resalta, children, onSel, reload, current } = params;
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const navigate = useNavigate();
    const row = React.useRef(null);

    const remarcado = classNames({
        'remarcado': resalta === 1,
        'seleccionado': current === data.id
    });


    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/detalle-usuario", { state: { data: data } });
    }

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/editar-usuario", { state: { data: data } });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`¿Seguro de borrar el usuario "${data.nombre} ${data.apellido}"?`, async (response) => {
            if (response) {
                let _data = await api.fetchJson({
                    url: "delete",
                    data: {
                        type: "user",
                        id_user: data.id
                    }
                });

                if (_data.response == 1) {
                    reload();
                    Alert("Usuario Borrado correctamente", "ok");
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true }));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 160 ? e.pageY - 160 : e.pageY;


        setPos({ top: _to_y + "px", left: _posx + "px" });
        setShowMenu(true);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
        }
    }, []);

    return (
        <tr className={remarcado} ref={row}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="row_menu"
                onClick={stopClick}
                onDoubleClick={stopClick}
                onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>
                                <li onClick={goOpen}><OpenIcon /> <span><strong>Ver detalles</strong></span></li>
                                <li onClick={goEdit}><EditIcon /> <span>Editar</span></li>

                                <hr />

                                <li onClick={goDelete}><DeleteIcon /> <span>Borrar</span></li>

                            </ul>
                        </nav>
                    </>}
            </td>
        </tr>
    );

}

export default MenuRow;