import * as React from 'react';
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
    var d = new Date(+this);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));

    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const ChartAreaVentas = (params) => {
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const isMounted = React.useRef(true);
    const [ordenes, setOrdenes] = React.useState([]);
    const [afterLoad, setAfterLoad] = React.useState(false);
    const { change } = params;
    

    const handleClick = (item) => {
        // const index = ordenes.indexOf(item.payload);
        change(item);
    }

    const getData = async () => {
        let _data = await api.fetchJson({
            url: "get-users",
            data: {
                roll: "vendedores"
            }
        });

        if (isMounted.current) {
            if (_data.response == "1") {
                let _sumatoria = 0;
                _data.data.map((item, index) => {
                    _sumatoria += parseInt(item.cantidad_ventas);
                });

                // Calcula el porcentaje de cada vendedor
                _data.data.map((item, index) => {
                    item.porcentaje = parseInt(item.cantidad_ventas) * 100 / _sumatoria;
                });

                setOrdenes(_data.data);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;

                Alert(_msg, "warning");
            }
        }

        setAfterLoad(true);
    }

    const Lista = () => {
        let _toReturn = [];

        ordenes.map((entry, index) => (
            _toReturn.push(
                <p key={index} onClick={() => handleClick(entry)}>
                    <span style={{ width: entry.porcentaje + "%" }}></span>
                    <i>
                        <samp>({entry.cantidad_ventas}) {entry.valor_ventas > 0 ? "$" + funciones.formatPrice(entry.valor_ventas).format : ""}</samp>
                        {entry.nombre} {entry.apellido}
                    </i>
                </p>)
        ));

        if (_toReturn.length == 0) {
            _toReturn.push(<h4 key={0} className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>);
        }

        return _toReturn;
    }

    React.useEffect(() => {
        getData();

        return () => { isMounted.current = false; };
    }, []);

    return (
        <div className="grafico_ventas">
            <div className="cont_barras">

                <h4>Ventas de los agentes durante el día</h4>

                <Lista />

            </div>
        </div>
    );
};

export default ChartAreaVentas;
