import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (navigate && pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return children;
};

export default ScrollToTop;
