import * as React from 'react';
import CardAccountDetailsOutlineIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import { Alert, alerta, alertaRemove } from "shared/Alert";
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint react-hooks/exhaustive-deps: 0 */

const NuevoMetodoPago = () => {
  const { setTitulo } = useTitle();
  const navigate = Rdom.useNavigate();
  const location = Rdom.useLocation();
  const [nombre, setNombre] = React.useState('');
  const [cuenta, setCuenta] = React.useState('');
  const [descripcion, setDescripcion] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);
  const form_ref = React.useRef(null);

  const validaCampos = (e) => {
    e.preventDefault();

    alertaRemove(form_ref.current.nombre);
    alertaRemove(form_ref.current.cuenta);
    alertaRemove(form_ref.current.descripcion);

    if (nombre == null || nombre.length < 1) {
      alerta(form_ref.current.nombre, "¡Este campo es requerido!");
      form_ref.current.nombre.focus();
      return false;
    }

    if (cuenta == null || cuenta.length < 1) {
      alerta(form_ref.current.cuenta, "¡Este campo es requerido!");
      form_ref.current.cuenta.focus();
      return false;
    }

    if (descripcion == null || descripcion.length < 1) {
      alerta(form_ref.current.descripcion, "¡Este campo es requerido!");
      form_ref.current.descripcion.focus();
      return false;
    }

    saveData();
  }

  const saveData = async () => {
    setShowLoading(true);

    let _data = await api.fetchJson({
      url: "save-metodo-pago",
      data: {
        id: 0,
        nombre: nombre,
        cuenta: cuenta,
        descripcion: descripcion
      }
    });

    if (parseInt(_data.response) === 1) {

      setTimeout(() => {
        setShowLoading(false);
        Alert("Método de pago agregado con éxito", "ok", () => {
          navigate(-1);
        });
      }, 2000);


    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
    } else {
      let _msg = _data.data ? _data.data.msg : _data.msg;
      setShowLoading(false);
      Alert(_msg, "warning");
    }
  }

  React.useEffect(() => {
    setTitulo("Nuevo método de pago");
  }, []);

  return (
    <div className="cont_principal deg_top cont_forms">

      <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
        <h2>Nuevo método de pago</h2>

        <div className="fila">

          <div className="columna">
            <label>Nombre</label>
            <div className="input_icon">
              <AccountOutlineIcon />
              <input type="text" name="nombre" placeholder='Nombre del método de pago'
                value={nombre} onChange={(e) => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
            </div>
          </div>

          <div className="columna">
            <label>Nº Cuenta</label>
            <div className="input_icon">
              <CardAccountDetailsOutlineIcon />
              <input type="text" name="cuenta" placeholder='Nº de cuenta'
                value={cuenta} onChange={(e) => { alertaRemove(form_ref.current.cuenta); setCuenta(e.target.value) }} />
            </div>
          </div>

        </div>

        <div className="columna">
          <label>Descripción</label>
          <div className="input_icon">
            <textarea name="descripcion" cols="30" rows="5" placeholder='Descripción'
              value={descripcion} onChange={(e) => { alertaRemove(form_ref.current.descripcion); setDescripcion(e.target.value) }}></textarea>
          </div>
        </div>

        <div className="botones">
          <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
          <button type="button" onClick={validaCampos}>Agregar</button>
        </div>

      </form >

      {showLoading && <Loading />}
    </div >
  );
}

export default NuevoMetodoPago;
