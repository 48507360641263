import * as React from 'react';

export function UserCircle({ color = "currentColor", className = "mdi-icon" }) {
    let _fill = className != "mdi-icon" ? {} : { fill: color };

    return (
        <svg className={className} width="24" height="24" viewBox="0 0 496 512">
            <path {..._fill} d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z" />
        </svg>
    );
}

export function Percent({ color = "currentColor" }) {

    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <text fill={color} y="17" x="5">%</text>
            <path fill={color} d="m 17,22 v -2 h 3 v -3 h 2 v 3.5 c 0,0.4 -0.2,0.7 -0.5,1 -0.3,0.3 -0.7,0.5 -1,0.5 H 17 M 7,22 H 3.5 C 3.1,22 2.8,21.8 2.5,21.5 2.2,21.2 2,20.8 2,20.5 V 17 h 2 v 3 h 3 v 2 M 17,2 h 3.5 c 0.4,0 0.7,0.2 1,0.5 0.3,0.3 0.5,0.6 0.5,1 V 7 H 20 V 4 H 17 V 2 M 7,2 V 4 H 4 V 7 H 2 V 3.5 C 2,3.1 2.2,2.8 2.5,2.5 2.8,2.2 3.1,2 3.5,2 H 7" />
        </svg>
    );
}

export function Peso({ color = "currentColor" }) {

    return (
        <svg height="24" width="24" viewBox="0 0 24 24">
            <text fill={color} y="17" x="8">$</text>
            <path fill={color} d="m 17,22 v -2 h 3 v -3 h 2 v 3.5 c 0,0.4 -0.2,0.7 -0.5,1 -0.3,0.3 -0.7,0.5 -1,0.5 H 17 M 7,22 H 3.5 C 3.1,22 2.8,21.8 2.5,21.5 2.2,21.2 2,20.8 2,20.5 V 17 h 2 v 3 h 3 v 2 M 17,2 h 3.5 c 0.4,0 0.7,0.2 1,0.5 0.3,0.3 0.5,0.6 0.5,1 V 7 H 20 V 4 H 17 V 2 M 7,2 V 4 H 4 V 7 H 2 V 3.5 C 2,3.1 2.2,2.8 2.5,2.5 2.8,2.2 3.1,2 3.5,2 H 7" />
        </svg>
    );
}

export function Surface({ color = "currentColor" }) {

    return (
        <svg width="14" height="14" viewBox="0 0 32 32" >
            <path fill="none" stroke={color} strokeWidth="4"
                d="M0,16h10.666666666666666
                   A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                   H32M21.333333333333332,16
                   A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16" />
        </svg>
    );
}

export function PackAdd({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M13 19.3V12.6L19 9.2V13C19.7 13 20.4 13.1 21 13.4V7.5C21 7.1 20.8 6.8 20.5 6.6L12.6 2.2C12.4 2.1 12.2 2 12 2S11.6 2.1 11.4 2.2L3.5 6.6C3.2 6.8 3 7.1 3 7.5V16.5C3 16.9 3.2 17.2 3.5 17.4L11.4 21.8C11.6 21.9 11.8 22 12 22S12.4 21.9 12.6 21.8L13.5 21.3C13.2 20.7 13.1 20 13 19.3M12 4.2L18 7.5L16 8.6L10.1 5.2L12 4.2M11 19.3L5 15.9V9.2L11 12.6V19.3M12 10.8L6 7.5L8 6.3L14 9.8L12 10.8M20 15V18H23V20H20V23H18V20H15V18H18V15H20Z" />
        </svg>
    );
}

export function ChartDownload({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" />
            <path fill={color} transform="translate(0, 1.50)" d="M20 16H18V20H16L19 23L22 20H20V16Z" />
        </svg>
    );
}

export function FileChartCheckOutline({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M6 2C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H13.8C13.4 21.4 13.2 20.7 13.1 20H6V4H13V9H18V13.1C18.3 13 18.7 13 19 13C19.3 13 19.7 13 20 13.1V8L14 2H6M11 11V19H13V11H11M7 13V19H9V13H7Z" />
        </svg>
    );
}

export function ChartVentas({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M6,16.5L3,19.44V11H6M11,14.66L9.43,13.32L8,14.64V7H11M16,13L13,16V3H16M18.81,12.81L17,11H22V16L20.21,14.21L13,21.36L9.53,18.34L5.75,22H3L9.47,15.66L13,18.64" />
            <text fill={color} transform="scale(0.5)" y="46" x="35">$</text>
        </svg>
    );
}

export function FilePdf({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M 14,2 H 6 C 4.89,2 4,2.9 4,4 v 16 c 0,1.11 0.89,2 2,2 h 12 c 1.11,0 2,-0.89 2,-2 V 8 L 14,2 m 4,18 H 6 V 4 h 7 c -0.384062,5.3467807 -0.01846,5 5,5 v 11" />
            <path fill={color} d="m 11.924107,13.205196 h 0.805169 v 2.415506 h -0.805169 v -2.415506 m -4.0112452,0 h 0.8333085 c 0.1416925,0.19791 0.1215804,0.66037 0,0.819938 H 7.9128618 M 9.911185,13.205198 c 0,-0.667024 -0.5407293,-1.207753 -1.2077529,-1.207753 H 6.6905102 v 4.831012 h 1.2077531 v -1.610339 h 0.8051688 c 0.6670236,0 1.2077529,-0.54073 1.2077529,-1.207753 v -0.805169 m 4.025844,0 c 0,-0.667024 -0.54073,-1.207753 -1.207753,-1.207753 h -2.012922 v 4.831012 h 2.012922 c 0.667023,0 1.207753,-0.540729 1.207753,-1.207753 v -2.415506 m 3.220674,-1.207753 h -2.415506 v 4.831012 h 1.207754 v -1.610337 h 1.207752 v -1.207753 h -1.207752 v -0.805169 h 1.207752 z" />
        </svg>
    );
}

// printers

export function PrinterOutline({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" />
        </svg>
    );
}

export function PrinterLabel({ color = "currentColor" }) {
    return (
        <svg className="mdi-icon" width="24" height="24" viewBox="0 0 24 24">
            <path fill={color} d="m 6,2.640625 v 5 H 5 c -1.66,0 -3,1.34 -3,3 v 6 h 3.9746094 v -2 H 4 v -4 c 0,-0.55 0.45,-1 1,-1 h 14 c 0.55,0 1,0.45 1,1 v 4 h -2 l -0.02539,0.02148 v 2 L 18,16.640625 h 4 v -6 c 0,-1.66 -1.34,-3 -3,-3 h -1 v -5 z m 2,2 h 8 v 3 H 8 Z m 10,5.5 c -0.55,0 -1,0.45 -1,1 0,0.55 0.45,1 1,1 0.55,0 1,-0.45 1,-1 0,-0.55 -0.45,-1 -1,-1 z" />
            <path fill={color} d="m 18,18.64918 c 0,0.38 -0.21,0.71 -0.53,0.88 l -4.9,2.69 c -0.17,0.12 -0.36,0.18 -0.57,0.18 -0.21,0 -0.41,-0.06 -0.57,-0.18 l -4.9,-2.69 c -0.32,-0.17 -0.53,-0.5 -0.53,-0.88 v -5.25 c 0,-0.38 0.21,-0.71 0.53,-0.88 l 4.9,-2.44 c 0.16,-0.12 0.36,-0.18 0.57,-0.18 0.21,0 0.4,0.06 0.57,0.18 l 4.9,2.44 c 0.32,0.17 0.53,0.5 0.53,0.88 v 5.25 m -6,-6.6 -2.96,1.35 2.96,1.6 2.96,-1.6 -2.96,-1.35 m -4,6.01 3,1.63 v -2.96 l -3,-1.62 v 2.95 m 8,0 v -2.95 l -3,1.62 v 2.96 z" />
        </svg>
    );
}