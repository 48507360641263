import * as React from 'react';
import ReactDOM from 'react-dom';
import InfoCircleIcon from 'mdi-react/InformationOutlineIcon';
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import InfoIcon from 'mdi-react/InformationVariantIcon';
import EmailIcon from "mdi-react/EmailMultipleIcon";
import OkIcon from 'mdi-react/CheckBoldIcon';
import AlerIcon from 'mdi-react/WarningIcon';
import DatePicker from "react-datepicker";

/* eslint jsx-a11y/anchor-is-valid: 0 */

const AlertComponent = ({ msg, icono, action }) => {
  let Icon;

  const onDismiss = () => {
    action();
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  switch (icono) {
    case "alert":
    case "warning":
      Icon = <AlerIcon />;
      break;
    case "ok":
      Icon = <OkIcon />;
      break;
    case "infoc":
      Icon = <InfoCircleIcon />;
      break;
    case "info":
    default:
      Icon = <InfoIcon />;
      break;
  }


  return (
    <div className="alert__content">
      <div className="alert__icon">{Icon}</div>
      <p>{msg}</p>
      <a className="close" onClick={onDismiss}>Aceptar</a>
    </div>
  );
}

const ConfirmComponent = ({ msg, action }) => {
  let Icon = <AlerIcon />;

  const onDismiss = (e, _response) => {
    e.preventDefault();
    e.stopPropagation();

    action(_response);
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  return (
    <div className="alert__content">
      <div className="alert__icon">{Icon}</div>
      <p>{msg}</p>
      <div className="in_row">
        <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
        <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
      </div>
    </div>
  );
}

const ConfirmaFechaComponent = ({ msg, fecha, action }) => {
  const [dateEjecut, setDateEjecut] = React.useState(new Date());

  const onDismiss = (e, _response) => {
    e.preventDefault();
    e.stopPropagation();
    let _fecha_ejecucion = new Date(dateEjecut.getFullYear(), dateEjecut.getMonth(), dateEjecut.getDate(), 0, 0, 0, 0).getTime() / 1000;

    action(_response, _fecha_ejecucion);
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  React.useEffect(() => {
    setDateEjecut(new Date(fecha * 1000));
  }, [fecha]);

  return (
    <div className="alert__content">
      <p style={{ height: 24 }}></p>
      <p>{msg}</p>
      <i className="resartado">Asegurate de verificar la fecha de ejecución</i>
      <div>
        <label>Fecha de la actividad</label>
        <div className="input_icon">
          <DatePicker name='fecha' dateFormat="yyyy/MM/dd" selected={dateEjecut} onChange={(date) => setDateEjecut(date)} />
          <CalendarMonthIcon />
        </div>
      </div>

      <div className="in_row">
        <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
        <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
      </div>
    </div>
  );
}

const AgregaCorreoComponent = ({ msg, correo, action }) => {
  const [correos, setCorreos] = React.useState("");

  const onDismiss = (e, _response) => {
    e.preventDefault();
    e.stopPropagation();
    let _correos = correos.trim();

    if (_correos.startsWith(",")) {
      _correos = _correos.substring(1);
    }

    action(_response, _correos);
    ReactDOM.unmountComponentAtNode(document.getElementById("modal"));
  }

  return (
    <div className="alert__content">
      <p style={{ height: 24 }}></p>
      <p>{msg}</p>
      <div style={{ margin: "0 12px" }}>
        <label>Correo(s)</label>
        <div className="input_icon">
          <input type="email" name="correo" className="to_mail" placeholder={correo}
            value={correos} onChange={e => setCorreos(e.target.value)} />
          <EmailIcon />
        </div>
      </div>

      <div className="in_row">
        <a className="close" onClick={e => onDismiss(e, true)}>Aceptar</a>
        <a className="close" onClick={e => onDismiss(e, false)}>Cancelar</a>
      </div>
    </div>
  );
}

const Alert = (_msg, _icono = "info", action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <AlertComponent msg={_msg} icono={_icono} action={action} />
    </div>,
    _modal,
  );
}

const Confirm = (_msg, action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <ConfirmComponent msg={_msg} action={action} />
    </div>,
    _modal,
  );
}

const alertArea = (_current_node, _msg) => {
  let _new_node = document.createElement("span");
  let _add_alert = true;

  if (_current_node.classList.contains("alert__resalta_input")) {
    _add_alert = false;
  }

  if (_add_alert) {
    _current_node.classList.add("alert__resalta_input");
    _new_node.className = "alert__input _up _area";
    _new_node.innerText = _msg;

    _current_node.insertAdjacentElement("afterend", _new_node);
  }
}

const alerta = (_current_node, _msg) => {
  let _new_node = document.createElement("span");
  let _add_alert = true;

  if (_current_node.classList.contains("alert__resalta_input")) {
    _add_alert = false;
  }

  if (_add_alert) {
    _current_node.classList.add("alert__resalta_input");
    _new_node.className = "alert__input _up";
    _new_node.innerText = _msg;

    _current_node.insertAdjacentElement("afterend", _new_node);
  }
}

const alertDown = (_current_node, _msg) => {
  let _new_node = document.createElement("span");
  let _add_alert = true;

  if (_current_node.classList.contains("alert__resalta_input")) {
    _add_alert = false;
  }

  if (_add_alert) {
    _current_node.classList.add("alert__resalta_input");
    _new_node.className = "alert__input _down";
    _new_node.innerText = _msg;

    _current_node.insertAdjacentElement("afterend", _new_node);
  }
}

const alertaRemove = (_current_node) => {
  let _alerta = _current_node == null ? null : _current_node.nextSibling;

  if (_alerta != null) {
    _current_node.classList.remove("alert__resalta_input");

    if (_alerta.className === "alert__input _up"
      || _alerta.className === "alert__input _down"
      || _alerta.className === "alert__input _up _area") {
      _alerta.parentNode.removeChild(_alerta);
    }
  }
}

const ConfirmaFecha = (_msg, _fecha, action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <ConfirmaFechaComponent msg={_msg} fecha={_fecha} action={action} />
    </div>,
    _modal,
  );
}

const AgregaCorreo = (_msg, _correo, action = () => console.log("No action defined")) => {
  let _modal = document.getElementById("modal");

  ReactDOM.render(
    <div className="alert">
      <AgregaCorreoComponent msg={_msg} correo={_correo} action={action} />
    </div>,
    _modal,
  );
}

export { Alert, Confirm, alerta, alertArea, alertDown, alertaRemove, ConfirmaFecha, AgregaCorreo };
