import * as React from "react";
import { numeroALetras } from "services/funciones";
import logo from "../../../images/logo_black.png";
import Autorenew from 'mdi-react/AutorenewIcon';
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import Check from "mdi-react/CheckIcon";
import { Alert } from "shared/Alert";
// import QRCode from "react-qr-code";
import api from "services/api";
import { jsPDF } from "jspdf";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

/**
 * 
 * Barcodes Type
 *
 *   CODE128
 *   EAN / UPC
 *   CODE39
 *   ITF-14
 *   MSI
 *   Pharmacode
 *   Codabar
 *
 */

const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

const PdfDoc = (param) => {
    const { data, pos, close } = param;
    const [factura, setFactura] = React.useState(null);
    const [_style, setStyle] = React.useState(pos);
    const facturaRef = React.useRef();
    const navigate = Rdom.useNavigate();


    const downloadPdf = () => {
        let doc = new jsPDF("portrait", 'px', [816, 1056]);

        doc.html(facturaRef.current, {
            callback: (documento) => {

                // documento.addPage("portrait", 'px', [816, 1056]);
                // documento.text("Prueba de texto de la pagina dos", 10, 20);
                // documento.setPage(1);

                let pageHeight = documento.internal.pageSize.height;
                let factutaHeight = facturaRef.current.offsetHeight;
                let pages = Math.ceil(factutaHeight / pageHeight);





                //         let y = 1056;
                console.log("doc", doc);
                console.log("height", pageHeight);
                console.log("height", factutaHeight);
                console.log("pages", pages);

                //         if (y >= pageHeight) {
                //             console.log("es mayor a height", pageHeight);
                //         }

                documento.toDataURL();
                // documento.save(meses[new Date(data.fecha_ejecucion * 1000).getMonth()] + "-" + parseInt(data.id) + ".pdf");
                close(false);
            }
        });

    }

    const getDetalle = async () => {
        let _templates = [];
        let _data = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "por_id",
                id: data.id
            }
        });

        let _packs = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "productos_x_orden",
                id: data.id
            }
        });


        if (_data.response == 1) {
            _data = _data.data[0];
            let _total = parseInt(_data.valor_total - (_data.valor_total * parseInt(_data.descuento) / 100));

            _total = {
                neto: parseInt(_data.valor_total),
                abonos: parseInt(_data.abonos),
                total: _total,
                saldo: _data.abonos ? parseInt(_total - _data.abonos) : _total
            };

            if (parseInt(_data.estado) > 1) {
                let _consulta_templates = await api.fetchJson({
                    url: "get-templates",
                    data: {
                        type: "por_orden",
                        id: data.id
                    }
                });

                if (_consulta_templates.response == 1) {
                    _templates = _consulta_templates.data;
                }
            }

            let _factura = <div className="impress_label">
                <div className="_header">
                    <img src={logo} alt="Logo de la emprsa" />

                    <div className="_header_info">
                        <h3>TOBIAEXTREME</h3>
                        <p>NIT 80149023-1</p>
                        <p>PBX: 7559952</p>
                        <p>ventas@tobiaextreme.com</p>
                    </div>

                    {/* <QRCode
                        size={150}
                        value={process.env.REACT_APP_URL_ORDER + data.id} /> */}

                    <div className="_header_num" style={{ gridRow: 1 }}>
                        <p>{funciones.estadoOrden(data.estado)}</p>
                        <h2>N°: {meses[new Date(data.fecha_ejecucion * 1000).getMonth()]}-{data.id}</h2>
                    </div>
                </div>

                <div className="_body">
                    <div className="_body_info">
                        <div className="_body_info_user">
                            <div className="_info_row">
                                <div className="_row">
                                    <h3>Señor@</h3>
                                    <p>{data.nombre} {data.apellido}</p>
                                </div>
                                <div className="_row">
                                    <h3>{funciones.tipoDoc(data.tipo_doc)}</h3>
                                    <p>{data.num_doc}</p>
                                </div>
                            </div>

                            <div className="_info_row">
                                <div className="_row">
                                    <h3>Correo</h3>
                                    <p>{data.correo}</p>
                                </div>
                                <div className="_row">
                                    <h3>Teléfono</h3>
                                    <p>{data.telefono}</p>
                                </div>
                            </div>

                            <div className="_info_row">
                                <div className="_row">
                                    <h3>Nota</h3>
                                    <p>{data.notas}</p>
                                </div>
                            </div>
                        </div>

                        <div className="_body_info_date">
                            <h2>Fecha y caducidad</h2>

                            <div className="_info_row">
                                <h3>Fecha creada</h3>
                                <p>{funciones.formatFecha(data.fecha_creacion)}</p>
                            </div>

                            <div className="_info_row">
                                <h3>Fecha actividad</h3>
                                <p>{funciones.formatFecha(data.fecha_ejecucion)}</p>
                            </div>
                        </div>
                    </div>

                    <table className="_body_items">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Destino</th>
                                <th>Vr. unitario</th>
                                <th>Cantidad</th>
                                <th>Vr. Total</th>
                            </tr>
                        </thead>

                        <tbody>
                            {_packs.data.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.producto}</td>
                                        <td>{item.nombre_producto}</td>
                                        <td>{item.descripcion}</td>
                                        <td>{item.nombre_ciudad}</td>
                                        <td>${funciones.formatPrice(item.valor_producto).format}</td>
                                        <td>{item.cantidad}</td>
                                        <td>${funciones.formatPrice(item.valor_producto * item.cantidad).format}</td>
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                </div>

                <div className="_valores">
                    <p>
                        <b>Total en letras: </b> {numeroALetras(_total.total).toLocaleLowerCase()}
                        <br />
                        <br />
                        <b>Saldo en letras: </b> {numeroALetras(_total.saldo).toLocaleLowerCase()}
                    </p>

                    <h4>
                        {data.descuento >= 1 && <>
                            <b>Neto:</b> <span>${funciones.formatPrice(_total.neto).format} COP</span><br />
                            <samp>Descuento del {data.descuento}%</samp>
                        </>}

                        <b>Total:</b> <span>${funciones.formatPrice(_total.total).format} COP</span><br />
                        <hr />
                        <b>Abonado:</b> <span>{_total.abonos ? "$" + funciones.formatPrice(_total.abonos).format + " COP" : "0"}</span><br />
                        <hr />
                        <b>Saldo:</b> <span>{_total.saldo > 0 ? "$" + funciones.formatPrice(_total.saldo).format + "COP" : "0"}</span><br />
                        <samp className="_last">{data.metodos_pago ? data.metodos_pago : "..."}</samp>
                    </h4>
                </div>

                <div className="_footer">
                    <p>© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
                </div>
            </div>;

            let _anexos = <div className="impress_label">
                <div className="_anexo">
                    {_templates.map((document, index) => {
                        switch (parseInt(document.tipo)) {
                            case 1:
                                return <h3 key={index}>{document.contenido}</h3>;
                            case 2:
                                return <p key={index}>{document.contenido}</p>;
                            case 3:
                                return <p key={index} className="_option"><Check />{document.contenido}</p>;
                            case 4:
                                return <img key={index} src={document.imagen} alt="Imagen de la plantilla" className="img_template" />;
                            case 5:
                                return <div key={index} className="cont_img_template">
                                    <img src={document.imagen} alt="Imagen de la plantilla" className="img_template" />
                                    <p>{document.contenido}</p>
                                </div>;
                        }
                    })}
                </div>

                <div className="_footer">
                    <p>© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
                </div>
            </div>;

            setFactura(
                <>
                    {_factura}
                    {parseInt(_data.estado) > 1 && _anexos}
                </>
            );

            setStyle({
                // ...pos,

                width: "816px",
                position: "relative",

                borderRadius: 0,
                boxShadow: "none",
                padding: 0
            });

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate(-1));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    React.useEffect(() => {

        if (factura) {
            console.log("imprimiendo");
            setTimeout(() => {
                downloadPdf();
            }, 2000);
        }
    }, [factura]);

    React.useEffect(() => {
        setTimeout(() => {
            getDetalle();
        }, 1000);
    }, [data]);

    return (
        <div className="content_modal">

            <div className="win_modal _impress" style={_style} ref={facturaRef}>
                {factura}
            </div>

            <div className="win_modal _impress" style={pos}>
                <h4 className="_loadind"><Autorenew /> Generando documento...</h4>
            </div>

        </div>
    );
}

export default PdfDoc;