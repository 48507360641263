import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SwapVerticalIcon from 'mdi-react/SwapVerticalIcon';
import ArrowRigth from "mdi-react/ChevronRightIcon";
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import MenuRow from "./components/MenuRow";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import Cell from "shared/Cell";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Operadores = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = useNavigate();
    const location = useLocation();
    const [asc, setAsc] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [filtro, setFiltro] = React.useState({ type: "all" });
    const [showLoading, setShowLoading] = React.useState(true);

    const Row = (params) => {

        return (
            <MenuRow {...params}
                current={selected.id}
                onSel={setSelected}
                reload={getOperadores}>

                <td>{params.data.nombre}</td>
                <td>{params.data.ciudades}</td>
                <td>{params.data.url}</td>
                <td>{params.data.representante}</td>
                <td>{params.data.telefono}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} resalta={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return <div className="cont_tabla">
                <table>
                    <thead>
                        <tr>
                            <Cell><SwapVerticalIcon onClick={() => sortBy("nombre")} />Nombre</Cell>
                            <Cell>Sedes</Cell>
                            <Cell>Sitio web</Cell>
                            <Cell>Contacto</Cell>
                            <Cell>Teléfono</Cell>
                        </tr>
                    </thead>

                    <tbody>
                        {_data}
                    </tbody>
                </table>
            </div>;
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const handleKey = (e) => {
        e.preventDefault();

        if (e.key === "Enter") {
            handleSearch(e);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();

        if (criterio.length > 0) {
            setShowLoading(true);

            setFiltro({
                type: "search",
                criterio: criterio
            });
        }
    }

    const handleShowAll = (e) => {
        e.preventDefault();

        setShowLoading(true);

        setCriterio("");

        setFiltro({ type: "all" });
    }

    const getOperadores = async () => {
        let _data = await api.fetchJson({
            url: "get-operadores",
            data: filtro
        });

        if (_data.response == "1") {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
        setShowLoading(false);
    }

    React.useEffect(() => {
        getOperadores();
    }, [filtro]);

    React.useEffect(() => {
        setTitulo("Operadores");

        if (auth && auth.nivel >= 3) {
            navigate("/", { replace: true, state: { from: location } });
        }
    }, []);

    return (
        <div className="cont_principal deg_top">

            <div className="cont_titulo only_searsh">
                <h2>Operadores</h2>

                <Link to="/agregar-operador">Crear nuevo <ArrowRigth /></Link>

                {filtro.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                <div className="search_bar">
                    <input type="text" placeholder="Buscar por nombre, contacto o teléfono"
                        value={criterio} onChange={e => setCriterio(e.target.value)}
                        onKeyUp={handleKey} />
                    <MagnifyIcon onClick={handleSearch} />
                </div>

            </div>

            <AllRow />
            {showLoading && <Loading />}
        </div>
    );

}

export default Operadores;