import * as React from "react";
import BoxCloseIcon from "mdi-react/PackageVariantClosedIcon";
import { useLocation, useNavigate, Link } from "react-router-dom";
import BoxOpenIcon from "mdi-react/PackageVariantIcon";
import DeleteIcon from "mdi-react/DeleteOutlineIcon";
import { Alert, Confirm } from "shared/Alert";
import EditIcon from "mdi-react/EditOutlineIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import OpenIcon from "mdi-react/OpenInNewIcon";
import * as Iconos from "../../../images";

import classNames from 'classnames';

import api from "services/api";
// import PrintLabel from "./PrintLabel";
// import PrintOrder from "./PrintOrder";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const MenuRow = (params) => {
    const { data, resalta, children, onSel, current } = params;
    const navigate = useNavigate();
    const row = React.useRef(null);

    const remarcado = classNames({
        'remarcado': resalta === 1,
        'seleccionado': current === data.id
    });

    const goOpen = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/abonos", { state: { data: data } });
    }

    const onSelect = (e) => {
        e.preventDefault()
        e.stopPropagation();

        onSel(data);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <tr className={remarcado} ref={row}
            onDoubleClick={goOpen}
            onClick={onSelect}
            onContextMenu={handleContextMenu}>

            {children}
            <td className="row_menu"></td>
        </tr>
    );

}

export default MenuRow;