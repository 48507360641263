import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import BasketRemoveIcon from "mdi-react/BasketMinusOutlineIcon";
import BasketIcon from "mdi-react/BasketPlusOutlineIcon";
import ArrowRigth from "mdi-react/ChevronRightIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import MinusIcon from "mdi-react/MinusIcon";
import funciones from "services/funciones";
import PlusIcon from "mdi-react/PlusIcon";
import useBasket from "hooks/useBasket";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ListaProductos = ({ hiddenList, home }) => {
    const { setTitulo } = useTitle();
    const { basket, setBasket, addCount, subCount } = useBasket();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();
    const [criterio, setCriterio] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(true);
    const [filtro, setFiltro] = React.useState({ type: "all", id: window.atob(auth.auth) });

    const Card = ({ data }) => {
        const [thisItem, setThisItem] = React.useState({});
        const [isInBasket, setIsInBasket] = React.useState(false);


        const handleAdd = (e) => {
            e.stopPropagation();

            addCount(thisItem);
        }

        const handleSub = (e) => {
            e.stopPropagation();

            subCount(thisItem);
        }

        const handleClick = (e) => {
            e.preventDefault();

            if (isInBasket) {
                setBasket(b => b.filter(item => item.id !== data.id));
            } else {
                setBasket(b => [...b, data]);
            }
        }

        React.useEffect(() => {
            basket.forEach(item => {
                if (item.id === data.id) {
                    item.cantidad = item.cantidad ?? 1;
                    setIsInBasket(true);
                    setThisItem(item);
                }
            });
        }, [basket]);

        return (
            <div className={isInBasket ? "grid_item _added_to_cart" : "grid_item"}>
                <div className="_button" onClick={handleClick}>
                    {isInBasket ?
                        <span>Quitar de mi canasta <BasketRemoveIcon />
                            <samp>
                                Cantidad:
                                <i>
                                    <MinusIcon onClick={handleSub} />
                                    {thisItem.cantidad}
                                    <PlusIcon onClick={handleAdd} />
                                </i>
                            </samp>
                        </span>
                        :
                        <span>Agregar a mi canasta <BasketIcon /></span>
                    }
                </div>

                <div className="grid_item_info">
                    <h2>{data.nombre}</h2>
                    <h4>{data.destino}</h4>
                    <h4>{data.nombre_operador}</h4>
                    <p>{data.descripcion.length > 120 ? data.descripcion.substring(0, 120) + "..." : data.descripcion}</p>
                </div>

                <div className="grid_item_image">
                    <img src={data.imagen} alt={data.nombre} />
                    <h5>$ {funciones.formatPrice(data.valor_venta).format} COP</h5>
                </div>

            </div>
        );
    }

    const AllCards = () => {
        let cards = [];

        dataList.map((item, index) => {
            cards.push(<Card key={index} data={item} />);
        });

        if (cards.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return (
                <div className="grid_content">
                    {cards}
                </div>
            );
        }
    }

    const getProductos = async () => {
        let _data = await api.fetchJson({
            url: "get-productos",
            data: filtro
        });

        if (_data.response == "1") {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
        setShowLoading(false);
    }

    const BtnShowList = () => {
        let toReturn = null;

        if (auth && auth.nivel == 1) {
            if (typeof hiddenList == "function") {
                toReturn = <button className="btn_lista" onClick={() => hiddenList(false)}>{home ? "Volver a dashboard" : "Lista de ordenes"} <ArrowRigth /></button>;
            } else {
                toReturn = <Link to="/ordenes">Lista de ordenes <ArrowRigth /></Link>;
            }
        }

        return (toReturn);
    }

    const handleKey = (e) => {
        e.preventDefault();

        if (e.key === "Enter") {
            handleSearch(e);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();

        if (criterio.length > 0) {
            setShowLoading(true);

            setFiltro({
                type: "search",
                criterio: criterio
            });
        }
    }

    const handleShowAll = (e) => {
        e.preventDefault();

        setShowLoading(true);

        setCriterio("");

        setFiltro({
            type: "all",
            id: window.atob(auth.auth)
        });
    }

    React.useEffect(() => {
        getProductos();
    }, [filtro]);

    return (
        <div className="cont_principal deg_top">

            <div className="cont_titulo only_searsh">
                <h2>Planes y actividades</h2>
                <BtnShowList />

                {filtro.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                <div className="search_bar">
                    <input type="text" placeholder="Buscar por código o nombre"
                        value={criterio} onChange={e => setCriterio(e.target.value)}
                        onKeyUp={handleKey} />
                    <MagnifyIcon onClick={handleSearch} />
                </div>
            </div>

            <AllCards />

            {showLoading && <Loading />}
        </div>
    );
}

export default ListaProductos;