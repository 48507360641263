import * as React from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import DownloadIcon from "mdi-react/DownloadIcon";
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import { Alert } from "shared/Alert";
import api from "services/api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ReportsExcel = (params) => {
    const { data, valida } = params;
    const [dataList, setDataList] = React.useState([]);
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const [nombreDoc, setNombreDoc] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    // console.log("dataList", dataList.length);
    const handlePress = (e) => {
        e.preventDefault();

        valida(dataList.length);
    }

    const getList = async () => {
        let _tipo;
        setLoading(true);

        switch (parseInt(data.tipo)) {
            case 1:
                _tipo = "cotizacion_agente_cre";
                break;
            case 2:
                _tipo = "cotizacion_agente_eje";
                break;
            case 3:
                _tipo = "ventas_agente_cre";
                break;
            case 4:
                _tipo = "ventas_agente_eje";
                break;
            case 5:
                _tipo = "ventas_producto_cre";
                break;
            case 6:
                _tipo = "ventas_producto_eje";
                break;
            case 7:
                _tipo = "ventas_producto_detallado";
                break;
            case 8:
                _tipo = "ordenes_ejecutadas";
                break;
            default:
                _tipo = "cotizacion_agente";
                break;
        }

        let _obj = {
            type: _tipo,
            id_user: data.id,
            id_producto: data.id,
            rango: data.rango,
            desde: data.desde,
            hasta: data.hasta
        };

        console.log("_obj", _obj);

        let _data = await api.fetchJson({
            url: "get-pagos",
            data: _obj
        });

        if (_data.response == 1) {
            let _dataList = [];

            _data.data.map(item => {
                if (data.tipo == 1 || data.tipo == 2) {
                    item.total_precio = (item.valor_producto * item.cantidad) - ((item.valor_producto * item.cantidad) * item.descuento / 100);
                    item.saldo = item.total_precio - item.abonos;
                }

                if (item.tipo == 2) {
                    let _ids = item.productos.split(",");

                    _ids.map(async id => {
                        let _new_data = {};
                        let _existe = _dataList.find(x => x.id_producto == id);

                        if (_existe) {
                            let _cantidad = parseInt(item.cantidad_ventas) + parseInt(_existe.cantidad_ventas);
                            _new_data = {
                                id_producto: id,
                                cantidad_ventas: _cantidad,
                                costo_producto: _existe.costo_producto,
                                descripcion_producto: _existe.descripcion_producto,
                                descuento: _existe.descuento,
                                nombre_producto: _existe.nombre_producto,
                                productos: _existe.productos,
                                tipo: _existe.tipo,
                                valor_producto: _existe.valor_producto,
                                valor_total: parseInt(parseInt(_existe.valor_producto) - (parseInt(_existe.valor_producto) * parseInt(_existe.descuento) / 100)) * parseInt(_cantidad)
                            };

                            _dataList.splice(_dataList.indexOf(_existe), 1, _new_data);
                        } else {
                            let _detalle = await api.fetchJson({
                                url: "get-productos",
                                data: {
                                    type: "detalle",
                                    id_producto: id
                                }
                            });

                            _new_data = {
                                id_producto: id,
                                cantidad_ventas: parseInt(item.cantidad_ventas),
                                costo_producto: _detalle.data[0].costo,
                                descripcion_producto: _detalle.data[0].descripcion,
                                descuento: item.descuento,
                                nombre_producto: _detalle.data[0].nombre,
                                productos: item.productos,
                                tipo: item.tipo,
                                valor_producto: _detalle.data[0].valor_venta,
                                valor_total: parseInt(parseInt(_detalle.data[0].valor_venta) - (parseInt(_detalle.data[0].valor_venta) * parseInt(item.descuento) / 100)) * parseInt(item.cantidad_ventas),
                            };

                            _dataList.push(_new_data);
                        }

                        if (_dataList.length == _ids.length) {
                            setDataList(_dataList);
                        }
                    });
                } else {
                    let _existe = _dataList.find(x => x.id_producto == item.id_producto);

                    if (_existe) {
                        _existe.cantidad_ventas = parseInt(item.cantidad_ventas) + parseInt(_existe.cantidad_ventas);

                        _dataList.splice(_dataList.indexOf(_existe), 1, _existe);
                    } else {
                        _dataList.push(item);
                    }

                    setDataList(_dataList);
                }

            });

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            Alert(_data.msg, "warning");
        }

        setLoading(false);
    }

    const BotonDescargar = () => {
        return (
            <>
                {loading ?
                    <div className="wait_button">
                        <svg className="load__icon" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" fill="none" stroke="rgba(52, 59, 255, 0.4)" strokeWidth="10" r="35" />
                            <circle cx="50" cy="50" fill="none" stroke="rgb(255, 255, 255)" strokeWidth="10" r="35" strokeDasharray="165 57" />
                        </svg>
                    </div>
                    :
                    <button type="button" className={dataList.length > 0 ? "download-button" : "download-button _desactivado"} onClick={handlePress}><DownloadIcon />Descargar Excel</button>
                }
            </>
        );
    }

    React.useEffect(() => {
        console.log("data", data);

        if (data && data.id !== undefined) {
            if (data.rango > 0) {
                getList();
            } else {
                setDataList([]);
            }

            switch (parseInt(data.tipo)) {
                case 1:
                case 2:
                    setNombreDoc("Reporte de cotizaciones");
                    break;
                case 3:
                case 4:
                    setNombreDoc("Reporte de ventas");
                    break;
                case 5:
                case 6:
                    setNombreDoc("Reporte de ventas por producto");
                    break;
                case 7:
                    setNombreDoc("Reporte de ventas por producto detallado");
                    break;
                case 8:
                    setNombreDoc("Reporte de ordenes ejecutadas");
                    break;
                default:
                    setNombreDoc("Reporte de cotizaciones");
                    break;
            }
        }
    }, [data]);

    return (
        <>
            {dataList.length > 0 ?
                <ExcelFile
                    filename={nombreDoc}
                    element={<BotonDescargar />}>
                    {(data.tipo == 1 || data.tipo == 2 || data.tipo == 7 || data.tipo == 8) ?
                        <ExcelSheet data={dataList} name="Entregas">
                            <ExcelColumn label="NOMBRE CLIENTE" value="cliente" />
                            <ExcelColumn label="ESTADO" value={(col) => funciones.estadoOrden(col.estado)} />
                            <ExcelColumn label="ID PLAN" value="id_producto" />
                            <ExcelColumn label="NOMBRE PLAN" value="nombre_producto" />
                            <ExcelColumn label="DESCRIPCION" value="descripcion_producto" />
                            <ExcelColumn label="FECHA CREADA" value={(col) => funciones.formatFecha(col.fecha_creacion)} />
                            <ExcelColumn label="FECHA ACTIVIDAD" value={(col) => funciones.formatFecha(col.fecha_ejecucion)} />
                            <ExcelColumn label="OPERADOR" value="nombre_operador" />
                            <ExcelColumn label="CIUDAD" value="ciudad_operador" />
                            <ExcelColumn label="VALOR PRODUCTO" value={(col) => funciones.formatPrice(col.valor_producto).format} />
                            <ExcelColumn label="VALOR CON DESCUENTO" value={(col) => funciones.formatPrice(col.valor_total).format} />
                            <ExcelColumn label="CANTIDAD" value="cantidad" />
                            <ExcelColumn label="VALOR TOTAL" value={(col) => funciones.formatPrice(col.total_precio).format} />
                            <ExcelColumn label="DESCUENTO" value={(col) => col.descuento + "%"} />
                            <ExcelColumn label="ABONO" value={(col) => funciones.formatPrice(col.abonos).format} />
                            <ExcelColumn label="SALDO" value={(col) => funciones.formatPrice(col.saldo).format} />
                        </ExcelSheet>
                        :
                        <ExcelSheet data={dataList} name="Entregas">
                            <ExcelColumn label="CODIGO" value="id_producto" />
                            <ExcelColumn label="NOMBRE" value="nombre_producto" />
                            <ExcelColumn label="DESCRIPCION" value="descripcion_producto" />
                            <ExcelColumn label="CANTIDAD VENDIDA" value="cantidad_ventas" />
                            <ExcelColumn label="VALOR VENTA" value={(col) => funciones.formatPrice(col.valor_producto).format} />
                            <ExcelColumn label="DESCUENTO" value={(col) => col.descuento + "%"} />
                            <ExcelColumn label="COSTO" value={(col) => funciones.formatPrice(col.costo_producto).format} />
                            <ExcelColumn label="TOTAL" value={(col) => funciones.formatPrice(col.valor_total).format} />
                        </ExcelSheet>
                    }
                </ExcelFile>
                :
                <BotonDescargar />
            }
        </>
    );
}

export default ReportsExcel;