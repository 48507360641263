import * as React from "react";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import EmailOutlineIcon from "mdi-react/EmailOutlineIcon";
import logo from "../../images/logo.png";
import * as Rdom from "react-router-dom";
import Loading from "shared/Loading";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const ForgotPassword = () => {
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const [correo, setCorreo] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(false);
    const mail_fer = React.useRef(null);

    const validaCampos = (e) => {
        e.preventDefault();

        alertaRemove(mail_fer.current);

        if (correo.trim() === "") {
            alerta(mail_fer.current, "¡Este campo es requerido!");
            mail_fer.current.focus();
            return false;
        }

        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regex.test(String(correo).toLowerCase())) {
            alerta(mail_fer.current, "¡El formato del correo es incorrecto!");
            mail_fer.current.focus();
            return false;
        }

        sendMail();
    }

    const sendMail = async () => {
        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "reset-pass",
            data: { correo: correo }
        });

        if (parseInt(_data.response) === 1) {

            setTimeout(() => {
                setShowLoading(false);
                Alert("La información fue enviada al correo " + correo, "ok", () => {
                    navigate(-1);
                });
            }, 2000);

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;
            setShowLoading(false);
            Alert(_msg, "warning");
        }

    }

    React.useEffect(() => {
        document.title = "Restablecer Contraseña";
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            <div className="form_principal">

                <div className="cont_logo">
                    <img src={logo} alt="logo principal" />
                </div>

                <div className="mensaje">
                    <p>A continuación ingresa tu correo electrónico, al cual enviaremos las instrucciones para restablecer tu contraseña.</p>
                </div>

                <label>Correo</label>
                <div className="input_icon">
                    <EmailOutlineIcon />
                    <input type="email" name="correo" placeholder="Correo electronico" ref={mail_fer}
                        value={correo} onChange={e => { alertaRemove(mail_fer.current); setCorreo(e.target.value) }} />
                </div>

                <button onClick={validaCampos}>Enviar</button>

            </div>

            <p className="footer">© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>

            {showLoading && <Loading />}
        </div>
    );
}

export default ForgotPassword;