import * as React from "react";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import TitleIcon from "mdi-react/FormatAnnotationPlusIcon";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import OptionIcon from "mdi-react/PlaylistAddIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import * as Rdom from "react-router-dom";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DetallePlantilla = () => {
    const { setTitulo } = useTitle();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const data = location.state.data;
    const nombre = data.nombre || "";
    const [showLoading, setShowLoading] = React.useState(true);
    const [lista, setLista] = React.useState([]);
    const form_ref = React.useRef(null);


    const goEdit = (e) => {
        e.preventDefault()

        navigate("/editar-plantilla", { state: { data: data, from: location } });
    }

    const handleClose = (e) => {
        e.preventDefault()

        if (location.state?.from?.pathname == "/plantillas") {
            navigate(-1);
        } else {
            navigate("/plantillas", { replace: true });
        }
    }

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-templates",
            data: {
                type: "por_id",
                id: data.id
            }
        });

        if (_data.response == "1") {
            setLista(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo("Detalle plantilla");
        getInfo();
    }, []);

    return (
        <div className="cont_principal deg_top cont_forms">

            <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                <h2 className="title_close">Detalle plantilla ({data.id}) <CloseIcon className="close" onClick={handleClose} /> </h2>

                <div className="fila">

                    <div className="columna">
                        <label>Nombre</label>
                        <div className="input_icon">
                            <AccountOutlineIcon />
                            <p className="rerumen">{nombre}</p>
                        </div>
                    </div>

                </div>

                <fieldset>
                    <legend>Datos de la plantilla</legend>
                    {lista.map((item, index) => {
                        switch (parseInt(item.tipo)) {
                            case 1:
                                return (
                                    <div className="columna" key={index}>
                                        <label>Titulo</label>
                                        <div className="input_icon">
                                            <TitleIcon />
                                            <p className="rerumen">{item.contenido}</p>
                                        </div>
                                    </div>
                                );
                            case 2:
                                return (
                                    <div className="columna" key={index}
                                        style={{ width: "90%", marginLeft: "5%" }}>
                                        <label>Contenido</label>
                                        <p className="rerumen_textarea">{item.contenido}</p>
                                    </div>
                                );
                            case 3:
                                return (
                                    <div className="columna" key={index}
                                        style={{ width: "90%", marginLeft: "5%" }}>
                                        <label>Opción de lista</label>
                                        <div className="input_icon">
                                            <OptionIcon />
                                            <p className="rerumen">{item.contenido}</p>
                                        </div>
                                    </div>
                                );
                            case 4:
                                return (
                                    <div className="columna" key={index}
                                        style={{ width: "95%", marginLeft: "5%", alignItems: "center" }}>
                                        <label>Imagen</label>
                                        <img src={item.imagen} className="img_plantilla" alt="Imagen de plantilla" />
                                    </div>
                                );
                            case 5:
                                return (
                                    <div className="columna" key={index}
                                        style={{ width: "95%", marginLeft: "5%" }}>
                                        <label>Imagen y descripción</label>
                                        <div className="fila" style={{ alignItems: "center" }}>
                                            <img src={item.imagen} className="img_plantilla" alt="Imagen de plantilla" />
                                            <p className="rerumen_textarea" style={{ height: 120, marginLeft: 12 }}>{item.contenido}</p>
                                        </div>
                                    </div>
                                );
                        }

                    })}
                </fieldset>

                <button type="button" className="btn_link" onClick={goEdit}>Editar <EditIcon /></button>
            </form >

            {showLoading && <Loading />}
        </div >
    );
}

export default DetallePlantilla;
