import * as React from "react";
import { Alert, Confirm, ConfirmaFecha, AgregaCorreo } from "shared/Alert";
import DeleteIcon from "mdi-react/ArchiveOffOutlineIcon";
import EjecutadaIcon from "mdi-react/CalendarCheckIcon";
import EmailIcon from "mdi-react/EmailSendOutlineIcon";
import OtsIcon from "mdi-react/BookCheckOutlineIcon";
import EditIcon from "mdi-react/EditOutlineIcon";
import AbonoIcon from "mdi-react/CashPlusIcon";
import * as Iconos from "../../../images";
import * as Rdom from "react-router-dom";
import useAuth from "hooks/useAuth";
import PrintLabel from "./Print";
import SendDoc from "./SendDoc";
import api from "services/api";
import PdfDoc from "./PdfDoc";

/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Menu = (params) => {
    const { auth } = useAuth();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const { data, children, reload } = params;
    const [_pos, setPos] = React.useState({ top: "0", left: "0" });
    const [showMenu, setShowMenu] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalView, setModalView] = React.useState(null);
    const row = React.useRef(null);

    const goEdit = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/editar-orden", { state: { data: data } });
    }

    const goAbono = (e) => {
        e.preventDefault()
        e.stopPropagation();

        navigate("/abono", { state: { data: data } });
    }

    const goDelete = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de anular la orden\n Nº ${data.id}?`, async (response) => {
            if (response) {
                let _data = await api.fetchJson({
                    url: "delete",
                    data: {
                        type: "order",
                        id_order: data.id,
                    }
                });

                if (_data.response == 1) {
                    Alert("Orden anulada correctamente", "ok", () => navigate("/ordenes", { replace: true }));
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const cambioAOrden = (e) => {
        hiddenMenu(e);

        Confirm(`Seguro de cambiar la cotización Nº ${data.id} a una orden de servicio?`, async (response) => {
            if (response) {
                let _data = await api.fetchJson({
                    url: "estado",
                    data: {
                        type: "orden_ejecutada",
                        id_order: data.id,
                        estado: 2
                    }
                });

                if (_data.response == 1) {
                    reload();
                    Alert("Estado cambiado correctamente", "ok");
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const marcaEjecutada = (e) => {
        hiddenMenu(e);

        ConfirmaFecha(`Seguro de marcar la orden\n Nº ${data.id} como ejecutada?`, data.fecha_ejecucion, async (response, fecha) => {
            if (response) {
                let _data = await api.fetchJson({
                    url: "estado",
                    data: {
                        type: "orden_ejecutada",
                        id_order: data.id,
                        fecha: fecha,
                        estado: 4
                    }
                });

                if (_data.response == 1) {
                    reload();
                    Alert("Estado cambiado correctamente", "ok");
                } else if (_data.response === -2) {
                    Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
                } else {
                    Alert(_data.msg, "warning");
                }
            }
        });

    }

    const stopClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const hiddenMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setShowMenu(false);
    }

    const handleContextMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let _height = window.innerHeight;
        let _width = document.getElementById("root").offsetWidth;
        let _posx = (e.pageX + 220) > _width ? _width - 220 : e.pageX;
        let _to_border_bottom = _height - e.target.getBoundingClientRect().bottom;
        let _to_y = _to_border_bottom < 160 ? e.pageY - 160 : e.pageY;


        setPos({ top: _to_y + "px", left: _posx + "px" });
        setShowMenu(true);
    }

    const handleAddMail = (e) => {
        hiddenMenu(e);

        AgregaCorreo(`Se enviará al correo electrónico \n"${data.correo}", \nademás puedes agregar más correos \nseparándolos por comas.`, data.correo, async (response, correos) => {
            if (response) {

                if (correos.length > 0) {
                    correos = data.correo + "," + correos;
                } else {
                    correos = data.correo;
                }

                handleModal("send", correos);
            }
        });

    }

    const handleModal = (_option, correos = "") => {
        let _window;

        switch (_option) {

            case "send":
                _window = <SendDoc data={{ ...data, correos: correos }} pos={_pos} close={setShowModal} />;
                break;
            case "print":
                _window = <PrintLabel data={data} pos={_pos} close={setShowModal} />;
                break;
            case "pdf":
                _window = <PdfDoc data={data} pos={_pos} close={setShowModal} />;
                break;
            default:
                _window = null;
                break;
        }

        setShowMenu(false);
        setModalView(_window);
        setShowModal(true);
    }

    React.useEffect(() => {
        document.addEventListener("click", hiddenMenu);

        return () => {
            document.removeEventListener("click", hiddenMenu);
        }
    }, []);

    return (
        <div ref={row} onContextMenu={handleContextMenu}>

            {children}

            <div className="row_menu"
                onClick={stopClick}
                onDoubleClick={stopClick}
                onContextMenu={stopClick}>
                {showMenu &&
                    <>
                        <div className="back_form" onClick={hiddenMenu} onContextMenu={hiddenMenu}></div>
                        <nav className="menu" style={_pos}>
                            <ul>
                                {(auth.nivel <= 3 && data.estado < 4) && <li onClick={goEdit}><EditIcon /> <span><strong>Editar</strong></span></li>}
                                {/* <li onClick={() => handleModal("pdf")}><Iconos.FilePdf /> <span>Descargar PDF</span></li> */}
                                <li onClick={handleAddMail}><EmailIcon /> <span>Enviar por correo</span></li>
                                <li onClick={() => handleModal("print")}><Iconos.PrinterOutline /> <span>Imprimir</span></li>
                                <hr />
                                {auth.nivel <= 3 &&
                                    <>
                                        {data.estado == 1 ?
                                            <li onClick={cambioAOrden}><OtsIcon /> <span>Orden de servicio</span></li>
                                            :
                                            <li><OtsIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Orden de servicio</span></li>
                                        }
                                        {data.estado == 3 ?
                                            <li onClick={marcaEjecutada}><EjecutadaIcon /> <span>Marcar como ejecutada</span></li>
                                            :
                                            <li><EjecutadaIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Marcar como ejecutada</span></li>
                                        }
                                        {data.estado < 4 ?
                                            <li onClick={goAbono}><AbonoIcon /> <span>Nuevo abono</span></li>
                                            :
                                            <li><AbonoIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Nuevo abono</span></li>
                                        }
                                        <hr />
                                    </>
                                }

                                {(auth.nivel <= 2 && data.estado < 4) ?
                                    <li onClick={goDelete}><DeleteIcon /> <span>Anular</span></li>
                                    :
                                    <li><DeleteIcon color="rgb(170, 170, 170)" /> <span style={{ color: "rgb(170, 170, 170)" }}>Anular</span></li>
                                }

                            </ul>
                        </nav>
                    </>}

                {showModal && modalView}
            </div>
        </div>
    );

}

export default Menu;