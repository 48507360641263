import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import BoxIcon from "mdi-react/PackageVariantClosedIcon";
import ArrowRigth from "mdi-react/ChevronRightIcon";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";
import api from "services/api";


const CardProductos = () => {
    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [conteo, setConteo] = React.useState("...");
    const isMounted = React.useRef(true);


    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-productos",
            data: {
                type: "conteo",
            }
        });

        if (isMounted.current) {
            if (_data.response == "1") {
                setConteo(_data.data[0].conteo);
            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;

                Alert(_msg, "warning");
            }
        }
    }

    React.useEffect(() => {
        getInfo();

        return () => { isMounted.current = false; };
    }, []);

    return (
        <div className="card">
            <h2>Productos</h2>
            <BoxIcon className="logo_card" />

            <div className="cont_btns">
                {(auth && auth.nivel) <= 2 && <Link to="/agregar-producto">Crear nuevo <ArrowRigth /></Link>}
                {/* <Link to="/">Ver todos <ArrowRigth /></Link> */}
            </div>

            <div className="cont_info">
                <h1>{conteo}</h1>
            </div>

        </div>
    );
}

export default CardProductos;