import * as React from 'react';
import { numeroALetras } from "services/funciones";
import logo from "../../../images/logo.png";
import * as Rdom from "react-router-dom";
import funciones from "services/funciones";
import Check from "mdi-react/CheckIcon";
import useTitle from "hooks/useTitle";
import { Alert } from "shared/Alert";
import Loading from "shared/Loading";
import QRCode from "react-qr-code";
import api from "services/api";
import Menu from "./Menu";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];


const Detalle = () => {
    const { setTitulo } = useTitle();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const params = Rdom.useParams();
    const [data, setData] = React.useState([]);
    const [packs, setPacks] = React.useState([]);
    const [showLoading, setShowLoading] = React.useState(true);
    const [total, setTotal] = React.useState({ neto: 0, abonos: 0, total: 0, saldo: 0 });
    const [plantillas, setPlantillas] = React.useState([]);
    const [media_movil] = React.useState(window.matchMedia('(max-width: 768px)').matches);

    const Anexo = () => {
        let _toReturn = [];
        let _tag;

        plantillas.map((item, index) => {
            switch (parseInt(item.tipo)) {
                case 1:
                    _tag = <h3 key={index}>{item.contenido}</h3>;
                    break;
                case 2:
                    _tag = <p key={index}>{item.contenido}</p>;
                    break;
                case 3:
                    _tag = <p key={index} className="_option"><Check />{item.contenido}</p>;
                    break;
                case 4:
                    _tag = <img key={index} src={item.imagen} alt="Imagen de la plantilla" className="img_template" />;
                    break;
                case 5:
                    _tag = <div key={index} className="cont_img_template">
                        <img src={item.imagen} alt="Imagen de la plantilla" className="img_template" />
                        <p>{item.contenido}</p>
                    </div>;
                    break;
            }

            _toReturn.push(_tag);
        });

        return (
            <div className="_anexo">
                {_toReturn}
            </div>
        );
    }

    const getDetalle = async () => {
        let _templates = [];
        let _data = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "por_id",
                id: params.orderId
            }
        });

        let _packs = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "productos_x_orden",
                id: params.orderId
            }
        });


        if (_data.response == "1") {
            _data = _data.data[0];
            let _total = parseInt(_data.valor_total - (_data.valor_total * parseInt(_data.descuento) / 100));

            if (parseInt(_data.estado) > 1) {
                _templates = await api.fetchJson({
                    url: "get-templates",
                    data: {
                        type: "por_orden",
                        id: params.orderId
                    }
                });
            }

            setData(_data);

            setTotal({
                neto: parseInt(_data.valor_total),
                abonos: parseInt(_data.abonos),
                total: _total,
                saldo: _data.abonos ? parseInt(_total - _data.abonos) : _total
            });
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        if (_packs.response == "1") {
            setPacks(_packs.data);
        } else if (_packs.response === -2) {
            Alert(_packs.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _packs.data ? _packs.data.msg : _packs.msg;

            Alert(_msg, "warning");
        }

        if (_templates.length < 1) {
            console.log("No hay plantillas");
        } else if (_templates.response == "1") {
            let _plantillas = [];
            let _incluir = true;

            _templates.data.map(t => {
                _plantillas.map(c => {
                    if (c.plantilla === t.plantilla) {
                        if (c.producto !== t.producto) {
                            _incluir = false;
                        }
                    } else {
                        _incluir = true;
                    }
                });

                if (_incluir) {
                    _plantillas.push(t);
                }
            });

            setPlantillas(_plantillas);

        } else if (_templates.response === -2) {
            Alert(_templates.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _templates.data ? _templates.data.msg : _templates.msg;

            Alert(_msg, "warning");
        }

        setShowLoading(false);
    }

    React.useEffect(() => {
        setTitulo("Orden Nº " + params.orderId);
        getDetalle();
    }, []);

    return (
        <div className="cont_principal">
            {params.orderId ?
                <Menu data={data}
                    reload={getDetalle}>
                    <div className="_header">
                        <img src={logo} alt="Logo de la emprsa" />

                        <div className="_header_info">
                            <h3>TOBIAEXTREME</h3>
                            <p>NIT 80149023-1</p>
                            <p>PBX: 7559952</p>
                            <p>ventas@tobiaextreme.com</p>
                        </div>

                        <QRCode
                            size={media_movil ? 150 : 100}
                            value={process.env.REACT_APP_URL_ORDER + params.orderId} />

                        <div className="_header_num">
                            <p>{funciones.estadoOrden(data.estado)}</p>
                            <h2>N°: {meses[new Date(data.fecha_ejecucion * 1000).getMonth()]}-{params.orderId}</h2>
                        </div>
                    </div>

                    <div className="_body">
                        <div className="_body_info">
                            <div className="_body_info_date">
                                <h2>Fecha y caducidad</h2>

                                <div className="_info_row">
                                    <h3>Fecha creada</h3>
                                    <p>{funciones.formatFecha(data.fecha_creacion)}</p>
                                </div>

                                <div className="_info_row">
                                    <h3>Fecha actividad</h3>
                                    <p>{funciones.formatFecha(data.fecha_ejecucion)}</p>
                                </div>
                            </div>

                            <div className="_body_info_user" style={{ overflow: 'hidden' }}>
                                <div className="_info_row">
                                    <div className="_row">
                                        <h3>Señor@</h3>
                                        <p style={{ whiteSpace: "nowrap", width: "200px" }}>{data.nombre} {data.apellido}</p>
                                    </div>
                                    <div className="_row">
                                        <h3>{funciones.tipoDoc(data.tipo_doc)}</h3>
                                        <p style={{ whiteSpace: "nowrap" }}>{data.num_doc}</p>
                                    </div>
                                </div>

                                <div className="_info_row">
                                    <div className="_row">
                                        <h3>Correo</h3>
                                        <p style={{ whiteSpace: "nowrap", width: "200px" }}>{data.correo}</p>
                                    </div>
                                    <div className="_row">
                                        <h3>Teléfono</h3>
                                        <p style={{ whiteSpace: "nowrap" }}>{data.telefono}</p>
                                    </div>
                                </div>

                                <div className="_info_row">
                                    <div className="_row">
                                        <h3>Nota</h3>
                                        <p>{data.notas}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table className="_body_items">
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Destino</th>
                                    <th>Vr. unitario</th>
                                    <th>Cantidad</th>
                                    <th>Vr. Total</th>
                                </tr>
                            </thead>

                            <tbody>
                                {packs.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.producto}</td>
                                            <td>{item.nombre_producto}</td>
                                            <td>{item.descripcion}</td>
                                            <td>{item.nombre_ciudad}</td>
                                            <td>${funciones.formatPrice(item.valor_producto).format}</td>
                                            <td>{item.cantidad}</td>
                                            <td>${funciones.formatPrice(item.valor_producto * item.cantidad).format}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>

                    <div className="_valores">
                        <p>
                            <b>Total en letras: </b> {numeroALetras(total.total).toLocaleLowerCase()}
                            <br />
                            <br />
                            <b>Saldo en letras: </b> {numeroALetras(total.saldo).toLocaleLowerCase()}
                        </p>

                        <h4>
                            {data.descuento >= 1 && <>
                                <b>Neto:</b> <span>${funciones.formatPrice(total.neto).format} COP</span><br />
                                <samp>Descuento del {data.descuento}%</samp>
                            </>}

                            <b>Total:</b> <span>${funciones.formatPrice(total.total).format} COP</span><br />
                            <hr />
                            <b>Abonado:</b> <span>{total.abonos ? "$" + funciones.formatPrice(total.abonos).format + " COP" : "0"}</span><br />
                            <hr />
                            <b>Saldo:</b> <span>{total.saldo > 0 ? "$" + funciones.formatPrice(total.saldo).format + "COP" : "0"}</span><br />
                            <samp className="_last">{data.metodos_pago ? data.metodos_pago : "..."}</samp>
                        </h4>
                    </div>

                    <Anexo />

                    <div className="_footer">
                        <p>© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
                    </div>
                </Menu>
                :
                <h2>No se pasaron parametros</h2>
            }

            {showLoading && <Loading />}
        </div>
    );
}

export default Detalle;