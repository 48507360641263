import * as React from 'react';
import { AuthProvider, BasketProvider } from "./Contexts";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Router from './Router';
import '../styles/app.scss';
import "services/firebase";

function App() {

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    console.log(`x: ${e.pageX}px y: ${e.pageY}px`);
  }

  React.useEffect(() => {
    if (typeof android !== "undefined") {
      document.onselectstart = () => false;
    } else {
      if (process.env.NODE_ENV === 'production') {
        document.addEventListener("contextmenu", handleContextMenu);

        return () => {
          document.removeEventListener("contextmenu", handleContextMenu);
        }
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <AuthProvider>
          <BasketProvider>
            <Router />
          </BasketProvider>
        </AuthProvider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
