import * as React from "react";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import KeyOutlineIcon from "mdi-react/KeyOutlineIcon";
import * as Rdom from "react-router-dom";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import CryptoJS from "crypto-js";
import api from "services/api";


/* eslint eqeqeq: 0 */
/* eslint no-useless-escape: 0 */

const ChangePass = () => {
    const { auth } = useAuth();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    // const data = location.state?.data;
    const [oldPass, setOldPass] = React.useState("");
    const [newPass, setNewPass] = React.useState("");
    const [confirmPass, setConfirmPass] = React.useState("");
    const [showLoading, setShowLoading] = React.useState(false);
    const [showNewPass, setShowNewPass] = React.useState(false);
    const [showCurrentPass, setShowCurrentPass] = React.useState(false);

    const form_ref = React.useRef(null);

    const handleShowCurrentPassword = (e) => {
        e.preventDefault();

        setShowCurrentPass(!showCurrentPass);
    }

    const handleShowNewPassword = (e) => {
        e.preventDefault();

        setShowNewPass(!showNewPass);
    }

    const validaCampos = (e) => {
        e.preventDefault();
        alertaRemove(form_ref.current.current_password);
        alertaRemove(form_ref.current.new_password);
        alertaRemove(form_ref.current.confirm_password);


        if (oldPass.trim() === "") {
            alerta(form_ref.current.current_password, "¡Este campo es requerido!");
            form_ref.current.current_password.focus();
            return false;
        }

        if (newPass.trim() === "") {
            alerta(form_ref.current.new_password, "¡Este campo es requerido!");
            form_ref.current.new_password.focus();
            return false;
        }

        if (newPass.trim().length < 8) {
            alerta(form_ref.current.new_password, "¡La contraseña debe tener al menos 8 caracteres!");
            form_ref.current.new_password.focus();
            return false;
        }

        if (confirmPass.trim() === "") {
            alerta(form_ref.current.confirm_password, "¡Este campo es requerido!");
            form_ref.current.confirm_password.focus();
            return false;
        }

        if (newPass.trim() !== confirmPass.trim()) {
            alerta(form_ref.current.confirm_password, "¡Las contraseñas no coinciden!");
            form_ref.current.confirm_password.focus();
            return false;
        }

        saveData();
    }

    const saveData = async () => {

        setShowLoading(true);

        let _data = await api.fetchJson({
            url: "save-password",
            data: {
                id: window.atob(auth.auth),
                old_pass: CryptoJS.SHA256(oldPass.trim()).toString(),
                new_pass: CryptoJS.SHA256(newPass.trim()).toString(),
            }
        });

        if (parseInt(_data.response) === 1) {

            setTimeout(() => {
                setShowLoading(false);
                Alert("Contraseña cambiada correctamente.", "ok", () => {
                    navigate(-1);
                });
            }, 2000);

        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;
            setShowLoading(false);
            Alert(_msg, "warning");
        }

    }

    React.useEffect(() => {
        document.title = "Cambiar mi contraseña";
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="cont_principal deg_top cont_forms">

            <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
                <h2>Cambiar mi contraseña</h2>

                <div className="cambio_contra">

                    <label>Contraseña actual</label>
                    <div className="contrasenia">
                        <KeyOutlineIcon />
                        <input type={showCurrentPass ? 'text' : 'password'}
                            name="current_password"
                            placeholder='Contraseña actual'
                            value={oldPass}
                            onChange={({ target: { value } }) => { alertaRemove(form_ref.current.current_password); setOldPass(value) }} />
                        {showCurrentPass ? <EyeOffOutlineIcon className="eye_icon" onClick={handleShowCurrentPassword} /> : <EyeOutlineIcon className="eye_icon" onClick={handleShowCurrentPassword} />}
                    </div>

                    <label>Contraseña nueva</label>
                    <div className="contrasenia">
                        <KeyOutlineIcon />
                        <input type={showNewPass ? 'text' : 'password'}
                            name="new_password"
                            placeholder='Contraseña Nueva'
                            value={newPass}
                            onChange={({ target: { value } }) => { alertaRemove(form_ref.current.new_password); setNewPass(value) }} />
                        {showNewPass ? <EyeOffOutlineIcon className="eye_icon" onClick={handleShowNewPassword} /> : <EyeOutlineIcon className="eye_icon" onClick={handleShowNewPassword} />}
                    </div>

                    <label>Confirma nueva contraseña</label>
                    <div className="contrasenia">
                        <KeyOutlineIcon />
                        <input type={showNewPass ? 'text' : 'password'}
                            name="confirm_password"
                            placeholder='Confirma Contraseña'
                            value={confirmPass}
                            onChange={({ target: { value } }) => { alertaRemove(form_ref.current.confirm_password); setConfirmPass(value) }} />

                    </div>

                </div>

                <div className="botones">
                    <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
                    <button type="button" onClick={validaCampos}>Guardar</button>
                </div>
            </form>

            {showLoading && <Loading />}
        </div>
    );
}

export default ChangePass;