import * as React from 'react';
import PhoneInTalkOutlineIcon from 'mdi-react/PhoneInTalkOutlineIcon';
import NumDocIcon from 'mdi-react/CardAccountDetailsOutlineIcon';
import TipoDocIcon from 'mdi-react/CardAccountMailOutlineIcon';
import CalendarMonthIcon from "mdi-react/CalendarMonthIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import SurNameIcon from 'mdi-react/AccountPlusOutlineIcon';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import BullhornIcon from 'mdi-react/BullhornOutlineIcon';
import NameIcon from 'mdi-react/AccountOutlineIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import funciones from "services/funciones";
import DatePicker from "react-datepicker";
import * as Iconos from "../../../images";
import PlusIcon from 'mdi-react/PlusIcon';
import * as Rdom from "react-router-dom";
import useBasket from "hooks/useBasket";
import useTitle from "hooks/useTitle";
import { Input } from "shared/Input";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const NuevaCotizacion = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const { basket, setBasket, addCount, subCount } = useBasket();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const [showLoading, setShowLoading] = React.useState(false);
    const [dateEjecut, setDateEjecut] = React.useState(new Date());
    const [dateVence, setDateVence] = React.useState(new Date(dateEjecut.getTime() + (1000 * 60 * 60 * 24 * 30)));
    const [tipoDoc, setTipoDoc] = React.useState(auth.nivel == 4 ? auth.tipo_doc : 0);
    const [numDoc, setNumDoc] = React.useState(auth.nivel == 4 ? auth.num_doc : '');
    const [nombre, setNombre] = React.useState(auth.nivel == 4 ? auth.nombre : '');
    const [apellido, setApellido] = React.useState(auth.nivel == 4 ? auth.apellido : '');
    const [correo, setCorreo] = React.useState(auth.nivel == 4 ? auth.correo : '');
    const [telefono, setTelefono] = React.useState(auth.nivel == 4 ? auth.telefono : '');
    const [notas, setNotas] = React.useState('');
    const [canalVenta, setCanalVenta] = React.useState(auth.nivel == 4 ? 1 : 0);
    const [decuento, setDecuento] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [listCanales, setListCanales] = React.useState([]);

    const form_ref = React.useRef(null);

    const validaFecha = () => {
        alertaRemove(form_ref.current.fecha);

        if (dateEjecut == null || dateEjecut.length < 1) {
            alerta(form_ref.current.fecha, "¡Este campo es requerido!");
            form_ref.current.fecha.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaCanalVanta = () => {
        alertaRemove(form_ref.current.c_ventas);

        if (canalVenta == null || canalVenta < 1) {
            alerta(form_ref.current.c_ventas, "¡Este campo es requerido!");
            form_ref.current.c_ventas.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaTipoDoc = () => {
        alertaRemove(form_ref.current.tipoDoc);

        if (tipoDoc == null || tipoDoc < 1) {
            alerta(form_ref.current.tipoDoc, "¡Este campo es requerido!");
            form_ref.current.tipoDoc.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaNumDoc = () => {
        alertaRemove(form_ref.current.numDoc);

        if (numDoc == null || numDoc.length < 1) {
            alerta(form_ref.current.numDoc, "¡Este campo es requerido!");
            form_ref.current.numDoc.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaNombre = () => {
        alertaRemove(form_ref.current.nombre);

        if (nombre == null || nombre.length < 1) {
            alerta(form_ref.current.nombre, "¡Este campo es requerido!");
            form_ref.current.nombre.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaApellido = () => {
        alertaRemove(form_ref.current.apellido);

        if (apellido == null || apellido.length < 1) {
            alerta(form_ref.current.apellido, "¡Este campo es requerido!");
            form_ref.current.apellido.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaCorreo = () => {
        alertaRemove(form_ref.current.correo);

        if (correo == null || correo.length < 1) {
            alerta(form_ref.current.correo, "¡Este campo es requerido!");
            form_ref.current.correo.focus();
            return false;
        } else {
            return true;
        }
    }

    const validaTelefono = () => {
        alertaRemove(form_ref.current.telefono);

        if (telefono == null || telefono.length < 1) {
            alerta(form_ref.current.telefono, "¡Este campo es requerido!");
            form_ref.current.telefono.focus();
            return false;
        } else {
            return true;
        }
    }

    const handleSave = async (e) => {
        e.preventDefault();

        if (validaFecha()
            && validaCanalVanta()
            && validaTipoDoc()
            && validaNumDoc()
            && validaNombre()
            && validaApellido()
            && validaCorreo()
            && validaTelefono()
        ) {
            setShowLoading(true);
            let _cantidad = "";

            basket.forEach(item => {
                _cantidad += item.id + ":" + item.cantidad + ",";
            });

            _cantidad = _cantidad.substring(0, _cantidad.length - 1);

            let _data = await api.fetchJson({
                url: "save-order",
                data: {
                    id: 0,
                    vendedor: auth.nivel == 4 ? 0 : window.atob(auth.auth),
                    fecha_ejecucion: new Date(dateEjecut.getFullYear(), dateEjecut.getMonth(), dateEjecut.getDate(), 0, 0, 0, 0).getTime() / 1000, //dateEjecut.getTime() / 1000,
                    cantidad: _cantidad,
                    descuento: decuento,
                    vigencia: dateVence.getTime() / 1000,
                    canal_venta: canalVenta,
                    tipo_doc: tipoDoc,
                    num_doc: numDoc,
                    nombre: nombre,
                    apellido: apellido,
                    correo: correo,
                    telefono: telefono,
                    notas: notas
                }
            });

            if (parseInt(_data.response) === 1) {

                setTimeout(() => {
                    setShowLoading(false);
                    setBasket([]);
                    Alert("Cotización creada correctamente.", "ok", () => {
                        navigate(-1);
                    });
                }, 2000);

            } else if (_data.response === -2) {
                Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
            } else {
                let _msg = _data.data ? _data.data.msg : _data.msg;
                setShowLoading(false);
                Alert(_msg, "warning");
            }
        }
    }

    const getCanalesDeVenta = async () => {
        let _data = await api.fetchJson({
            url: "canales-de-venta",
            data: {
                type: "all"
            }
        });

        if (_data.response == "1") {
            setListCanales(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }
    }

    const CanalVenta = () => {
        let canales = listCanales.map((item, index) => {
            return (
                <option key={index} value={item.id}>{item.nombre}</option>
            )
        });

        return (
            <div className="input_icon">
                <BullhornIcon />
                <select name="c_ventas" value={canalVenta} onChange={(e) => { alertaRemove(form_ref.current.c_ventas); setCanalVenta(e.target.value); }}>
                    <option value="0">Selecciona...</option>
                    {canales}
                </select>
            </div>
        );
    }

    const handleResult = (data) => {
        setTipoDoc(data.tipo_doc);
        setNumDoc(data.num_doc);
        setNombre(data.nombre);
        setApellido(data.apellido);
        setCorreo(data.correo);
        setTelefono(data.telefono);
    }

    const buildTotal = () => {
        let _valor = 0;

        basket.map((item, index) => {
            _valor += parseInt(item.cantidad) * parseInt(item.valor_venta);
        });

        setTotal(_valor - (_valor * decuento / 100));
    }

    React.useEffect(() => {
        setTitulo("Nueva Cotización");
        getCanalesDeVenta();
    }, []);

    React.useEffect(() => {
        buildTotal();
    }, [basket, decuento]);

    return (
        <div className="cont_principal deg_top cont_forms">

            <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>

                <div className="fila" style={{ marginBottom: 38 }}>
                    <div className="columna info_sell">
                        <h1>Nueva Cotización</h1>

                        {basket.map((item, index) => {
                            return (
                                <div className="item_sell" key={index}>
                                    <h2>{item.nombre}</h2>

                                    <h3>
                                        <span>Cantidad:</span>
                                        <i>
                                            <MinusIcon onClick={() => subCount(item)} />
                                            {item.cantidad}
                                            <PlusIcon onClick={() => addCount(item)} />
                                        </i>
                                    </h3>

                                    <h3><span>Opera:</span> {item.nombre_operador}</h3>
                                    <h3><span>Lugar:</span> {item.nombre_ciudad}</h3>
                                    <p>
                                        <samp> ${funciones.formatPrice(item.valor_venta).format} COP</samp>
                                        {item.descripcion}
                                    </p>
                                </div>
                            );
                        })}

                    </div>
                </div>

                <div className="fila">

                    {auth.nivel <= 3 && <div className="columna">
                        <label>Descuento</label>
                        <div className="input_icon">
                            <Iconos.Percent />
                            <input type="number" name="decuento" placeholder='ingresa el descuento'
                                value={decuento} onChange={(e) => {
                                    alertaRemove(form_ref.current.decuento);
                                    setDecuento(e.target.value);
                                }} />
                        </div>
                    </div>}

                    <div className="columna">
                        <label>Total</label>
                        <div className="input_icon">
                            <Iconos.Peso />
                            <p className="detalle"><i>{funciones.formatPrice(total).format} COP</i></p>
                        </div>
                    </div>

                </div>

                <div className="fila">

                    <div className="columna">
                        <label>Fecha del evento</label>
                        <div className="input_icon">
                            <DatePicker name='fecha' dateFormat="yyyy/MM/dd" selected={dateEjecut} onChange={(date) => setDateEjecut(date)} />
                            <CalendarMonthIcon />
                        </div>
                    </div>

                    <div className="columna">
                        <label>Vigencia</label>
                        <div className="input_icon">
                            <DatePicker name='fechavence' dateFormat="yyyy/MM/dd" selected={dateVence} onChange={(date) => setDateVence(date)} readOnly={auth.nivel == 4} />
                            <CalendarMonthIcon />
                        </div>
                    </div>

                </div>

                {auth.nivel <= 3 && <div className="fila">

                    <div className="columna">
                        <label>Canal de ventas</label>
                        <CanalVenta />
                    </div>

                    <div className="columna">

                    </div>

                </div>}

                <fieldset>
                    <legend>Información de contacto</legend>

                    {auth.nivel <= 3 && <div className="fila">
                        <div className="columna">
                            <p>Digita el número de documento para traer los datos del usuario o llena el formulario para crear uno nuevo. <br />Recuerda que si el número de documento existe en la base de datos no se actualizará la información conservando la que ya se encuentra guardada.</p>
                        </div>
                    </div>}

                    <div className="fila">

                        <div className="columna">
                            <label>Tipo documento</label>
                            <div className="input_icon">
                                <TipoDocIcon />
                                <select name="tipoDoc" value={tipoDoc} onChange={(e) => { alertaRemove(form_ref.current.tipoDoc); setTipoDoc(e.target.value) }}>
                                    <option value="0">Selecciona...</option>
                                    <option value="1">Cédula de Ciudadanía</option>
                                    <option value="2">Cédula de Extranjería</option>
                                    <option value="3">Pasaporte</option>
                                </select>
                            </div>
                        </div>

                        <div className="columna">
                            <label>Nº documento</label>
                            {auth.nivel <= 3 ?
                                <Input type="tel" name="numDoc" find="documento" placeholder='Número de documento'
                                    onTyping={(value) => setNumDoc(value)}
                                    onMatch={(item) => handleResult(item)}>
                                    <NumDocIcon />
                                </Input>
                                :
                                <div className="input_icon">
                                    <NumDocIcon />
                                    <input type="number" name="numDoc" placeholder='Número de documento'
                                        value={numDoc} onChange={(e) => { alertaRemove(form_ref.current.numDoc); setNumDoc(e.target.value) }} />
                                </div>}
                        </div>

                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Nombre</label>
                            <div className="input_icon">
                                <NameIcon />
                                <input type="text" name="nombre" placeholder='Tu nombre'
                                    value={nombre} onChange={(e) => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
                            </div>
                        </div>

                        <div className="columna">
                            <label>Apellido</label>
                            <div className="input_icon">
                                <SurNameIcon />
                                <input type="text" name="apellido" placeholder='Apellidos'
                                    value={apellido} onChange={(e) => { alertaRemove(form_ref.current.apellido); setApellido(e.target.value) }} />
                            </div>
                        </div>

                    </div>

                    <div className="fila">

                        <div className="columna">
                            <label>Correo</label>
                            <div className="input_icon">
                                <EmailOutlineIcon />
                                <input type="email" name="correo" placeholder='Ciudad'
                                    value={correo} onChange={(e) => { alertaRemove(form_ref.current.correo); setCorreo(e.target.value) }} />
                            </div>
                        </div>

                        <div className="columna">
                            <label>Teléfono</label>
                            <div className="input_icon">
                                <PhoneInTalkOutlineIcon />
                                <input type="tel" name="telefono" placeholder='Operador'
                                    value={telefono} onChange={(e) => { alertaRemove(form_ref.current.telefono); setTelefono(e.target.value) }} />
                            </div>
                        </div>

                    </div>

                </fieldset>

                <div className="fila">

                    <div className="columna">
                        <label>Nota</label>
                        <div className="input_icon">
                            <textarea name="notas" cols="30" rows="10" placeholder="Agrega una observación"
                                value={notas} onChange={(e) => { alertaRemove(form_ref.current.notas); setNotas(e.target.value) }} />
                        </div>
                    </div>

                </div>

                <div className="botones">
                    <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
                    <button type="button" onClick={handleSave}>Aceptar</button>
                </div>

            </form>

            {showLoading && <Loading />}
        </div>
    );
}

export default NuevaCotizacion;
