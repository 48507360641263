import * as React from "react";
import ChartBarVendedores from "./ChartBarVendedores";
import ChartBarProductos from "./ChartBarProductos";
import VendedorIcon from "mdi-react/AccountEyeIcon";
import ProductoIcon from "mdi-react/ArchiveEyeIcon";
import ChartAreaVentas from "./ChartAreaVentas";
import ChartIcon from "mdi-react/ChartLineIcon";


const CardAdmin = () => {
    const [viewChart, setViewChart] = React.useState(<ChartAreaVentas user={{ id: 0 }} />);
    const [vendedor, setVendedor] = React.useState({});
    const [opcion, setOpcion] = React.useState(0);


    const handleSelectVendedor = (_user) => {
        setVendedor(_user);

        handleShowChart(2, _user);
    }

    const handleShowChart = (_opt, _user) => {
        setOpcion(_opt);

        switch (_opt) {
            case 1:
                setViewChart(<ChartBarVendedores change={handleSelectVendedor} />);
                break;
            case 2:
                setViewChart(<ChartAreaVentas user={_user} />);
                break;
            case 3:
                setViewChart(<ChartBarProductos />);
                break;
            default:
                setViewChart(<ChartAreaVentas user={{ id: 0 }} />);
                break;
        }
    }

    const Botones = () => {
        let _toReturn = null;
        switch (opcion) {
            case 3:
                _toReturn = <>
                    <button className="card_button_option" onClick={e => handleShowChart(1, vendedor)}><VendedorIcon /> Por Agente </button>
                    <button className="card_button_option" onClick={e => handleShowChart(0, vendedor)}><ChartIcon /> Mis ventas </button>
                </>;
                break;
            case 2:
                _toReturn = <>
                    <button className="card_button_option" onClick={e => handleShowChart(1, vendedor)}><VendedorIcon /> Todos los agentes </button>
                    <button className="card_button_option" onClick={e => handleShowChart(3, vendedor)}><ProductoIcon /> Por Producto </button>
                </>;
                break;
            case 1:
                _toReturn = <>
                    <button className="card_button_option" onClick={e => handleShowChart(0, vendedor)}><ChartIcon /> Mis ventas </button>
                    <button className="card_button_option" onClick={e => handleShowChart(3, vendedor)}><ProductoIcon /> Por Producto </button>
                </>;
                break;
            case 0:
            default:
                _toReturn = <>
                    <button className="card_button_option" onClick={e => handleShowChart(1, vendedor)}><VendedorIcon /> Por Agente </button>
                    <button className="card_button_option" onClick={e => handleShowChart(3, vendedor)}><ProductoIcon /> Por Producto </button>
                </>;
                break;
        }

        return <div className="card_buttons_group">{_toReturn}</div>
    }

    return (
        <>
            {viewChart}

            <Botones />
        </>
    );
}

export default CardAdmin;