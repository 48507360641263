import * as React from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Alert, alerta, alertaRemove } from "shared/Alert";
import TitleIcon from "mdi-react/FormatAnnotationPlusIcon";
import CloseIcon from "mdi-react/CloseCircleOutlineIcon";
import AddIcon from "mdi-react/StickerPlusOutlineIcon";
import ImageTextIcon from "mdi-react/ImageTextIcon";
import OptionIcon from "mdi-react/PlaylistAddIcon";
import ImageIcon from "mdi-react/ImageIcon";
import * as Rdom from "react-router-dom";
import DropZone from "shared/DropZone";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import useAuth from "hooks/useAuth";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const EditarPlantilla = () => {
  const { auth } = useAuth();
  const fauth = getAuth();
  const { setTitulo } = useTitle();
  const navigate = Rdom.useNavigate();
  const location = Rdom.useLocation();
  const data = location.state.data;
  const [nombre, setNombre] = React.useState(data.nombre || "");
  const [showLoading, setShowLoading] = React.useState(true);
  const [lista, setLista] = React.useState([]);
  const form_ref = React.useRef(null);


  const validaCampos = (e) => {
    e.preventDefault();
    let validador = true;

    alertaRemove(form_ref.current.nombre);

    if (nombre == null || nombre.length < 1) {
      alerta(form_ref.current.nombre, "¡Este campo es requerido!");
      form_ref.current.nombre.focus();
      return false;
    }

    lista.map((item) => {
      if ((item.contenido == null || item.contenido < 1) && parseInt(item.tipo) !== 4) {
        console.log(item);
        validador = false;
      }
    });

    if (validador) {
      let _new_list = [];

      setShowLoading(true);

      lista.map(async (item, index) => {
        item.sort = index;

        if (item.imagen) {
          let file = item.imagen[0];

          if (typeof file.upload === "undefined") {
            await uploadFile(file).then(response => {
              item.url = response.url;
            }).catch(error => {
              setShowLoading(false);
            });
          } else {
            item.url = file.url;
          }

          _new_list.push(item);

          if (_new_list.length === lista.length) {
            _new_list.sort((a, b) => a.sort - b.sort);
            saveData(_new_list);
          }
        } else {
          _new_list.push(item);

          if (_new_list.length === lista.length) {
            _new_list.sort((a, b) => a.sort - b.sort);
            saveData(_new_list);
          }
        }
      });
    } else {
      Alert("¡No pueden haber campos vacios!", "warning");
    }
  }

  const uploadFile = async (file) => {
    const imgName = file.name;

    return new Promise((resolve, reject) => {
      const storageRef = ref(getStorage(), "plantillas/" + imgName);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on("state_changed", snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + Math.round(progress) + "% done");
      },
        error => {
          console.log("error", error.message);
          reject(error.message);
        },
        () => {
          const downloadTask = getDownloadURL(uploadTask.snapshot.ref);

          downloadTask.then(url => {
            let _new_file = Object.assign(file, { url: url });

            resolve(_new_file);
          }).catch(error => {
            console.log("Error: ", error.message);
            reject(error.message);
          });
        }
      );
    });
  }

  const saveData = async (_new_list) => {
    let _lista = "";

    _new_list.map((item) => {
      let _url_img = item.tipo == 4 || item.tipo == 5 ? item.url : "";
      _lista += item.tipo + "|c|" + item.contenido + "|i|" + _url_img + "^";
    });

    _lista = _lista.substring(0, _lista.length - 1);

    let _data = await api.fetchJson({
      url: "save-template",
      data: {
        id: data.id,
        nombre: nombre,
        lista: _lista
      }
    });

    if (parseInt(_data.response) === 1) {

      setTimeout(() => {
        setShowLoading(false);
        Alert("Plantilla modificada con éxito", "ok", () => {
          navigate(-1);
        });
      }, 2000);


    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
    } else {
      let _msg = _data.data ? _data.data.msg : _data.msg;
      setShowLoading(false);
      Alert(_msg, "warning");
    }
  }

  const handleAdd = (_int) => {
    let _item = {};

    switch (_int) {
      case 1:
        _item = { indice: lista.length + 1, tipo: 1, contenido: "" };
        break;
      case 2:
        _item = { indice: lista.length + 1, tipo: 2, contenido: "" };
        break;
      case 3:
        _item = { indice: lista.length + 1, tipo: 3, contenido: "" };
        break;
      case 4:
        _item = { indice: lista.length + 1, tipo: 4, contenido: "", imagen: [] };
        break;
      case 5:
        _item = { indice: lista.length + 1, tipo: 5, contenido: "", imagen: [] };
        break;
    }

    setLista([...lista, _item]);
  }

  const handleRemove = (_indice) => {
    let _lista = [...lista];
    _lista.splice(_indice, 1);
    setLista(_lista);
  }

  const getInfo = async () => {
    let _data = await api.fetchJson({
      url: "get-templates",
      data: {
        type: "por_id",
        id: data.id
      }
    });

    if (_data.response == "1") {
      let _lista = [];
      _data.data.map((item) => {
        if (item.tipo == 4 || item.tipo == 5) {
          let _url = item.imagen;
          let file_name = _url.split("?")[0].split("%2F").pop();

          let file = new File(["data"],
            file_name,
            {
              type: "image/" + file_name.split(".").pop(),
              lastModified: Date.now()
            });

          file = Object.assign(file, {
            preview: _url,
            url: _url,
            path: file_name,
            upload: false
          });

          item.imagen = [file];
        }

        _lista.push(item);
      });


      setLista(_lista);
    } else if (_data.response === -2) {
      Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
    } else {
      let _msg = _data.data ? _data.data.msg : _data.msg;

      Alert(_msg, "warning");
    }

    setShowLoading(false);
  }

  React.useEffect(() => {
    setTitulo("Editar plantilla");
    getInfo();

    if (auth && auth.nivel >= 2) {
      navigate("/", { replace: true, state: { from: location } });
    }

    onAuthStateChanged(fauth, (user) => {
      if (user) {
        const uid = user.uid;

        console.log("Usuario logado\n\n uid", uid);
      } else {
        console.log("User is signed out");

        signInAnonymously(fauth)
          .then(() => {
            console.log("Signed in firebase");
          })
          .catch((error) => {
            let _error = { code: error.code, message: error.message };
            console.log("Error: ", _error);
          });
      }
    });
  }, []);

  return (
    <div className="cont_principal deg_top cont_forms">

      <form className="form_principal" ref={form_ref} onSubmit={e => e.preventDefault()}>
        <h2>Editar plantilla ({data.id})</h2>

        <div className="fila">

          <div className="columna">
            <label>Nombre</label>
            <div className="input_icon">
              <AccountOutlineIcon />
              <input type="text" name="nombre" placeholder='Nombre de la plantilla'
                maxLength={45}
                value={nombre} onChange={(e) => { alertaRemove(form_ref.current.nombre); setNombre(e.target.value) }} />
            </div>
          </div>

        </div>

        <fieldset>
          <legend>Datos de la plantilla</legend>
          {lista.map((item, index) => {
            switch (parseInt(item.tipo)) {
              case 1:
                return (
                  <div className="columna" key={index}>
                    <label><CloseIcon className="close" onClick={() => handleRemove(index)} />Titulo</label>
                    <div className="input_icon">
                      <TitleIcon />
                      <input type="text" name="cuenta" placeholder='Ingresa el titulo'
                        value={item.contenido} onChange={(e) => { setLista(lista.map((_item, _index) => { if (_index === index) _item.contenido = e.target.value; return _item; })) }} />
                    </div>
                  </div>
                );
              case 2:
                return (
                  <div className="columna" key={index}
                    style={{ width: "95%", marginLeft: "5%" }}>
                    <label><CloseIcon className="close" onClick={() => handleRemove(index)} />Contenido</label>
                    <div className="input_icon">
                      <textarea name="descripcion" cols="30" rows="5" placeholder='Ingresa el contenido'
                        style={{ height: 90 }}
                        value={item.contenido} onChange={(e) => { setLista(lista.map((_item, _index) => { if (_index === index) _item.contenido = e.target.value; return _item; })) }} />
                    </div>
                  </div>
                );
              case 3:
                return (
                  <div className="columna" key={index}
                    style={{ width: "95%", marginLeft: "5%" }}>
                    <label><CloseIcon className="close" onClick={() => handleRemove(index)} />Opción de lista</label>
                    <div className="input_icon">
                      <OptionIcon />
                      <input type="text" name="cuenta" placeholder='Ingresa la opción'
                        value={item.contenido} onChange={(e) => { setLista(lista.map((_item, _index) => { if (_index === index) _item.contenido = e.target.value; return _item; })) }} />
                    </div>
                  </div>
                );
              case 4:
                return (
                  <div className="columna" key={index}
                    style={{ width: "95%", marginLeft: "5%" }}>
                    <label><CloseIcon className="close" onClick={() => handleRemove(index)} />Adjuntar imagen</label>
                    <DropZone files={item.imagen}
                      onChange={(file) => {
                        setLista(l => l.map((_item, _index) => {
                          if (_index === index) {
                            _item.imagen = file;
                          }
                          return _item;
                        }))
                      }} />
                  </div>
                );
              case 5:
                return (
                  <div className="columna" key={index}
                    style={{ width: "95%", marginLeft: "5%" }}>
                    <label><CloseIcon className="close" onClick={() => handleRemove(index)} />Adjuntar imagen</label>
                    <div className="fila">
                      <DropZone files={item.imagen}
                        onChange={(file) => {
                          setLista(l => l.map((_item, _index) => {
                            if (_index === index) {
                              _item.imagen = file;
                            }
                            return _item;
                          }))
                        }} />
                      <div className="input_icon">
                        <textarea name="descripcion" cols="30" rows="5" placeholder='Ingresa el contenido'
                          style={{ height: 120, marginLeft: 12 }}
                          value={item.contenido} onChange={(e) => { setLista(l => l.map((_item, _index) => { if (_index === index) _item.contenido = e.target.value; return _item; })) }} />
                      </div>
                    </div>
                  </div>
                );
            }

          })}
        </fieldset>

        <div className="fila">
          <button type="button" className="btn_link" onClick={e => handleAdd(1)}>Titulo <TitleIcon /></button>
          <button type="button" className="btn_link" onClick={e => handleAdd(2)}>Contenido <AddIcon /></button>
          <button type="button" className="btn_link" onClick={e => handleAdd(3)}>Item de lista <OptionIcon /></button>
        </div>

        <div className="fila">
          <button type="button" className="btn_link" onClick={e => handleAdd(4)}>Imagen <ImageIcon /></button>
          <button type="button" className="btn_link" onClick={e => handleAdd(5)}>Imagen con descripción <ImageTextIcon /></button>
        </div>

        <div className="botones">
          <button type="button" onClick={() => navigate(-1)}>Cancelar</button>
          <button type="button" onClick={validaCampos}>Guardar</button>
        </div>

      </form >

      {showLoading && <Loading />}
    </div >
  );
}

export default EditarPlantilla;
