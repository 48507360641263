import React from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { TitleProvider } from "./Contexts";
import useAuth from "hooks/useAuth";

import Layout from '../view/Layout';
import NoMatch from '../view/NoMatch';
import Home from '../view/Home';

import LogIn from '../view/LogIn';
import SignUp from '../view/SignUp';
import ForgotPassword from '../view/ForgotPassword';
import ChangePass from '../view/ForgotPassword/components/ChangePass';
import ResetPass from '../view/ForgotPassword/components/ResetPass';
import VerificaCorreo from '../view/ForgotPassword/components/VerificaCorreo';

import Ordenes from '../view/Ordenes';
import DetalleOrden from '../view/Ordenes/components/Detalle';
import NuevaCotizacion from '../view/Ordenes/components/NuevaCotizacion';
import EditarOrden from '../view/Ordenes/components/EditarOrden';

import Caja from '../view/Caja';
import Abonos from '../view/Caja/components/Abonos';

import Productos from '../view/Productos';
import DetalleProducto from '../view/Productos/components/DetalleProducto';
import AgregarProducto from '../view/Productos/components/AgregarProducto';
import EditarProducto from '../view/Productos/components/EditarProducto';

import MetodoPago from '../view/MetodoPago';
import DetalleMetodoPago from '../view/MetodoPago/components/DetalleMetodoPago';
import NuevoMetodoPago from '../view/MetodoPago/components/NuevoMetodoPago';
import EditarMetodoPago from '../view/MetodoPago/components/EditarMetodoPago';

import Operadores from '../view/Operadores';
import AgregarOperador from '../view/Operadores/components/AgregarOperador';
import DetalleOperador from '../view/Operadores/components/DetalleOperador';
import EditarOperador from '../view/Operadores/components/EditarOperador';

import Destinos from '../view/Destinos';
import AgregarDestino from '../view/Destinos/components/AgregarDestino';

import Usuarios from '../view/Usuarios';
import Detalle from '../view/Usuarios/components/Detalle';
import NuevoUsuario from '../view/Usuarios/components/NuevoUsuario';
import EditarPerfil from '../view/Usuarios/components/EditarPerfil';

import Reportes from '../view/Reports';

import Plantillas from '../view/Plantillas';
import DetallePlantilla from '../view/Plantillas/components/DetallePlantilla';
import CrearPlantillas from '../view/Plantillas/components/CrearPlantillas';
import EditarPlantilla from '../view/Plantillas/components/EditarPlantilla';


const Router = () => {
  const { auth } = useAuth();

  const RequireAuth = ({ children }) => {
    let location = useLocation();

    if (!auth) {
      return <Navigate to="/log-in" state={{ from: location }} replace />;
    }

    return children;
  }

  return (
    <>
      <TitleProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route index path="/" element={<RequireAuth><Home /></RequireAuth>} />

            <Route path="/ordenes" element={<RequireAuth><Ordenes /></RequireAuth>} />
            <Route path="/orden" element={<RequireAuth><DetalleOrden /></RequireAuth>} >
              <Route path=":orderId" element={<RequireAuth><DetalleOrden /></RequireAuth>} />
            </Route>
            <Route path="/editar-orden" element={<RequireAuth><EditarOrden /></RequireAuth>} />

            <Route path="/caja" element={<RequireAuth><Caja /></RequireAuth>} />
            <Route path="/abono" element={<RequireAuth><Abonos /></RequireAuth>} />

            <Route path="/mis-compras" element={<RequireAuth><Ordenes /></RequireAuth>} />
            <Route path="/cotizacion" element={<RequireAuth><NuevaCotizacion /></RequireAuth>} />

            <Route path="/productos" element={<RequireAuth><Productos /></RequireAuth>} />
            <Route path="/detalle-producto" element={<RequireAuth><DetalleProducto /></RequireAuth>} />
            <Route path="/agregar-producto" element={<RequireAuth><AgregarProducto /></RequireAuth>} />
            <Route path="/editar-producto" element={<RequireAuth><EditarProducto /></RequireAuth>} />

            <Route path="/metodos-pago" element={<RequireAuth><MetodoPago /></RequireAuth>} />
            <Route path="/detalle-metodo-pago" element={<RequireAuth><DetalleMetodoPago /></RequireAuth>} />
            <Route path="/nuevo-metodo-pago" element={<RequireAuth>
              <NuevoMetodoPago /></RequireAuth>} />
            <Route path="/editar-metodo-pago" element={<RequireAuth><EditarMetodoPago /></RequireAuth>} />

            <Route path="/operadores" element={<RequireAuth><Operadores /></RequireAuth>} />
            <Route path="/agregar-operador" element={<RequireAuth><AgregarOperador /></RequireAuth>} />
            <Route path="/editar-operador" element={<RequireAuth><EditarOperador /></RequireAuth>} />
            <Route path="/detalle-operador" element={<RequireAuth><DetalleOperador /></RequireAuth>} >
              <Route path=":operadorId" element={<RequireAuth><DetalleOperador /></RequireAuth>} />
            </Route>

            <Route path="/destinos" element={<RequireAuth><Destinos /></RequireAuth>} />
            <Route path="/agregar-destino" element={<RequireAuth><AgregarDestino /></RequireAuth>} />

            <Route path="/usuarios" element={<RequireAuth><Usuarios /></RequireAuth>} />
            <Route path="/detalle-usuario" element={<RequireAuth><Detalle /></RequireAuth>} />
            <Route path="/nuevo-usuario" element={<RequireAuth><NuevoUsuario /></RequireAuth>} />
            <Route path="/editar-usuario" element={<RequireAuth><EditarPerfil /></RequireAuth>} />
            <Route path="/editar-perfil" element={<RequireAuth><EditarPerfil /></RequireAuth>} />

            <Route path="/reportes" element={<RequireAuth><Reportes /></RequireAuth>} />

            <Route path="/plantillas" element={<RequireAuth><Plantillas /></RequireAuth>} />
            <Route path="/detalle-plantilla" element={<RequireAuth><DetallePlantilla /></RequireAuth>} />
            <Route path="/nueva-plantilla" element={<RequireAuth><CrearPlantillas /></RequireAuth>} />
            <Route path="/editar-plantilla" element={<RequireAuth><EditarPlantilla /></RequireAuth>} />

            <Route path="/cambia-clave" element={<RequireAuth><ChangePass /></RequireAuth>} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPass />} >
              <Route path=":correoBase" element={<RequireAuth><ResetPass /></RequireAuth>} />
            </Route>

            <Route path="/verifica-correo" element={<VerificaCorreo />} >
              <Route path=":correoBase" element={<RequireAuth><VerificaCorreo /></RequireAuth>} />
            </Route>

            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </TitleProvider>
      <div id="modal"></div>
    </>
  );
}

export default Router;
