import * as React from "react";
import useTitle from "hooks/useTitle";


const NoMatch = () => {
    const { setTitulo } = useTitle();

    React.useEffect(() => {
        setTitulo("Página no encontrada");
    }, []);

    return (
        <div className="cont_principal">

            <div className="title">
                <h2>Página no encontrada</h2>
                <p></p>
            </div>

        </div >
    );
}

export default NoMatch;