import * as React from "react";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { numeroALetras } from "services/funciones";
import Autorenew from 'mdi-react/AutorenewIcon';
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import Check from "mdi-react/CheckIcon";
import domtoimage from 'dom-to-image';
import { Alert } from "shared/Alert";
import QRCode from "react-qr-code";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

/**
 * 
 * Barcodes Type
 *
 *   CODE128
 *   EAN / UPC
 *   CODE39
 *   ITF-14
 *   MSI
 *   Pharmacode
 *   Codabar
 *
 */

const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

const SendDoc = (param) => {
    const fauth = getAuth();
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const { data, pos, close } = param;
    const [allLabels, setAllLabels] = React.useState(<h4 className="_loadind"><Autorenew /> Generando correo...</h4>);
    const [_style, setStyle] = React.useState(pos);
    const documet_view = React.useRef();
    const qr_view = React.useRef();


    const sendMail = async () => {
        let _code = meses[new Date(data.fecha_ejecucion * 1000).getMonth()] + "-" + data.id;

        let _data = await api.fetchJson({
            url: "send-order",
            data: {
                correo: data.correos,
                contenido: documet_view.current.innerHTML,
                asunto: parseInt(data.estado) === 1 ? "Cotización - " + _code : "Orden de Servicio - " + _code,
            }
        });

        if (_data.response == 1) {
            Alert("Enviado correctamente a \n" + data.correos, "ok");
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            Alert(_data.msg, "warning");
        }

        close(false);
    }

    const getDetalle = async (url_qr) => {
        let _list = [];
        let _templates = [];
        let _data = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "por_id",
                id: data.id
            }
        });

        let _packs = await api.fetchJson({
            url: "get-ordenes",
            data: {
                type: "productos_x_orden",
                id: data.id
            }
        });

        if (_data.response == 1) {
            _data = _data.data[0];
            let _total = parseInt(_data.valor_total - (_data.valor_total * parseInt(_data.descuento) / 100));

            _total = {
                neto: parseInt(_data.valor_total),
                abonos: parseInt(_data.abonos),
                total: _total,
                saldo: _data.abonos ? parseInt(_total - _data.abonos) : _total
            };

            if (parseInt(_data.estado) > 1) {
                let _plantillas = [];
                let _incluir = true;
                let _consulta_templates = await api.fetchJson({
                    url: "get-templates",
                    data: {
                        type: "por_orden",
                        id: data.id
                    }
                });

                if (_consulta_templates.response == 1) {
                    _templates = _consulta_templates.data;
                    _templates.map(t => {
                        _plantillas.map(c => {
                            if (c.plantilla === t.plantilla) {
                                if (c.producto !== t.producto) {
                                    _incluir = false;
                                }
                            } else {
                                _incluir = true;
                            }
                        });
        
                        if (_incluir) {
                            _plantillas.push(t);
                        }
                    });

                    _templates = _plantillas;
                }
            }

            _list.push(
                <div key={_data.id} style={{ width: "100%", display: "block", paddingTop: 18, margin: 0 }}>
                    <div style={{ width: "100%", padding: "8px 0", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center" }}>
                        <img src="https://ventas.tobiaextreme.com/static/media/logo_black.png" alt="Logo de tobiaextreme" style={{ width: "25%", minWidth: 100, height: 100, objectFit: "contain" }} />

                        <div style={{ width: "25%", display: "block" }}>
                            <div style={{ display: "block", width: "min-content", textAlign: "left", margin: "0 auto" }}>
                                <h3 style={{ margin: "0 0 12px", fontSize: "1.1rem", fontWeight: "bold" }}>TOBIAEXTREME</h3>
                                <p style={{ fontSize: "0.9rem", margin: "0", padding: "0", lineHeight: "1.3", color: "rgb(70, 70, 70)" }}>NIT 80149023-1</p>
                                <p style={{ fontSize: "0.9rem", margin: "0", padding: "0", lineHeight: "1.3", color: "rgb(70, 70, 70)" }}>PBX: 7559952</p>
                                <p style={{ fontSize: "0.9rem", margin: "0", padding: "0", lineHeight: "1.3", color: "rgb(70, 70, 70)", whiteSpace: "nowrap" }}>ventas@tobiaextreme.com</p>
                            </div>
                        </div>

                        <div style={{ width: "25%", display: "block" }}>
                            <a href={process.env.REACT_APP_URL_ORDER + data.id} target="_blank" rel="noopener noreferrer" style={{ display: "block", width: 100, height: 100, margin: "0 auto", textDecoration: "none", color: "rgb(0, 0, 0)" }}>
                                <img src={url_qr} alt={"Codigo QR "} style={{ display: "block", width: 100, height: 100, objectFit: "contain" }} />
                            </a>
                        </div>

                        <div style={{ width: "25%", display: "block" }}>
                            <div style={{ width: "min-content", maxHeight: 75, display: "block", margin: "12px auto 0", padding: "12px 16px 8px", border: "1px solid rgb(170, 170, 170)", borderRadius: "6px" }}>
                                <p style={{ fontSize: "0.9rem", margin: "0", padding: "0", lineHeight: "1.3", color: "rgb(70, 70, 70)", textAlign: "center", whiteSpace: "pre" }}>{funciones.estadoOrden(data.estado)}</p>
                                <h2 style={{ margin: "8px 0 0", fontSize: "1.5rem", fontWeight: "bold", whiteSpace: "nowrap" }}>N°: {meses[new Date(data.fecha_ejecucion * 1000).getMonth()]}-{data.id}</h2>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%", padding: "8px 0 0" }}>

                        <div style={{ display: "block", width: "100%", margin: "18px 0 12px", padding: 0 }}>
                            <div style={{ width: "35%", maxWidth: "270px", display: "block", margin: "0 6px 0 12px", padding: 0, float: "right" }}>
                                <h2 style={{
                                    width: "100%",
                                    margin: "0",
                                    padding: "4px 0",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    backgroundColor: "rgb(210, 210, 210)",
                                    border: "1px solid rgb(170, 170, 170)"
                                }}>Fecha y caducidad</h2>

                                <div style={{ width: "100%", margin: "0 0", padding: "0 0", display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)" }}>
                                    <h3 style={{ width: "140px", margin: "0 0", padding: "8px 0", backgroundColor: "rgb(210, 210, 210)", fontSize: "1rem", textAlign: "center", borderRight: "1px solid rgb(170, 170, 170)", borderLeft: "1px solid rgb(170, 170, 170)" }}>Fecha creada</h3>
                                    <p style={{ margin: "0 0", paddingLeft: 12, fontSize: "0.9rem", lineHeight: "2.7" }}>{funciones.formatFecha(data.fecha_creacion)}</p>
                                </div>

                                <div style={{ width: "100%", margin: "0 0", padding: "0 0", display: "flex", flexDirection: "row", alignItems: "center", borderBottom: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)" }}>
                                    <h3 style={{ width: "140px", margin: "0 0", padding: "8px 0", backgroundColor: "rgb(210, 210, 210)", fontSize: "1rem", textAlign: "center", borderRight: "1px solid rgb(170, 170, 170)", borderLeft: "1px solid rgb(170, 170, 170)" }}>Fecha actividad</h3>
                                    <p style={{ margin: "0 0", paddingLeft: 12, fontSize: "0.9rem", lineHeight: "2.7" }}>{funciones.formatFecha(data.fecha_ejecucion)}</p>
                                </div>
                            </div>

                            <div style={{ width: "60%", maxWidth: "750px", display: "block", margin: "0 12px 0 0" }}>

                                <div style={{ width: "100%", maxHeight: 30, display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)", overflow: "hidden" }}>
                                    <h3 style={{ width: "110px", minWidth: "110px", height: "100%", margin: "0", padding: "6px", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: "#d2d2d2", borderLeft: "1px solid #aaaaaa", borderRight: "1px solid #aaaaaa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Señor@</h3>
                                    <p style={{ width: "100%", margin: "0", padding: "0 12px", fontSize: "0.9rem", lineHeight: "1.8", color: "#464646" }}>{data.nombre} {data.apellido}</p>
                                </div>

                                <div style={{ width: "100%", maxHeight: 30, display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)", overflow: "hidden" }}>
                                    <h3 style={{ width: "110px", minWidth: "110px", height: "100%", margin: "0", padding: "6px", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: "#d2d2d2", borderLeft: "1px solid #aaaaaa", borderRight: "1px solid #aaaaaa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{funciones.tipoDoc(data.tipo_doc)}</h3>
                                    <p style={{ width: "100%", margin: "0", padding: "0 12px", fontSize: "0.9rem", lineHeight: "1.8", color: "#464646" }}>{data.num_doc}</p>
                                </div>

                                <div style={{ width: "100%", maxHeight: 30, display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)", overflow: "hidden" }}>
                                    <h3 style={{ width: "110px", minWidth: "110px", height: "100%", margin: "0", padding: "6px", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: "#d2d2d2", borderLeft: "1px solid #aaaaaa", borderRight: "1px solid #aaaaaa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Correo</h3>
                                    <p style={{ width: "100%", margin: "0", padding: "0 12px", fontSize: "0.9rem", lineHeight: "1.8", color: "#464646" }}>{data.correo}</p>
                                </div>

                                <div style={{ width: "100%", maxHeight: 30, display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)", overflow: "hidden" }}>
                                    <h3 style={{ width: "110px", minWidth: "110px", height: "100%", margin: "0", padding: "6px", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: "#d2d2d2", borderLeft: "1px solid #aaaaaa", borderRight: "1px solid #aaaaaa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Teléfono</h3>
                                    <p style={{ width: "100%", margin: "0", padding: "0 12px", fontSize: "0.9rem", lineHeight: "1.8", color: "#464646" }}>{data.telefono}</p>
                                </div>

                                <div style={{ width: "100%", maxHeight: 30, display: "flex", flexDirection: "row", alignItems: "center", borderTop: "1px solid rgb(170, 170, 170)", borderBottom: "1px solid rgb(170, 170, 170)", borderRight: "1px solid rgb(170, 170, 170)", overflow: "hidden" }}>
                                    <h3 style={{ width: "110px", minWidth: "110px", height: "100%", margin: "0", padding: "6px", fontSize: "0.9rem", fontWeight: "bold", backgroundColor: "#d2d2d2", borderLeft: "1px solid #aaaaaa", borderRight: "1px solid #aaaaaa", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Nota</h3>
                                    <p style={{ width: "100%", margin: "0", padding: "0 12px", fontSize: "0.9rem", lineHeight: "1.8", color: "#464646" }}>{data.notas}</p>
                                </div>
                            </div>
                        </div>

                        <table style={{ width: "100%", margin: "28px 0 0", borderCollapse: "collapse" }}>
                            <thead style={{ backgroundColor: "rgb(210, 210, 210)" }}>
                                <tr>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap" }}>Código</th>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap", minWidth: "200px" }}>Nombre</th>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap" }}>Descripción</th>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap" }}>Destino</th>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap" }}>Vr. unitario</th>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap" }}>Cantidad</th>
                                    <th style={{ position: "relative", padding: "4px 6px", border: "solid 1px rgb(170, 170, 170)", fontSize: "0.9rem", color: "rgb(68, 68, 68)", whiteSpace: "nowrap" }}>Vr. Total</th>
                                </tr>
                            </thead>

                            <tbody>
                                {_packs.data.map((item, index) => {
                                    return (
                                        <tr key={index} style={{ backgroundColor: "rgb(255, 255, 255)" }}>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>{item.producto}</td>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>{item.nombre_producto}</td>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>{item.descripcion}</td>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>{item.nombre_ciudad}</td>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>${funciones.formatPrice(item.valor_producto).format}</td>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>{item.cantidad}</td>
                                            <td style={{ padding: "4px 6px", fontSize: "0.9rem", color: "rgb(70, 70, 70)", border: "1px solid rgb(170, 170, 170)" }}>${funciones.formatPrice(item.valor_producto * item.cantidad).format}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>

                    </div>

                    <div style={{ width: "100%", padding: "18px 0 8px", display: "block" }}>

                        <h4 style={{ width: "300px", maxWidth: "300px", fontSize: "1rem", fontWeight: "bold", textAlign: "right", backgroundColor: "rgb(210, 210, 210)", border: "1px solid rgb(170, 170, 170)", whiteSpace: "nowrap", overflow: "hidden", float: "right" }}>
                            {data.descuento >= 1 && <>
                                <b style={{ display: "inline-block", width: "90px", paddingLeft: "12px", textAlign: "left" }}>Neto:</b> <span style={{ display: "inline-block", width: "200px", margin: "0 0 0 8px", padding: "6px 18px 4px 32px", fontWeight: "normal", textAlign: "left", borderLeft: "1px solid rgb(170, 170, 170)", backgroundColor: "rgb(255, 255, 255)" }}>${funciones.formatPrice(_total.neto).format} COP</span><br />
                                <samp style={{
                                    display: "block",
                                    width: "100%",
                                    padding: "4px 6px",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                    fontWeight: "normal",
                                    fontSize: "0.9rem",
                                    textAlign: "center",
                                    borderTop: "1px solid rgb(170, 170, 170)",
                                    borderBottom: "1px solid rgb(170, 170, 170)",
                                    backgroundColor: "rgb(255, 255, 255)"
                                }}>Descuento del {data.descuento}%</samp>
                            </>}

                            <b style={{ display: "inline-block", width: "90px", paddingLeft: "12px", textAlign: "left" }}>Total:</b> <span style={{ display: "inline-block", width: "200px", margin: "0 0 0 8px", padding: "6px 18px 4px 32px", fontWeight: "normal", textAlign: "left", borderLeft: "1px solid rgb(170, 170, 170)", backgroundColor: "rgb(255, 255, 255)" }}>${funciones.formatPrice(_total.total).format} COP</span><br />
                            <hr style={{ margin: "0", padding: "0", border: "none", borderTop: "1px solid rgb(170, 170, 170)" }} />
                            <b style={{ display: "inline-block", width: "90px", paddingLeft: "12px", textAlign: "left" }}>Abonado:</b> <span style={{ display: "inline-block", width: "200px", margin: "0 0 0 8px", padding: "6px 18px 4px 32px", fontWeight: "normal", textAlign: "left", borderLeft: "1px solid rgb(170, 170, 170)", backgroundColor: "rgb(255, 255, 255)" }}>{_total.abonos ? "$" + funciones.formatPrice(_total.abonos).format + " COP" : "0"}</span><br />
                            <hr style={{ margin: "0", padding: "0", border: "none", borderTop: "1px solid rgb(170, 170, 170)" }} />
                            <b style={{ display: "inline-block", width: "90px", paddingLeft: "12px", textAlign: "left" }}>Saldo:</b> <span style={{ display: "inline-block", width: "200px", margin: "0 0 0 8px", padding: "6px 18px 4px 32px", fontWeight: "normal", textAlign: "left", borderLeft: "1px solid rgb(170, 170, 170)", backgroundColor: "rgb(255, 255, 255)" }}>{_total.saldo > 0 ? "$" + funciones.formatPrice(_total.saldo).format + "COP" : "0"}</span><br />
                            <samp style={{
                                display: "block",
                                width: "100%",
                                padding: "4px 6px",
                                fontFamily: "Arial, Helvetica, sans-serif",
                                fontWeight: "normal",
                                fontSize: "0.9rem",
                                textAlign: "center",
                                borderTop: "1px solid rgb(170, 170, 170)",
                                backgroundColor: "rgb(255, 255, 255)"
                            }}>{data.metodos_pago ? data.metodos_pago : "..."}</samp>
                        </h4>

                        <p style={{ margin: "22px 0 0", padding: "0", fontSize: "0.9rem" }}>
                            <b style={{ fontSize: "1rem" }}>Total en letras: </b> {numeroALetras(_total.total).toLocaleLowerCase()}
                            <br />
                            <br />
                            <b style={{ fontSize: "1rem" }}>Saldo en letras: </b> {numeroALetras(_total.saldo).toLocaleLowerCase()}
                        </p>

                    </div>

                    <div style={{ width: "100%", display: "block", margin: "0", padding: "8px 28px 32px" }}>
                        {_templates.map((doc, index) => {
                            switch (parseInt(doc.tipo)) {
                                case 1:
                                    return <h3 key={index} style={{ padding: "6px 0", margin: "32px 0 4px", fontSize: "1.2rem", fontWeight: "bold", color: "rgb(255, 136, 38)" }}>{doc.contenido}</h3>;
                                case 2:
                                    return <p key={index} style={{ margin: "0", padding: "0 0 12px", fontSize: "1rem", lineHeight: "1.5", whiteSpace: "pre-line" }}>{doc.contenido}</p>;
                                case 3:
                                    return <p key={index} style={{ width: "100%", display: "block", margin: "0 0 8px", padding: "0 0 0 18px", fontSize: "0.9rem", lineHeight: "1", whiteSpace: "pre-line" }}><Check style={{ width: "18px", height: "18px", marginRight: "8px", marginBottom: "-4px" }} />{doc.contenido}</p>;
                                case 4:
                                    return <img key={index} src={doc.imagen} alt="Imagen de la plantilla" style={{ width: "90%", marginLeft: "18px" }} />;
                                case 5:
                                    return <div key={index} style={{ width: "100%", maxWidth: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                                        <img src={doc.imagen} alt="Imagen de la plantilla" style={{ width: "40%", marginLeft: "18px" }} />
                                        <p style={{ margin: "0 18px 0 32px", padding: "0 0 12px", fontSize: "1rem", lineHeight: "1.5", whiteSpace: "pre-line" }}>{doc.contenido}</p>
                                    </div>;
                            }
                        })}
                    </div>

                    <div style={{ width: "100%", display: "block", margin: "24px 0 18px" }}>
                        <p style={{ display: "inline-block", width: "100%", margin: "0", padding: "0", fontSize: "0.8rem", textAlign: "center", color: "rgb(70, 70, 70)" }}>© 2022 <b>TobiaExtreme</b> todos los derechos reservados.</p>
                    </div>

                </div>
            );

            setAllLabels(_list);
            setStyle({
                // ...pos,

                width: "100%",
                position: "relative",

                borderRadius: 0,
                boxShadow: "none",
                padding: 0
            });

            sendMail();
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate(-1));
        } else {
            Alert(_data.msg, "warning");
        }
    }

    const uploadFile = async (file) => {
        const imgName = file.name;

        return new Promise((resolve, reject) => {
            const storageRef = ref(getStorage(), "codigos_qr/" + imgName);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed", snapshot => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + Math.round(progress) + "% done");
            },
                error => {
                    console.log("error", error.message);
                    reject(error.message);
                },
                () => {
                    const downloadTask = getDownloadURL(uploadTask.snapshot.ref);

                    downloadTask.then(url => {
                        let _new_file = Object.assign(file, { url: url });

                        resolve(_new_file);
                    }).catch(error => {
                        console.log("Error: ", error.message);
                        reject(error.message);
                    });
                }
            );
        });
    }

    React.useEffect(() => {
        domtoimage.toBlob(qr_view.current)
            .then(function (blob) {

                let file = new File([blob],
                    "qr_" + data.id + ".png",
                    {
                        type: "image/png",
                        lastModified: Date.now()
                    });

                qr_view.current.style.display = "none";

                uploadFile(file).then(response => {
                    getDetalle(response.url);
                }).catch(error => {
                    Alert("Error al subir la imagen: " + error, "warning");
                });
            })
            .catch(function (error) {
                console.error('¡Huy! Algo salió mal!', error);
                Alert("¡Huy! Algo salió mal! " + error, "warning");
            });

    }, [data]);

    React.useEffect(() => {

        onAuthStateChanged(fauth, (user) => {
            if (user) {
                const uid = user.uid;

                console.log("Usuario logado\n\n uid", uid);
            } else {
                console.log("User is signed out");

                signInAnonymously(fauth)
                    .then(() => {
                        console.log("Signed in firebase");
                    })
                    .catch((error) => {
                        let _error = { code: error.code, message: error.message };
                        console.log("Error: ", _error);
                    });
            }
        });
    }, []);

    return (
        <div className="content_modal">
            <div className="win_modal _impress" style={_style} ref={documet_view}>
                {allLabels}
            </div>

            <div style={{ width: 100, display: "block" }} ref={qr_view}>
                <QRCode
                    size={100}
                    value={process.env.REACT_APP_URL_ORDER + data.id} />
            </div>
        </div>
    );
}

export default SendDoc;