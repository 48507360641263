import * as React from 'react';
import LoadingWare from "../../images/loading.svg";

const Loading = () => {
    return (
        <div className="loading">
            <div className="load_icon">
                <img src={LoadingWare} alt="Loading..." /> 
            </div>
        </div>
    );
}

export default Loading;