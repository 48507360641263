import * as React from 'react';
import funciones from "services/funciones";
import * as Rdom from "react-router-dom";
import { Alert } from "shared/Alert";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint no-extend-native: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

Date.prototype.getWeekNumber = function () {
    var d = new Date(+this);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));

    return Math.ceil((((d - new Date(d.getFullYear(), 0, 1)) / 8.64e7) + 1) / 7);
};

const ChartBarProductos = (params) => {
    const navigate = Rdom.useNavigate();
    const location = Rdom.useLocation();
    const [ordenes, setOrdenes] = React.useState([]);
    const isMounted = React.useRef(true);
    const [rango, setRango] = React.useState(4);
    const [label, setLabel] = React.useState("el año");
    const [afterLoad, setAfterLoad] = React.useState(false);


    const ListaProductos = () => {
        let _toReturn = [];

        ordenes.map((entry, index) => (
            _toReturn.push(
                <p key={index}>
                    <span style={{ width: entry.porcentaje + "%" }}></span>
                    <i>
                        <samp>(${funciones.formatPrice(entry.valor_producto).format} * {entry.cantidad_producto ?? 0}) ${funciones.formatPrice(entry.valor_producto * entry.cantidad_producto).format}</samp>
                        {entry.nombre_producto}
                    </i>
                </p>)
        ));

        if (_toReturn.length == 0) {
            _toReturn.push(<h4 key={0} className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>);
        }

        return _toReturn;
    }

    const handleChange = (_valor) => {
        setRango(_valor);

        switch (_valor) {
            case 1:
                setLabel("el día");
                break;
            case 2:
                setLabel("la semana");
                break;
            case 3:
                setLabel("el mes");
                break;
            case 4:
            default:
                setLabel("el año");
                break;
        }

    }

    const getData = async () => {
        let _data = await api.fetchJson({
            url: "get-productos",
            data: {
                type: "ventas",
                rango: rango,
            }
        });


        if (_data.response == "1") {
            let _sumatoria = 0;

            _data.data.map(item => {
                _sumatoria += parseInt(item.cantidad_producto ?? 0);
            });

            _data.data.map(item => {
                item.porcentaje = parseInt(item.cantidad_producto ?? 0) * 100 / _sumatoria;
            });

            setOrdenes(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
    }

    React.useEffect(() => {
        getData();

        return () => { isMounted.current = false; };
    }, [rango]);

    return (
        <div className="grafico_ventas">
            <div className="cont_barras">

                <h4>Ventas de productos durante {label}</h4>

                <ListaProductos />

            </div>

            <div className="radio_group">
                <label>
                    <input type="radio" name="ventas" checked={rango === 1} onChange={() => handleChange(1)} />
                    <span>Día</span>
                </label>
                <label>
                    <input type="radio" name="ventas" checked={rango === 3} onChange={() => handleChange(3)} />
                    <span>Mes</span>
                </label>
                <label>
                    <input type="radio" name="ventas" checked={rango === 4} onChange={() => handleChange(4)} />
                    <span>Año</span>
                </label>
            </div>
        </div>
    );
};

export default ChartBarProductos;
