import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import TrayArrowUpIcon from 'mdi-react/TrayArrowUpIcon';
import { Alert } from "shared/Alert";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const DropZoneField = ({ onChange, files }) => {

  const onDrop = React.useCallback(loaded => {
    if (loaded.length > 1) {
      Alert("Sólo puedes subir un archivo.\nSe tomará el primero '" + loaded[0].name + "'", "warning");
    }

    let _file = loaded.filter((file, index) => {
      if (index === 0) {
        Object.assign(file, { preview: URL.createObjectURL(file) });
        return file;
      }
    });

    onChange(_file);
  }, []);

  const Aside = () => {
    let _content = [];

    _content.push(<img key="1" src={files[0].preview} alt={"Imagen " + files[0].name} />);
    _content.push(<p key="2" className="aside_image_name">{files[0].name}</p>);

    return (
      <aside className="aside_content">
        {_content}
      </aside>
    );
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className="adjuntar">

      {(!files || files.length === 0)
        && (<div className="input_message">
          <TrayArrowUpIcon />
          <p>Clic aquí o arrastra las imágenes para agreagarlas</p>
        </div>)}

      <input {...getInputProps()} />

      {files.length > 0 && <Aside />}
    </div>);
}


export default DropZoneField;
