import * as React from "react";

const Cell = (params) => {
    const { children, className, ...props } = params;
    const [pressed, setPressed] = React.useState(false);
    const [startX, setStartX] = React.useState(0);
    const [startWidth, setStartWidth] = React.useState(0);

    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let cell = e.target.parentNode;

        setPressed(true);
        setStartX(e.pageX);
        setStartWidth(cell.offsetWidth);
        e.target.style.width = "200%";
        e.target.style.right = "-100%";
    }

    const handleMouseUp = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (pressed) {
            setPressed(false);
            e.target.style.width = "10px";
            e.target.style.right = "-5px";
        }
    }

    const handleMouseMove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.persist();

        if (pressed) {
            let cell = e.target.parentNode;
            let width = startWidth + (e.pageX - startX);

            if (width < 0) {
                width = 0;
            }
            cell.style.width = width + "px";
            cell.style.minWidth = width + "px";
            cell.style.maxWidth = width + "px";
        }
    }

    return (
        <th className={className} {...props}>
            <div className="content">
                {children}
            </div>
            <div className="resize"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}></div>
        </th>
    );
}

export default Cell;