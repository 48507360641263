import * as React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import ArrowRigth from "mdi-react/ChevronRightIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import MenuRow from "./components/MenuRow";
// import Filtro from "./components/Filtro";
import funciones from "services/funciones";
import useTitle from "hooks/useTitle";
import Loading from "shared/Loading";
import { Alert } from "shared/Alert";
import useAuth from "hooks/useAuth";
import Cell from "shared/Cell";
import api from "services/api";

/* eslint eqeqeq: 0 */
/* eslint array-callback-return: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

const Productos = () => {
    const { auth } = useAuth();
    const { setTitulo } = useTitle();
    const navigate = useNavigate();
    const location = useLocation();
    const [asc, setAsc] = React.useState(true);
    const [dataList, setDataList] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [criterio, setCriterio] = React.useState("");
    const [afterLoad, setAfterLoad] = React.useState(false);
    const [filtro, setFiltro] = React.useState({ type: "all", id: window.atob(auth.auth) });
    const [showLoading, setShowLoading] = React.useState(true);

    const Row = (params) => {

        return (
            <MenuRow {...params}
                current={selected.id}
                onSel={setSelected}
                reload={getInfo}>

                <td>{params.data.id}</td>
                <td>{params.data.nombre}</td>
                <td>{params.data.descripcion.length > 40 ? params.data.descripcion.substring(0, 40) + "..." : params.data.descripcion}</td>
                <td style={{textAlign: "right"}}>${funciones.formatPrice(params.data.valor_venta).format}</td>
                <td>{params.data.nombre_operador}</td>
                <td>{params.data.nombre_ciudad}</td>

            </MenuRow>
        );
    }

    const AllRow = () => {
        let _data = [];

        dataList.map((item, index) => {
            _data.push(<Row key={item.id} resalta={index % 2} data={item} />);
        });

        if (_data.length < 1) {
            return <h4 className="titulo_msg">{afterLoad ? "No hay resultados" : "Cargando..."}</h4>;
        } else {
            return <div className="cont_tabla">
                <table>
                    <thead>
                        <tr>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("id")} />Código</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortBy("nombre")} />Nombre</Cell>
                            <Cell>Descripción</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortByNum("valor_venta")} />Valor</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortBy("nombre_operador")} />Operador</Cell>
                            <Cell><SwapVerticalIcon onClick={() => sortBy("nombre_ciudad")} />Destino</Cell>
                        </tr>
                    </thead>

                    <tbody>
                        {_data}
                    </tbody>
                </table>
            </div>;
        }
    }

    const sortBy = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: item[_key].toLowerCase() };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                if (a.value > b.value) {
                    return 1;
                }

                if (a.value < b.value) {
                    return -1;
                }
            } else {
                if (a.value > b.value) {
                    return -1;
                }

                if (a.value < b.value) {
                    return 1;
                }
            }

            return 0;
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const sortByNum = (_key) => {

        let mapped = dataList.map((item, i) => {
            return { index: i, value: parseInt(item[_key]) };
        });

        mapped.sort(function (a, b) {
            if (asc) {
                return a.value - b.value;
            } else {
                return b.value - a.value;
            }
        });

        let _new = mapped.map(item => {
            return dataList[item.index];
        });

        setAsc(!asc);
        setDataList(_new);
    }

    const handleKey = (e) => {
        e.preventDefault();

        if (e.key === "Enter") {
            handleSearch(e);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();

        if (criterio.length > 0) {
            setShowLoading(true);

            setFiltro({
                type: "search",
                criterio: criterio
            });
        }
    }

    const handleShowAll = (e) => {
        e.preventDefault();

        setShowLoading(true);

        setCriterio("");

        setFiltro({
            type: "all",
            id: window.atob(auth.auth)
        });
    }

    const getInfo = async () => {
        let _data = await api.fetchJson({
            url: "get-productos",
            data: filtro
        });

        if (_data.response == "1") {
            setDataList(_data.data);
        } else if (_data.response === -2) {
            Alert(_data.msg, "warning", () => navigate("/log-in", { replace: true, state: { from: location } }));
        } else {
            let _msg = _data.data ? _data.data.msg : _data.msg;

            Alert(_msg, "warning");
        }

        setAfterLoad(true);
        setShowLoading(false);
    }

    React.useEffect(() => {
        getInfo();
    }, [filtro]);

    React.useEffect(() => {
        setTitulo("Productos");
        if (auth && auth.nivel >= 3) {
            navigate("/", { replace: true, state: { from: location } });
        }
    }, []);

    return (
        <div className="cont_principal deg_top">

            <div className="cont_titulo only_searsh">
                <h2>Productos</h2>
                {(auth && auth.nivel) <= 2 && <Link to="/agregar-producto">Crear nuevo <ArrowRigth /></Link>}
                {filtro.type == "search" && <button className="btn_reset_lista" onClick={handleShowAll}>Ver todos</button>}
                <div className="search_bar">
                    <input type="text" placeholder="Buscar por código o nombre"
                        value={criterio} onChange={e => setCriterio(e.target.value)}
                        onKeyUp={handleKey} />
                    <MagnifyIcon onClick={handleSearch} />
                </div>

                {/* <Filtro /> */}
            </div>

            <AllRow />
            {showLoading && <Loading />}
        </div>
    );
}

export default Productos;