const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];

/* eslint eqeqeq: 0 */
/* eslint default-case: 0 */
/* eslint no-useless-escape: 0 */

const numeroALetras = (function () {
    // Código basado en el comentario de @sapienman
    // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
    function Unidades(num) {

        switch (num) {
            case 1:
                return 'UN';
            case 2:
                return 'DOS';
            case 3:
                return 'TRES';
            case 4:
                return 'CUATRO';
            case 5:
                return 'CINCO';
            case 6:
                return 'SEIS';
            case 7:
                return 'SIETE';
            case 8:
                return 'OCHO';
            case 9:
                return 'NUEVE';
        }

        return '';
    } //Unidades()

    function Decenas(num) {

        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return 'DIEZ';
                    case 1:
                        return 'ONCE';
                    case 2:
                        return 'DOCE';
                    case 3:
                        return 'TRECE';
                    case 4:
                        return 'CATORCE';
                    case 5:
                        return 'QUINCE';
                    default:
                        return 'DIECI' + Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return 'VEINTE';
                    default:
                        return 'VEINTI' + Unidades(unidad);
                }
            case 3:
                return DecenasY('TREINTA', unidad);
            case 4:
                return DecenasY('CUARENTA', unidad);
            case 5:
                return DecenasY('CINCUENTA', unidad);
            case 6:
                return DecenasY('SESENTA', unidad);
            case 7:
                return DecenasY('SETENTA', unidad);
            case 8:
                return DecenasY('OCHENTA', unidad);
            case 9:
                return DecenasY('NOVENTA', unidad);
            case 0:
                return Unidades(unidad);
        }
    } //Unidades()

    function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + ' Y ' + Unidades(numUnidades)

        return strSin;
    } //DecenasY()

    function Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return 'CIENTO ' + Decenas(decenas);
                return 'CIEN';
            case 2:
                return 'DOSCIENTOS ' + Decenas(decenas);
            case 3:
                return 'TRESCIENTOS ' + Decenas(decenas);
            case 4:
                return 'CUATROCIENTOS ' + Decenas(decenas);
            case 5:
                return 'QUINIENTOS ' + Decenas(decenas);
            case 6:
                return 'SEISCIENTOS ' + Decenas(decenas);
            case 7:
                return 'SETECIENTOS ' + Decenas(decenas);
            case 8:
                return 'OCHOCIENTOS ' + Decenas(decenas);
            case 9:
                return 'NOVECIENTOS ' + Decenas(decenas);
        }

        return Decenas(decenas);
    } //Centenas()

    function Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let letras = '';

        if (cientos > 0)
            if (cientos > 1)
                letras = Centenas(cientos) + ' ' + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += '';

        return letras;
    } //Seccion()

    function Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
        let strCentenas = Centenas(resto);

        if (strMiles == '')
            return strCentenas;

        return strMiles + ' ' + strCentenas;
    } //Miles()

    function Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = Seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
        let strMiles = Miles(resto);

        if (strMillones == '')
            return strMiles;

        return strMillones + ' ' + strMiles;
    } //Millones()

    return function NumeroALetras(num, currency = {
        plural: "PESOS",
        singular: "PESO",
        centPlural: "CENTAVOS",
        centSingular: "CENTAVO"
    }) {
        currency = currency || {};
        let data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: '',
            letrasMonedaPlural: currency.plural || 'PESOS CHILENOS', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
            letrasMonedaSingular: currency.singular || 'PESO CHILENO', //'PESO', 'Dólar', 'Bolivar', 'etc'
            letrasMonedaCentavoPlural: currency.centPlural || 'CHIQUI PESOS CHILENOS',
            letrasMonedaCentavoSingular: currency.centSingular || 'CHIQUI PESO CHILENO'
        };

        if (data.centavos > 0) {
            data.letrasCentavos = 'CON ' + (function () {
                if (data.centavos == 1)
                    return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
                else
                    return Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
            })();
        };

        if (data.enteros == 0)
            return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
        if (data.enteros == 1)
            return Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
        else
            return Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    };

})();

class funciones {

    /**
     * Entrega el estdo de la orden en texto
     * 
     * @param {Number} estado: estado de la orden
     * @returns {String} estado de la orden en texto
     */
    estadoOrden(estado) {
        /**
         * Estados de las ordenes
         * 
         * 0: Pendiente
         * 1: Cotización
         * 2: Aprobado // orden de servicio
         * 3: Pagado
         * 4: Ejecutado
         * 5: Anulada
         * 6: Eliminado
         */

        switch (parseInt(estado)) {
            case 0:
                estado = "Pendiente";
                break;
            case 1:
                estado = "Cotización";
                break;
            case 2:
                estado = "Orden de servicio";
                break;
            case 3:
                estado = "Orden de servicio \nPagada";
                break;
            case 4:
                estado = "Ejecutado";
                break;
            case 5:
                estado = "Anulada";
                break;
            default:
                estado = "No definido";
                break;
        }

        return estado;
    }

    /**
     * Formatea una fecha en formato dd/mm/yyyy
     * tomando como entrada una fecha en formato unix
     * 
     * @param {String} unix: fecha en formato unix
     * @return {String}: fecha en formato dd/mm/yyyy
     */
    formatFecha(unix) {
        let fecha = new Date(unix * 1000);
        let dia = fecha.getDate();
        let mes = fecha.getMonth(); // + 1;
        let anio = fecha.getFullYear();

        if (dia < 10) {
            dia = '0' + dia;
        }

        // if (mes < 10) {
        //     mes = '0' + mes;
        // }

        return dia + '/' + meses[mes] + '/' + anio;
    }

    /**
     * Formatea una fecha en formato dd/mm/yyyy hh:mm
     * tomando como entrada una fecha en formato unix
     * 
     * @param {String} unix: fecha en formato unix
     * @return {String}: fecha en formato dd/mm/yyyy hh:mm
     */
    formatHora(unix) {
        let fecha = new Date(unix * 1000);
        let dia = fecha.getDate();
        let mes = fecha.getMonth(); // + 1;
        let anio = fecha.getFullYear();
        let hora = fecha.getHours();
        let minutos = fecha.getMinutes();

        if (dia < 10) {
            dia = '0' + dia;
        }

        if (hora < 10) {
            hora = '0' + hora;
        }

        if (minutos < 10) {
            minutos = '0' + minutos;
        }


        return dia + '/' + meses[mes] + '/' + anio + ' ' + hora + ':' + minutos;
    }

    /**
     * Ordena un numero en formato de moneda
     * 
     * @param num {String} numero a formatear
     * @returns {JSON} obj {`int`, `format`}
     */
    formatPrice(num) {
        num += '';
        num = num.replace(/[^0-9\.]/g, '');

        let _clean = num.toString().replaceAll(".", "");
        let _cant = parseInt(_clean.length / 3);
        let _to_return = "";
        let _rest = _clean;
        let _parts = [];

        for (let i = 0; i < _cant; i++) {
            _parts.push(_rest.slice(-3));
            _rest = _rest.slice(0, -3);
        }

        for (let e = _parts.length - 1; e >= 0; e--) {
            _to_return += "." + _parts[e];
        }

        _to_return = _rest + _to_return;
        _to_return = _to_return.slice(0, 1) == "." ? _to_return.slice(1, _to_return.length) : _to_return;

        return {
            int: _clean,
            format: _to_return
        };
    }

    /**
     * convierte el numero a el tipo de documento
     * 
     * @param {Number} tipo: tipo de documento
     * @return {String}: tipo de documento
     */
    tipoDoc(tipo) {
        switch (parseInt(tipo)) {
            case 1:
                return "Cédula Ciudadanía";
            case 2:
                return "Cédula Extranjería";
            case 3:
                return "Pasaporte";
            default:
                return "";
        }
    }
}

export { numeroALetras };
export default new funciones();